export class CnDrawing {
    id() {
        return '';
    }

    open() {

    }

    openSwitched(cnDrawingToCompare, selectedCnResult) {

    }

    toColumnData() {
        return {};
    }

    toResultReference() {
        return {};
    }
}