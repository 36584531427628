import axios from "axios";
import {API_URL} from "../constants";
import {authHeader} from "../Utilities";

class CacheService {
  constructor() {
    this.projectsCache = {};
    this.workspacesCache = {};
    this.runsCache = {};
    this.sourceRunsCache = {};
  }

  getProjectData = (projectId) => {
    if (projectId in this.projectsCache) {
      return Promise.resolve(this.projectsCache[projectId]);
    } else {
      return axios.get(
          API_URL + `/projects/${projectId}`,
          {headers: authHeader()}
      ).then(result => {
        const projectData = result.data.project;
        this.projectsCache[projectId] = projectData;
        return projectData;
      });
    }
  }

  getRunDataFromFinalResult = (projectId, finalResultId) => {
    if (finalResultId in this.sourceRunsCache) {
      return Promise.resolve(this.sourceRunsCache[finalResultId]);
    } else {
      return axios.get(
          API_URL + `/projects/${projectId}/final_results/${finalResultId}/source_run`,
          {headers: authHeader()}
      ).then(result => {
        const runData = result.data;
        this.sourceRunsCache[finalResultId] = runData;
        return runData;
      });
    }
  }

  getRunData = (projectId, runId) => {
    if (runId in this.runsCache) {
      return Promise.resolve(this.runsCache[runId]);
    } else {
      return axios.get(
          API_URL + `/projects/${projectId}/processing_runs/${runId}`,
          {headers: authHeader()}
      ).then(result => {
        const runData = result.data;
        this.runsCache[runId] = runData;
        return runData;
      });
    }
  }

  getWorkspaceData = (workspaceId) => {
    if (workspaceId in this.workspacesCache) {
      return Promise.resolve(this.workspacesCache[workspaceId]);
    } else {
      return axios.get(
          API_URL + `/workspaces/${workspaceId}`,
          {headers: authHeader()}
      ).then(result => {
        const workspaceData = result.data;
        this.workspacesCache[workspaceId] = workspaceData;
        return workspaceData;
      });
    }
  }
}

export default new CacheService();
