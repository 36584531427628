import { connect } from 'react-redux'
import { ExtractTags } from '../components/ExtractTags';
import { scheduleDocumentsProcessing } from '../actions/actions';

const mapStateToProps = state => {
  return {
    runsList: (state.runsList.payload.processing_runs || []).map((runInfo) => ({
      run: {
        id: runInfo.id,
        name: runInfo.name
      },
      status: {
        totalPages: runInfo.total_pages,
        processedPages: runInfo.processed_pages
      },
      key: runInfo.id
    }))
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    processDocuments: (projectId, extractFlag) => { dispatch(scheduleDocumentsProcessing(projectId, extractFlag)); },
    dispatch
  }
};


const ExtractTagsContainer = connect(mapStateToProps, mapDispatchToProps)(ExtractTags)

export default ExtractTagsContainer
