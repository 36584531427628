import React from "react";

export class MgTableRow {
    object() {
        return (
            <></>
        );
    }

    status() {
        return (
            <></>
        );
    }

    mgStatus() {
        return (
            <></>
        );
    }

    actions() {
        return (
            <></>
        );
    }
}