import React from "react";
import {Button, Checkbox, Form, message, Modal, Select, Spin, Tooltip} from "antd";
import { DownloadOutlined } from '@ant-design/icons';
import axios from "axios";
import {API_URL} from "../../constants";
import {authHeader, download} from "../../Utilities";
import {Divider} from "antd/es";
import {RequestBodyFromFilters} from "./SearchFilters/RequestBodyFromFilters";
import {RemoteFiltersEditor} from "./SearchFilters/RemoteFiltersEditor";
import UserViewService from "../../services/UserViewService";


export default class ExportTags extends React.Component {
  state = {
    isModalShown: false,
    isLoading: false,
    statusMessage: '',
    reportsList: [],
    filters: [],
    filterSchemas: [],
    exportFinalizedResults: false
  }

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.loadAvailableReports();

    UserViewService.fetchSearchFiltersSchemas(schemas => {
      this.setState({filterSchemas: schemas});
    });
  }

  handleExportTags = () => {
    this.setState({
      isLoading: true,
      statusMessage: 'Preparing tags',
    })

    axios.post(`${API_URL}/user_view/export_tags`, {
          export_final_results: this.props.exportFinalResults,
          use_final_if_possible: this.state.exportFinalizedResults,
          run_id: this.props.runId,
          columns_config_id: this.formRef.current.getFieldValue('export_mode'),
          filters: new RequestBodyFromFilters(this.state.filters).getBody()
        },{ headers: authHeader() }
    ).then(response => {
      const taskId = response.data.task_id;
      message.info('Tags export started')
      const getProgress = () => {
        axios.get(`${API_URL}/user_view/export_tags/progress/${taskId}`, { headers: authHeader() })
            .then(response => {
              const progress = response.data;

              if (progress == null || (!progress.error && progress.completed !== true)) {
                setTimeout(() => getProgress(), 2000);
              }
              else {
                this.setState({statusMessage: 'Download started'});
                axios.get(`${API_URL}/user_view/export_tags/download/${taskId}`, {
                  headers: authHeader(),
                  responseType: 'blob'
                }).then((response) => {
                  const blob = new window.Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                  download(blob, "exported_tags.xlsx");
                  this.setState({statusMessage: 'Finished', isLoading: false});
                }).catch(() => {
                  message.error("Failed to download tags");
                })
              }
            })
      }

      getProgress();
    });
  }

  loadAvailableReports = () => {
    axios.get(`${API_URL}/user_view/export_tags/reports`, {
      headers: authHeader(),
    }).then(res => {
      this.setState({reportsList: res.data});
    }).catch(() => message.error('Failed to load reports list'))
  }

  showModal = () => {
    this.setState({
      isModalShown: true,
      isLoading: false,
      statusMessage: ''
    });
  }

  hideModal = () => {
    this.setState({isModalShown: false});
  }

  defaultRenderer = (id, onClick) => {
    return (
        <a id={id} onClick={onClick}>
          <Tooltip title="Export tags to excel">
            <DownloadOutlined style={{fontSize: '24px'}} />
          </Tooltip>
        </a>
    )
  }

  render() {
    const renderer = this.props.renderer ? this.props.renderer : this.defaultRenderer;

    return (
      <>
        {renderer("export-tags-to-excel", this.showModal)}
        <Modal
            title="Export tags"
            visible={this.state.isModalShown}
            footer={null}
            onCancel={this.hideModal}
        >
          <h2>{this.state.statusMessage} <Spin spinning={this.state.isLoading} style={{marginLeft: '16px'}}/></h2>
          <Form ref={this.formRef} initialValues={{ export_mode: null }}>
            <Form.Item label="Mode" name="export_mode" rules={[{required: true}]}>
              <Select id="modeSelect">
                {
                  this.state.reportsList.map(rep => (<Select.Option value={rep.id}>{rep.name}</Select.Option>))
                }

              </Select>
            </Form.Item>
            {!this.props.exportFinalResults &&
                <Form.Item label="Export finalized results">
                  <Checkbox
                      id="export-finalized-results-checkbox"
                      value={this.state.exportFinalizedResults}
                      onChange={e => this.setState({exportFinalizedResults: e.target.checked})}
                  />
                </Form.Item>
            }
            <RemoteFiltersEditor
                fetchOptionsUrl={this.props.fetchOptionsUrl}
                filterSchemas={this.state.filterSchemas}
                filters={this.state.filters}
                onFiltersChanged={filters => {
                  this.setState({filters: filters})
                }}
            />
            <Form.Item>
              <Button type="primary" onClick={this.handleExportTags} style={{float: 'right'}}>Export tags</Button>
            </Form.Item>
          </Form>
        </Modal>
      </>
    )
  }
}