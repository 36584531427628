import {Alert, Button, message, Modal, Progress, Space, Upload} from "antd";
import React, {useState} from "react";
import {UploadProps} from "antd/es/upload";
import {API_URL} from "../../../constants";
import {authHeader, download, safeFileName, UpdateJobProgress} from "../../../Utilities";
import {HttpRequestHeader} from "antd/es/upload/interface";
import axios from "axios";
import Hierarchy from "../../ImageViewerHandlers/Hierarchy/Hierarchy";
import moment from "moment";

export function BulkUpdateHierarchyModal({hierarchy, setVisible, visible, onOk}: {
    hierarchy: Hierarchy,
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
    visible: boolean,
    onOk: () => any
}) {
    return (
        <Modal
            title={`Bulk Update ${hierarchy.name}`}
            visible={visible}
            onCancel={() => setVisible(false)}
            destroyOnClose={true}
            footer={null}
        >
            <Space direction={"vertical"}>
                <DownloadExcel hierarchyId={hierarchy.id} hierarchyName={hierarchy.name}/>
                <UploadExcel
                    hierarchyId={hierarchy.id}
                    onOk={onOk}
                />
            </Space>
        </Modal>
    );
}

type DownloadExcelProps = {
    hierarchyId: string,
    hierarchyName: string,
}

function DownloadExcel({hierarchyId, hierarchyName}: DownloadExcelProps) {
    const [progress, setProgress] = useState({status: "", percent: 0});
    const [spinning, setSpinning] = useState(false);

    const start = () => {
        setSpinning(true);
        axios.post(
            `${API_URL}/hierarchies/${hierarchyId}/export_for_bulk_update`
        ).then(resp => {
            const taskId = resp.data;
            const updateProgress = new UpdateJobProgress().withDefaultUpdateProgress(setProgress);
            return updateProgress.do(
                `${API_URL}/hierarchies/${hierarchyId}/export_for_bulk_update/${taskId}/progress`
            ).then(_ => {
                return axios.get(
                    `${API_URL}/hierarchies/${hierarchyId}/export_for_bulk_update/${taskId}/download`,
                    {
                        headers: authHeader(),
                        responseType: "blob"
                    }
                ).then(response => {
                    const blob = new window.Blob([response.data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
                    download(blob, `hierarchy_dump_${safeFileName(hierarchyName)}_${moment().format("YYYY-MM-DD_HH-mm-ss")}.xlsx`);
                    message.success("Hierarchy exported successfully");
                });
            });
        }).catch(error => {
            console.error(error);
            message.error("Failed to export");
        }).finally(() => {
            setSpinning(false);
        });
    };

    return (
        <>
            <Button type={"primary"} onClick={() => start()}>
                Download excel
            </Button>
            {spinning && (
                <div>
                    {progress.status}
                    <Progress percent={progress.percent}/>
                </div>
            )}
        </>
    );
}

type UploadExcelProps = {
    hierarchyId: string,
    onOk: () => any,
}

function UploadExcel({hierarchyId, onOk}: UploadExcelProps) {
    const [progress, setProgress] = useState({status: "", percent: 0});
    const [spinning, setSpinning] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);

    const uploadProps: Partial<UploadProps> = {
        accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        name: "file",
        action: `${API_URL}/hierarchies/${hierarchyId}/bulk_update`,
        headers: authHeader() as HttpRequestHeader,
        data: {},
        onChange(info) {
            if (info.file.status === "done") {
                const updateProgress = new UpdateJobProgress().withDefaultUpdateProgress(setProgress);
                setError(undefined);
                setSpinning(true);
                updateProgress.do(
                    `${API_URL}/hierarchies/${hierarchyId}/bulk_update/${info.file.response}/progress`
                ).then(_ => {
                    message.success("Operation completed successfully");
                    onOk();
                }).catch(error => {
                    console.error(error);
                    setError(error.toString());
                    message.error("Failed to update");
                }).finally(() => {
                    setSpinning(false);
                });
            } else if (info.file.status === "error") {
                message.error("Failed to upload file!");
            }
        },
    };

    return (
        <>
            <Upload {...uploadProps} showUploadList={true}>
                <Button type={"primary"}>Upload excel</Button>
            </Upload>
            {spinning && (
                <div>
                    {progress.status}
                    <Progress percent={progress.percent}/>
                </div>
            )}
            {error != null && (
                <Alert message={error} type={"error"}/>
            )}
        </>
    );
}