import React, {useMemo, useState} from "react";
import {Button, Col, message, Modal, Popconfirm, Spin, Typography} from "antd";
import {HistoryOutlined} from "@ant-design/icons";
import {ViewHierarchy} from "./ViewHierarchy";
import HierarchiesService, {HierarchyLog, LogAuthor} from "../../../services/HierarchiesService";
import {ManualPagedTable} from "../../Misc/Table/ManualPagedTable";
import {AsyncDataSource} from "../../Misc/Table/AsyncDataSourceGeneric";
import {ColumnsType} from "antd/es/table";
import Hierarchy from "../../ImageViewerHandlers/Hierarchy/Hierarchy";
import moment from "moment/moment";

export function HierarchyHistoryModal({viewHierarchy, hierarchy, visible, setVisible}: {
    viewHierarchy: ViewHierarchy,
    hierarchy: Hierarchy;
    visible: boolean;
    setVisible: (visible: boolean) => void;
}) {
    const [isLoading, setIsLoading] = useState(false);
    const dataSource = useMemo<AsyncDataSource<HierarchyLog>>(
        () => ({
            async values(page: number, limit: number): Promise<HierarchyLog[]> {
                const res = await HierarchiesService.getHierarchyLogsByCurrentUser(
                    hierarchy.id,
                    page,
                    limit
                );
                return res.data;
            }
        }),
        [hierarchy]
    );

    const close = () => {
        setVisible(false);
    };

    const columns: ColumnsType<HierarchyLog> = [
        {
            title: 'Type',
            dataIndex: 'type',
        },
        {
            title: 'Created By',
            dataIndex: 'created_by',
            render: (createdBy: LogAuthor) => (
                <LogAuthorColumn author={createdBy}/>
            )
        },
        {
            title: 'Reverted By',
            dataIndex: 'reverted_by',
            render: (revertedBy?: LogAuthor) => (
                <LogAuthorColumn author={revertedBy}/>
            )
        },
        {
            title: 'Actions',
            dataIndex: 'id',
            render: (id, log) => {
                const revertIsDisabled = log.reverted_by != null;
                return (
                    <Popconfirm
                        disabled={revertIsDisabled}
                        title={"Are you sure to revert the log？"}
                        onConfirm={() => {
                            setIsLoading(true);
                            HierarchiesService.revertHierarchyLog(
                                hierarchy.id, id
                            ).then(
                                () => {
                                    viewHierarchy.reloadHierarchy(() => {
                                        message.success("Reverted");
                                    });
                                },
                            ).catch(
                                () => {
                                    message.error("Failed to revert")
                                }
                            ).finally(() => {
                                setIsLoading(false);
                            });
                        }}
                    >
                        <Typography.Link disabled={revertIsDisabled}>
                            Revert
                        </Typography.Link>
                    </Popconfirm>
                )
            }
        }
    ];

    return (
        <Modal
            width={800}
            title={"Hierarchy Change History"}
            visible={visible}
            onCancel={close}
            modalRender={node => (
                <Spin spinning={isLoading}>
                    {node}
                </Spin>
            )}
            footer={[]}
        >
            <ManualPagedTable
                asyncDataSource={dataSource}
                columns={columns}
                size={"small"}
            />
        </Modal>
    );
}

function LogAuthorColumn({author}: { author?: LogAuthor }) {
    if (author == null) {
        return <></>;
    } else {
        return (
            <Col>
                <div>
                    {author.user.name}
                </div>
                {moment.utc(author.date).local().format("MM/DD/YYYY, h:mm a")}
            </Col>
        );
    }
}

export function HierarchyHistoryButton({viewHierarchy}: { viewHierarchy: ViewHierarchy }) {
    return (
        <Button
            onClick={() => viewHierarchy.historyModalShow()}
            type={"link"}
            icon={<HistoryOutlined/>}
        />
    );
}
