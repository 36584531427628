import {viewerModes} from "../../constants";
import {fabric} from "fabric";
import {filter, map, switchMap, takeUntil, tap, withLatestFrom} from "rxjs/operators";
import {merge, Subject} from "rxjs";
import {MarkerTargetAsProperObj} from "../ImageViewer/Comments/Selection/MarkerBelonging";


export class NewCommentMarkerHandler {
    constructor(imageViewer) {
        this.imageViewer = imageViewer;
        this.crossLines = [];
        this.lastMousePoint = {x: 0, y: 0};
        this.creatingNewMarkerAborted$ = new Subject();
        this.creatingNewMarkerFinished$ = new Subject();
        this.newMarker = {
            position: {x: 0, y: 0},
            text: ''
        };
    }

    deactivateObjects = () => {
        this.imageViewer.canvas.getObjects().filter((obj) => obj.isProperObject).forEach(obj => {
            obj.evented = false;
        });
    }

    activateObjects = () => {
        this.imageViewer.canvas.getObjects().filter((obj) => obj.isProperObject).forEach(obj => {
            obj.evented = true;
        });
    }

    handleStart = () => {
        this.newMarker = {
            position: {x: 0, y: 0},
            text: ''
        };

        const th = this.imageViewer;

        th.objectSelected$.next({target: null, source: 'canvas'});
        th.mode$.next(viewerModes.CREATING_NEW_MARKER);

        this.deactivateObjects();

        th.canvas.defaultCursor = 'crosshair';
        th.canvas.setCursor('crosshair');

        let vertLine = new fabric.Line([
                this.lastMousePoint.x,
                0,
                this.lastMousePoint.x,
                th.imageHeight
            ],
            {stroke: 'black', strokeUniform: true, selectable: false, evented: false, strokeWidth: 1 / th.zoomHandler.zoomLevel});

        let horLine = new fabric.Line([
                0,
                this.lastMousePoint.y,
                th.imageWidth,
                this.lastMousePoint.y
            ],
            {stroke: 'black', strokeUniform: true, selectable: false, evented: false, strokeWidth: 1 / th.zoomHandler.zoomLevel});
        th.canvas.add(vertLine);
        th.canvas.add(horLine);
        this.crossLines = [vertLine, horLine]
        th.renderAll$.next(1);
    }

    clearLayout() {
        const th = this.imageViewer;
        th.canvas.defaultCursor = 'default';
        th.canvas.setCursor('default');
        this.crossLines.forEach((line) => th.canvas.remove(line));
        this.crossLines = []
        this.activateObjects();
        th.renderAll$.next(1);
    }

    handleFinish = () => {
        this.creatingNewMarkerFinished$.next(this.newMarker);
        this.imageViewer.mode$.next(viewerModes.NORMAL);
        this.clearLayout();
    }

    handleAbort = () => {
        this.creatingNewMarkerAborted$.next(1);
        this.imageViewer.mode$.next(viewerModes.NORMAL);
        this.clearLayout();
    }

    registerEvents = () => {
        // synchronize imageViewer.state.isCreatingObject value
        this.imageViewer.subscriptions.push(
            this.imageViewer.mode$.subscribe(
                mode => this.imageViewer.setState({isCreatingMarker: mode === viewerModes.CREATING_NEW_MARKER})
            )
        );

        const canvas = this.imageViewer.canvas;

        this.imageViewer.subscriptions.push(this.imageViewer.mouseDown$.pipe(
            withLatestFrom(this.imageViewer.mode$),
            filter(([_, mode]) => mode === viewerModes.CREATING_NEW_MARKER), map(([opt, _]) => opt),

            map(opt => ({corner: canvas.getPointer(opt.e)})),
            tap((opt) => {
                const textTarget = this.imageViewer.getCanvasObjects().find(
                    obj => new MarkerTargetAsProperObj(obj).containsMarker({position: {...opt.corner}})
                );

                this.newMarker.text = '';
                this.newMarker.position = {...opt.corner};

                if (textTarget) {
                    this.newMarker = new MarkerTargetAsProperObj(textTarget).attachedMarker({...this.newMarker});
                }

                this.imageViewer.renderAll$.next(1);
            }),
            switchMap(opt => this.imageViewer.mouseMove$.pipe(
                map(opt2 => ({otherCorner: canvas.getPointer(opt2.e)})),
                tap(opt2 => {
                    this.imageViewer.renderAll$.next(1);
                }),
                takeUntil(
                    merge(
                        this.imageViewer.mouseUp$,
                        this.creatingNewMarkerAborted$,
                    ).pipe(tap(() => this.handleFinish()))
                ),
            )),
        ).subscribe());

        // handle drawing cross lines on mouse move
        this.imageViewer.subscriptions.push(this.imageViewer.mode$.pipe(
            filter(mode => mode === viewerModes.CREATING_NEW_MARKER),
            switchMap(opt => this.imageViewer.mouseMove$.pipe(
                    map(opt => ({pointer: canvas.getPointer(opt.e)})),
                    tap(opt => {
                        if (this.crossLines.length !== 2) return;
                        const curPoint = opt.pointer;
                        this.crossLines[0].set('x1', curPoint.x)
                        this.crossLines[0].set('x2', curPoint.x)
                        this.crossLines[0].setCoords();
                        this.crossLines[1].set('y1', curPoint.y)
                        this.crossLines[1].set('y2', curPoint.y)
                        this.crossLines[1].setCoords();
                        this.imageViewer.renderAll$.next(1);
                    }),
                    takeUntil(this.imageViewer.mouseDown$),
                )
            ),
        ).subscribe());

        this.imageViewer.subscriptions.push(this.imageViewer.mouseMove$.subscribe(opt => {
            this.lastMousePoint = canvas.getPointer(opt.e);
        }));
    }
}