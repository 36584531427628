import React from "react";
import {ParentChildSelectorState} from "./Types/ParentChildSelectorState";
import {CutPasteState, cutPasteStateReset} from "./Types/CutPasteState";

type HierarchyTreeContextType = {
    parentChildState: ParentChildSelectorState;
    cutPasteState: CutPasteState;
};

export const HierarchyTreeContext = React.createContext<HierarchyTreeContextType>({
    parentChildState: {
        child: null,
        parent: null
    },
    cutPasteState: cutPasteStateReset()
});

type HierarchyTreeContextProviderProps = HierarchyTreeContextType & {
    children: React.ReactNode
};

export function HierarchyTreeContextProvider(
    {children, ...rest}: HierarchyTreeContextProviderProps
) {
    return (
        <HierarchyTreeContext.Provider
            children={children}
            value={rest}
        />
    );
}
