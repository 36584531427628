import {MgConflicts} from "./MgConflicts";
import {isObjectMatchFilters} from "../../../Misc/DataSourceFilter";

export class SmartMgConflicts {
    constructor(mgConflicts) {
        this._mgConflicts = mgConflicts || new MgConflicts();
    }

    addToCanvas() {
        this._mgConflicts.values().forEach(el => el.toCnCanvasObject().addToCanvas());
    }

    removeFromCanvas() {
        this._mgConflicts.values().forEach(el => el.toCnCanvasObject().removeFromCanvas());
    }

    updateVisibility(imageViewer, obj) {
        const selectionParams = imageViewer.state.selectionParams;
        const filters = imageViewer.state.mgFilters || [];
        const extraVisibleObjectsIds = imageViewer.state.extraVisibleObjectsIds;
        const canvas = imageViewer.canvas;

        let isVisible = selectionParams.includes(obj.objectMetadata.label) || obj === canvas.getActiveObject()
            || extraVisibleObjectsIds.has(obj.objectMetadata.id);

        const mgConflict = this._mgConflicts.values()
            .find(el => el.relatedToCanvasObject(obj));

        if (!mgConflict) return;

        const isMatchedFilters = isObjectMatchFilters(mgConflict, filters);

        isVisible = isVisible || isMatchedFilters;

        if (isVisible) {
            mgConflict.toCnCanvasObject().show();
        } else {
            mgConflict.toCnCanvasObject().hide();
        }

        if (isMatchedFilters) {
            mgConflict.toCnCanvasObject().addHighlight();
        } else {
            mgConflict.toCnCanvasObject().removeHighlight();
        }
    }
}