import { connect } from 'react-redux'
import { Statistics } from '../components/Statistics'

const mapStateToProps = state => {
  return {
    summary: state.statistics.summary,
    fileTypesStatistics: state.statistics.fileTypesStatistics
  }
}

const StatisticsContainer = connect(mapStateToProps)(Statistics)

export default StatisticsContainer