import {useEffect, useState} from "react";

type FetchFactory<T> = () => Promise<T>;
type OnOk<T> = ((result: T) => void) | undefined;
type OnErr = ((err: any) => void) | undefined;

export function useFetch<T>(fetchFactory: FetchFactory<T>, onOk: OnOk<T> = undefined, onErr: OnErr = undefined, deps: any[] = []) {
    useEffect(() => {
        const controller = new AbortController();
        const {signal} = controller;
        fetchFactory().then(result => {
            if (!signal.aborted) {
                onOk?.(result);
            }
        }).catch((error: any) => {
            if (signal.aborted) return;

            if (onErr) {
                onErr(error);
            } else {
                throw error;
            }
        });
        return () => controller.abort();
    }, deps); // eslint-disable-line react-hooks/exhaustive-deps
}

export function useFetchWithLoading<T>(fetchFactory: FetchFactory<T>, onOk: OnOk<T> = undefined, onErr: OnErr = undefined, deps: any[] = []) {
    const [isLoading, setIsLoading] = useState(true);
    useFetch(() => {
        setIsLoading(true);
        return fetchFactory().finally(() => setIsLoading(false));
    }, onOk, onErr, deps);
    return isLoading;
}
