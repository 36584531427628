import React, {useRef, useState} from "react";
import {AutoSizer} from "react-virtualized";

interface ImmortalAutoSizerProps {
    children: (size: Size) => React.ReactNode
}

interface Size {
    width: number
    height: number
}

export function ImmortalAutoSizer({children} : ImmortalAutoSizerProps) {
    const rerender = useState<{}>({});
    const size = useRef<Size>();
    return (
        <>
            <AutoSizer children={currentSize => {
                if (currentSize.width !== size.current?.width || currentSize.height !== size.current?.height) {
                    rerender[1]({});
                }
                size.current = currentSize as Size;
                return null;
            }}/>
            <div style={{overflow: 'visible', height: '0px', width: '0px'}}>
                {size.current != null && children(size.current)}
            </div>
        </>
    );
}
