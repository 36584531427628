import Point from "../Geometry/Point";
import HierarchiesService from "../../../services/HierarchiesService";
import {message} from "antd";
import TagUrlLinkService from "../../../services/TagUrlLinkService";
import HierarchyNode from "./HierarchyNode";
import ImageViewerObject, {getAttributes} from "../ImageViewerObject";
import AllResultsService from "../../../services/AllResultsService";

export default class ObjectReference {
    public id: string | null;
    public page_id: string;
    public x_rel: number;
    public y_rel: number;
    public node: HierarchyNode;

    constructor({id, page_id, x_rel, y_rel, node}: {
        id: string | null, page_id: string, x_rel: number, y_rel: number, node: HierarchyNode
    }) {
        this.id = id;
        this.page_id = page_id;
        this.x_rel = x_rel;
        this.y_rel = y_rel;
        this.node = node;
    }

    isOnPageReference() {
        return this.findReferencedObject() != null;
    }

    clone() {
        return new ObjectReference({
            id: this.id,
            page_id: this.page_id,
            x_rel: this.x_rel,
            y_rel: this.y_rel,
            node: this.node,
        });
    }

    getDict() {
        return {
            id: this.id,
            page_id: this.page_id,
            x_rel: this.x_rel,
            y_rel: this.y_rel,
        };
    }

    getPoint() {
        return new Point(
            this.x_rel * this.node.hierarchy.imageViewer.imageWidth!,
            this.y_rel * this.node.hierarchy.imageViewer.imageHeight!
        );
    }

    matchesObject(obj: ImageViewerObject) {
        if (!this.pointsToTheCurrentPage()) return false;
        if (obj.text !== this.node.text || obj.label !== this.node.label) return false;
        return (obj.getRect().containsPoint(this.getPoint()));
    }

    // returns null if the object is not found on the drawing
    findReferencedObject() {
        if (!this.pointsToTheCurrentPage()) return null;
        return this.node.hierarchy.imageViewer.state.allObjects?.find(obj => this.matchesObject(obj));
    }

    getObjectAttributes() {
        const node = this.node;
        return HierarchiesService.getHierarchyNodeReferenceTagReference(
            node.hierarchy, node, this
        ).then(res => {
            if (!res.data.tag_number) return null;
            return AllResultsService.getAnnotationObject(res.data).then(
                res => {
                    if (res.data) return getAttributes(res.data);
                    return null;
                }
            );
        }).catch(() => null);
    }

    open() {
        HierarchiesService.getHierarchyNodeReferenceTagReference(this.node.hierarchy, this.node, this).then(res => {
            if (!res.data.tag_number) {
                message.warning('Invalid tag reference');
            } else {
                return TagUrlLinkService.generateTagLinkByResultReference(
                    res.data.result_reference.result_id,
                    res.data.result_reference.is_final,
                    res.data.tag_number,
                    res.data.tag_id,
                    `?hierarchy_id=${this.node.hierarchy.id}`
                ).then(tagLink => {
                    this.node.hierarchy.imageViewer.props.history.push(tagLink);
                });
            }
        }).catch(() => {
            message.error('Failed to get tag reference');
        });
    }

    private pointsToTheCurrentPage(): boolean {
        return this.page_id === this.node.hierarchy.imageViewer.state.pageId;
    }
}
