import React from "react";
import {Button, Card, Form, Input, Layout, message, Modal, Row, Select, Table, Typography} from "antd";
import {Link} from "react-router-dom";
import OrganizationsService from "../../services/OrganizationsService";
import WorkspacesService from "../../services/WorkspacesService";
import {PermissionsList} from "../Misc/PermissionsList";

const { Option } = Select;

export class NewOrganization extends React.Component {
  state = {
    permissionsList: [],
    isPermissionModalShown: false,
    workspacesList: [],
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();
    this.addPermissionFormRef = React.createRef();
  }

  componentDidMount() {
    this.loadWorkspaces();
  }

  loadWorkspaces = () => {
    WorkspacesService.fetchWorkspaces(true).then(workspacesList => {
      this.setState({workspacesList: workspacesList.data});
    }).catch(() => message.error("Failed to load workspaces list"));
  }

  onSubmit = (values) => {
    const newOrganization = {
      ...values,
      permissions: this.state.permissionsList,
    };

    OrganizationsService.addOrganization(newOrganization)
        .then(() => {
          message.success("Organization created");
          this.props.history.push("/organizations");
        }).catch(() => message.error("Failed to create organization"));
  }

  render() {
    const permissionsColumns = [
      {
        title: 'Type',
        dataIndex: 'permission_type',
      },
      {
        title: 'Target',
        dataIndex: 'target',
      }
    ]
    return (
      <>
        <Row style={{justifyContent: 'center'}}>
          <Card
              title="New organization"
              style={{width: '50%'}}>
            <Form
                labelCol={{span: 8}} wrapperCol={{span: 16}}
                ref={this.formRef} name="new-organization-form" onFinish={this.onSubmit}>
              <Form.Item name={'name'} label="Name" rules={[{ required: true }, { min: 3 }]}>
                <Input />
              </Form.Item>

              <PermissionsList
                  permissionsList={this.state.permissionsList}
                  isEditable={true}
                  availablePermissionsTypes={['view_results', 'finalize_results', 'hierarchies', 'comments']}
                  onChange={plist => this.setState({permissionsList: plist})}
              />

              {/*<Form.Item name={'email'} label="Email" rules={[{ type: 'email', required: true }]}>*/}
              {/*  <Input />*/}
              {/*</Form.Item>*/}
              {/*<Form.Item name={'phone_number'} label="Phone number">*/}
              {/*  <Input />*/}
              {/*</Form.Item>*/}
              {/*<Form.Item name={'message'} label="Message" rules={[{ required: true }]}>*/}
              {/*  <Input.TextArea rows={6}/>*/}
              {/*</Form.Item>*/}
              <Row style={{justifyContent: 'space-between', marginTop: '16px'}}>
                <Button><Link to="/organizations">Cancel</Link></Button>
                <Button type="primary" htmlType="submit">
                  Create
                </Button>
              </Row>
            </Form>
          </Card>
        </Row>



      </>
    )
  }
}