import React from "react";
import WorkspacesService from "../../services/WorkspacesService";
import {Button, Card, Form, message, Modal, Popconfirm, Row, Select, Table, Tooltip} from "antd";
import {Link} from "react-router-dom";
import { DeleteOutlined } from '@ant-design/icons';


const { Option } = Select;

const PERMISSIONS_MAPPING = {
  view_results: 'View results',
  finalize_results: 'Finalize results',
  data_extraction: 'Data extraction',
  hierarchies: 'Hierarchies',
  comments: 'Comments'
}



export class PermissionsList extends React.Component {
  state = {
    workspacesList: [],
    isPermissionModalShown: false,
  }

  componentDidMount() {
    this.loadWorkspaces();
  }

  loadWorkspaces = () => {
    WorkspacesService.fetchWorkspaces(true).then(workspacesList => {
      this.setState({workspacesList: workspacesList.data});
    }).catch(() => message.error("Failed to load workspaces list"));
  }

  hidePermissionModal = () => {
    this.setState({isPermissionModalShown: false});
  }

  showPermissionModal = () => {
    this.setState({isPermissionModalShown: true});
  }

  onAddPermission = (values) => {
    const newPermission = { ...values, target_type: 'workspace' };
    this.props.onChange([...this.props.permissionsList, newPermission])
    this.hidePermissionModal();
  }

  getWarningMessage = workspace_name => {
    return `Warning: Missing 'View results' permission for workspace ${workspace_name}. The user will not be able to view the results.`
  }

  checkPermissions = () => {
    let permissionsList = this.props.permissionsList;
    let warnings = []
    if (!permissionsList) {
      return warnings
    }
    let finalised = permissionsList.filter(permission => permission['permission_type'] === 'finalize_results')
    if (!finalised) {
      return warnings
    }
    finalised.forEach(finalised => {
      let view = permissionsList.find(permission => permission['permission_type'] === 'view_results' && finalised['target'] === permission['target'])
      if (!view) {
        let workspace = this.state.workspacesList.find(workspace => workspace.id === finalised['target'])
        if (workspace) {
          warnings.push(<div id="warning-no-view-permission" style={{color: 'red'}}>{this.getWarningMessage(workspace.name)}</div>)
        }
      }
    })
    return warnings
  }

  render() {
    const permissionsColumns = [
      {
        title: 'Type',
        dataIndex: 'permission_type',
        render: (match) => {
          return PERMISSIONS_MAPPING[match] || match
        }
      },
      {
        title: 'Target',
        dataIndex: 'target',
        render: (match) => {
          let result = match;
          this.state.workspacesList.forEach(w => {
            if (w.id === match) result = w.name;
          })
          return result;
        }
      },
      {
        render: (match, record, ind) => {
          return (
              <>
                {
                  this.props.isEditable &&
                  <Tooltip title="Delete" placement="right">
                    <Popconfirm
                        title="Are you sure to delete the permission？"
                        onConfirm={() => this.props.onChange(this.props.permissionsList.filter((el, i) => el !== record))}
                    >
                      <a>
                        <DeleteOutlined/>
                      </a>
                    </Popconfirm>
                  </Tooltip>
                }
              </>
          )
        }
      }
    ];

    const extra = (this.props.isEditable &&
          <Button onClick={this.showPermissionModal} style={{marginLeft: 'auto'}}>Add permission</Button>
    );
    const th = this;
    return (
      <div style={this.props.style}>
        <Card title={this.props.title || "Permissions"} size="small" type="inner" extra={extra}>
          {this.checkPermissions()}
          <Table
              dataSource={this.props.permissionsList} columns={permissionsColumns} size="small"
              pagination={{hideOnSinglePage: true}}
            />
        </Card>
        <Modal
            title="Add permission"
            visible={this.state.isPermissionModalShown}
            footer={null}
            onCancel={this.hidePermissionModal}
            width="40vw"
            bodyStyle={{height: 'auto'}}
        >
          <Form
              labelCol={{span: 8}} wrapperCol={{span: 16}}
              ref={this.addPermissionFormRef} name="new-permission-form"
              onFinish={this.onAddPermission}
          >
            <Form.Item name="permission_type" label="Type" rules={[{ required: true }]}>
              <Select>
                {
                  (this.props.availablePermissionsTypes || []).map(permType =>
                    <Option value={permType}>{PERMISSIONS_MAPPING[permType] || permType}</Option>
                  )
                }
              </Select>
            </Form.Item>

            <Form.Item name="target" label="Target" dependencies={['permission_type']}
                       rules={[
                         { required: true },
                         ({ getFieldValue }) => ({
                           validator(_, value) {
                             if (!value || th.props.permissionsList.findIndex(el =>
                                 el.permission_type === getFieldValue('permission_type') && el.target === value) === -1) {
                               return Promise.resolve();
                             }
                             return Promise.reject('Permission already exists');
                           },
                         }),
                       ]}>
              <Select
                  showSearch
                  filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
              >
                {
                  this.state.workspacesList.map(w => (<Option value={w.id}>{w.name}</Option>))
                }
              </Select>

            </Form.Item>
            <Row style={{justifyContent: 'space-between', marginTop: '16px'}}>
              <Button onClick={this.hidePermissionModal}>Cancel</Button>
              <Button type="primary" htmlType="submit">
                Add
              </Button>
            </Row>
          </Form>
        </Modal>
      </div>
    )
  }
}
