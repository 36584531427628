const {EditedFilter} = require("./EditedFilter");

export class NullEditedFilter extends EditedFilter {
    canBeAdded() {
        return false;
    }

    toConcreteFilter() {
        return {};
    }
}