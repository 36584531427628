export class AnimatedCnDetails {
    constructor(data) {
        this._data = data;
    }

    keys() {
        return this._data.map(el => el.key);
    }

    values(key) {
        const recordByKey = this._data.find(el => el.key === key);

        return [recordByKey.current.value, recordByKey.another.value];
    }

    hasValueByKey(key, value) {
        const recordByKey = this._data.find(el => el.key === key);

        return recordByKey.current.value === value || recordByKey.another.value === value;
    }
}