import React from "react";
import {Attributes} from "./Attributes";
import {CnCanvasObject} from "./CnCanvasObject";

export class CnObject {
    selectOnCanvas() {

    }

    toCnCanvasObject() {
        return new CnCanvasObject();
    }

    toTag() {
        return <></>;
    }

    toTagHash() {
        return '';
    }

    relatedToCanvasObject(canvasObj) {
        return false;
    }

    attrs() {
        return new Attributes();
    }
}