import React from "react";
import {fetchRuns} from "../actions/actions";
import axios from "axios";
import {API_URL} from "../constants";
import {Button, Col, Form, Input, Modal, Popconfirm, Row, Select, Table, Card, Radio} from "antd";
import {authHeader} from "../Utilities";

export class LinkingRules extends React.Component {
  state = {
    rulesList: [],
    newRuleVisible: false,
    newRuleName: '',
    newRuleTargetTagType: '',
    newRuleTextFilterType: '',
    newRuleSpatialFilterType: '',
    newRuleTextFilterRegexp: '',
    newRuleTextFilterTextRuleName: '',
    newRuleSpatialFilterDistanceThreshold: '',
    newRuleScale: '1',
    newRuleDataTarget: 'text',
  }

  loadRules = (projectId) => {
    axios.get(
        API_URL + `/projects/${projectId}/linking_rules`,
        {headers: authHeader()}
    ).then(result => {
      const rulesList = result.data.rules_list;
      this.setState({
        rulesList: rulesList,
      });
    });
  }

  showModal = () => {
    this.setState({newRuleVisible: true});
  }

  handleNewLinkingRule = () => {
    this.setState({
          newRuleName: '',
          newRuleTargetTagType: '',
          newRuleTextFilterType: 'none',
          newRuleSpatialFilterType: 'within-bbox',
          newRuleScale: '1',
          newRuleDataTarget: 'text',
        },() => this.showModal()
    )
  }

  handleNewRuleOk = () => {
    let textFilter = {
      filter_type: this.state.newRuleTextFilterType
    };

    if (this.state.newRuleTextFilterType === 'none') {
      textFilter = null;
    } else if (this.state.newRuleTextFilterType === 'regexp') {
      textFilter.regexp = this.state.newRuleTextFilterRegexp;
    } else if (this.state.newRuleTextFilterType === 'from-text-rule') {
      textFilter.text_rule_target_tag = this.state.newRuleTextFilterTextRuleName
    }

    let spatialFilter = {
      filter_type: this.state.newRuleSpatialFilterType
    };

    if (this.state.newRuleSpatialFilterType === 'closest') {
      spatialFilter.distance_threshold = parseInt(this.state.newRuleSpatialFilterDistanceThreshold)
    }

    if (this.state.newRuleSpatialFilterType === 'within-bbox') {
      spatialFilter.scale = parseFloat(this.state.newRuleScale)
    }


    let ruleDict = {
      name: this.state.newRuleName,
      rule_data: {
        target_tag_type: this.state.newRuleTargetTagType,
        text_filter: textFilter,
        location_filter: spatialFilter,
        data_target: this.state.newRuleDataTarget,
      }
    };

    axios.post(API_URL + `/projects/${this.props.match.params.projectId}/linking_rules`, ruleDict, {headers: authHeader()}).then(result => {
      this.setState({newRuleVisible: false},
        () => this.loadRules(this.props.match.params.projectId)
      )
    })
  }

  handleNewRuleCancel = () => {
    this.setState({newRuleVisible: false})
  }

  handleLinkingRuleRemoval = (projectId, ruleId) => {
    axios.delete(API_URL + `/projects/${projectId}/linking_rules/${ruleId}`, {headers: authHeader()}).then(result => {
      this.loadRules(projectId)
    });
  }

  componentDidMount() {
    this.loadRules(this.props.match.params.projectId)
  }

  render() {
    const linkingRulesColumns = [
      {
        title: 'Name',
        dataIndex: 'name',
      },
      {
        title: 'Description',
        dataIndex: 'description'
      },
      {
        title: 'Actions',
        dataIndex: 'id',
        render: (ruleId) => {
          return (
              <Popconfirm title="Are you sure to delete the rule？" onConfirm={() => this.handleLinkingRuleRemoval(this.props.match.params.projectId, ruleId)}>
                <a href="#">Delete</a>
              </Popconfirm>
          )
        }
      }
    ]

    const radioStyle = {display: 'block', marginTop: '8px'}

    return (
      <>
        <Row style={{justifyContent: 'flex-end'}}>
          <Button type="primary" onClick={this.handleNewLinkingRule}>New rule</Button>
        </Row>
        <Table id="linking-rules-list" dataSource={this.state.rulesList} columns={linkingRulesColumns} size="small" style={{paddingTop: '16px'}} />

        <Modal
            title="New rule"
            visible={this.state.newRuleVisible}
            onOk={() => this.handleNewRuleOk()}
            onCancel={this.handleNewRuleCancel}
            width="80vw"
            bodyStyle={{height: '65vh'}}
        >
          <Row>
            <Col span={5}>
              <Form.Item label="Rule name" style={{marginBottom: '4px'}}>
                <Input value={this.state.newRuleName} onChange={(e) => this.setState({newRuleName: e.target.value})}/>
              </Form.Item>

              <Form.Item label="Target tag type">
                <Input value={this.state.newRuleTargetTagType} onChange={(e) => this.setState({newRuleTargetTagType: e.target.value})}/>
              </Form.Item>

              <Card title="Text filter" size="small">
                <Radio.Group value={this.state.newRuleTextFilterType} onChange={(e) => this.setState({newRuleTextFilterType: e.target.value})}>
                  <Radio style={radioStyle} value="none">None</Radio>
                  <Radio style={radioStyle} value="regexp">
                    Regexp:
                    <Input style={{width: '50%', marginLeft: '8px'}} onChange={(e) => this.setState({newRuleTextFilterRegexp: e.target.value})}/>
                  </Radio>
                  <Radio style={radioStyle} value="from-text-rule">
                    From text rule:
                    <Input style={{width: '50%', marginLeft: '8px'}} onChange={(e) => this.setState({newRuleTextFilterTextRuleName: e.target.value})}/>
                  </Radio>
                </Radio.Group>
              </Card>

              <Card title="Spatial filter" size="small" >
                <Radio.Group value={this.state.newRuleSpatialFilterType} onChange={(e) => this.setState({newRuleSpatialFilterType: e.target.value})}>
                  <Radio style={radioStyle} value="within-bbox">
                    Within bounding box
                    <br />
                    Scale: <Input style={{width: '50%', marginLeft: '8px'}} value={this.state.newRuleScale} onChange={(e) => this.setState({newRuleScale: e.target.value})}/>
                  </Radio>
                  <Radio style={radioStyle} value="closest">
                    Closest
                    <br />
                    {/*Distance threshold: <Input style={{width: '50%', marginLeft: '8px'}}/>*/}
                  </Radio>
                </Radio.Group>
              </Card>
              <Form.Item label="Data target">
                <Input value={this.state.newRuleDataTarget} onChange={(e) => this.setState({newRuleDataTarget: e.target.value})}/>
              </Form.Item>
              {/*<Button type="primary" onClick={*/}
              {/*  () => this.handlePreview({*/}
              {/*    sourceString: ((sourceString || {}).state || {}).value,*/}
              {/*    targetString: ((targetString || {}).state || {}).value*/}
              {/*  })*/}
              {/*}>Preview</Button>*/}

              <Button type="primary" />
            </Col>
          </Row>
        </Modal>
      </>
    )
  }
}