import React from "react";
import OrganizationsService from "../../services/OrganizationsService";
import {Button, Card, Form, Input, message, Row} from "antd";
import {Link} from "react-router-dom";
import {PermissionsList} from "../Misc/PermissionsList";
import axios from "axios";
import {API_URL} from "../../constants";
import {authHeader} from "../../Utilities";
import {AuthContext} from "../../contexts/AuthContext";

export class EditOrganization extends React.Component {
  static contextType = AuthContext;

  state = {
    organization: null,
  }

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.loadOrganization();
  }

  loadOrganization = () => {
    OrganizationsService.fetchOrganizationById(this.props.match.params.organizationId).then(org => {
      this.formRef.current.setFieldsValue(org.data);
      this.setState({organization: org.data});
    }).catch(() => message.error("Failed to load organization"));
  }

  onSubmit = (values) => {
    const updatedOrganization = {
      ...values,
      id: this.props.match.params.organizationId,
      permissions: this.state.organization.permissions,
    };

    OrganizationsService.updateOrganization(updatedOrganization)
        .then(() => {
          message.success("Organization updated");
          this.props.history.push(`${this.props.root}organizations/${this.props.match.params.organizationId}`);
        }).catch(() => message.error("Failed to update organization"));
  }
  render() {
    const isAdmin = this.context.user && this.context.user.roles.includes('admin');
    return (
        <Row style={{justifyContent: 'center'}}>
          <Card
              title="Edit organization"
              style={{width: '50%'}}>
            <Form
                labelCol={{span: 8}} wrapperCol={{span: 16}}
                ref={this.formRef} name="edit-organization-form" onFinish={this.onSubmit}>
              <Form.Item name={'name'} label="Name" rules={[{ required: true }, { min: 3 }]}>
                <Input disabled={!isAdmin}/>
              </Form.Item>
              <PermissionsList
                  permissionsList={this.state.organization?.permissions}
                  isEditable={true}
                  availablePermissionsTypes={['view_results', 'finalize_results', 'hierarchies', 'comments']}
                  onChange={plist => this.setState({organization: {...this.state.organization, permissions: plist}})}
              />
              <Row style={{justifyContent: 'space-between', marginTop: '16px'}}>
                <Button><Link to={`/organizations/${this.props.match.params.organizationId}`}>Cancel</Link></Button>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Row>
            </Form>
          </Card>
        </Row>
    )
  }
}