import {CnResults} from "../CnResults";
import {RemoteCnResult} from "./RemoteCnResult";
import {SafeCnObject} from "../Safe/SafeCnObject";
import {AnnotationCnObject} from "../Annotation/AnnotationCnObject";
import {AnnotationObjectAttributes} from "../Annotation/AnnotationObjectAttributes";
import {TagCnStatus} from "../Tag/TagCnStatus";
import {CurrentAnnotationCnCanvasObject} from "../Annotation/CurrentAnnotationCnCanvasObject";
import {AnotherAnnotationCnCanvasObject} from "../Annotation/AnotherAnnotationCnCanvasObject";

export class RemoteCnResults extends CnResults {
    constructor(data, selectCanvasObjectById, selectOtherObjectById, canvas) {
        super();

        this._data = data;
        this._selectCanvasObjectById = selectCanvasObjectById;
        this._selectOtherObjectById = selectOtherObjectById;
        this._canvas = canvas;

        this._fillColor = (status) => {
            if (status === 'MOVED') {
                return 'rgba(128,0,255,0.6)';
            } else if (status === 'REMOVED') {
                return 'rgba(255,0,0,0.6)';
            } else if (status === 'ADDED'){
                return 'rgba(0, 255, 0, 0.6)';
            } else if (status === 'MODIFIED') {
                return 'rgba(0,0,255,0.6)';
            } else if(status === 'SAME') {
                return 'rgba(0,0,0,0)';
            } else {
                return 'rgba(0,0,0,0)';
            }
        }
    }

    loaded() {
        return true;
    }

    values() {
        return this._data.map((el, idx) => new RemoteCnResult(
            idx,
            new SafeCnObject(
                new AnnotationCnObject(
                    el.current,
                    this._selectCanvasObjectById,
                    new AnnotationObjectAttributes(el.current),
                    new CurrentAnnotationCnCanvasObject(
                        el.current,
                        this._canvas,
                        () => this._fillColor(el.status)
                    )
                )
            ),
            new SafeCnObject(
                new AnnotationCnObject(
                    el.another,
                    this._selectOtherObjectById,
                    new AnnotationObjectAttributes(el.another),
                    new AnotherAnnotationCnCanvasObject(
                        el.current,
                        el.another,
                        this._canvas,
                        () => this._fillColor(el.status),
                        el.status
                    )
                )
            ),
            new TagCnStatus(el.status)
        ));
    }
}