import React from 'react'
import { Table, Progress, Button, Card, Spin, Modal } from 'antd'
import { InboxOutlined } from '@ant-design/icons';
import filesize from 'filesize'
import traverseFileTree from './traverseFileTree';
import { sec2time } from '../../Utilities';

export class UploadDocuments extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      progress: {},
      elapsed: 0,
      speed: 0,
      eta: 0,
      scannedFiles: 0,
      initializedFiles: 0,
    };

    this.fileInput = React.createRef();
    this.directoryInput = React.createRef();

    this.totalUploadedPrevious = 0;
    this.isUploading = false;

    this.dropHandler = this.dropHandler.bind(this);
    this.dragOverHandler = this.dragOverHandler.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.interval == null) {
      const callInterval = 1000;
      this.interval = setInterval(() => this.updateTime(callInterval), callInterval);
    }

    this.updateStatus();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  updateTime = (elapsedTime) => {
    const seconds = elapsedTime / 1000;

    const values = Object.values(this.props.progress);
    const totalUploaded = values.reduce((a, b) => a + b.uploadedBytes, 0);
    const totalSize = values.reduce((a, b) => a + b.file.size, 0);

    const elapsed = this.state.elapsed + seconds;
    const speed = Math.max(0, (totalUploaded - this.totalUploadedPrevious)) / seconds;
    const eta = (totalSize - totalUploaded) / speed;

    this.totalUploadedPrevious = totalUploaded;

    this.setState((prevState) => {
      return {
        ...prevState,
        speed,
        elapsed,
        eta,
      }
    });
  }

  updateStatus() {
    const { progress } = this.props;
    const values = Object.values(progress);
    const isAllCompleted = values.every(item => item.completed || item.error);
    this.isUploading = !isAllCompleted;

    if (!this.isUploading) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }


  handleClickUploadFile = (e) => {
    this.fileInput.current.click();
  }

  handleClickUploadDirectory = (e) => {
    this.directoryInput.current.click();
  }

  handleChangeFileInput = (e) => {
    this.handleSelectedFilesBrowser(this.fileInput.current);
  }

  handleChangeDirectoryInput = (e) => {
    this.handleSelectedFilesBrowser(this.directoryInput.current);
  }

  handleSelectedFilesBrowser(input) {
    const files = Array.from(input.files);

    this.showModal();
    this.setState({
      scannedFiles: files.length,
    })

    setTimeout(() => {
      this.uploadFiles(files)
      input.value = '';
    }, 1000)
  }



  dragOverHandler(ev) {
    ev.preventDefault();
  }

  dropHandler(e) {
    e.preventDefault();

    const files = [];
    const callback = (_files) => {
      files.push(_files[0]);

      // update state every 100 files
      if (files.length % 100 === 0) {
        this.setState({
          scannedFiles: files.length,
        })
      }
    }

    const endCallback = () => {
      this.setState({
        scannedFiles: files.length,
      })
      this.uploadFiles(files);
    }

    this.showModal();
    traverseFileTree(
      Array.prototype.slice.call(e.dataTransfer.items),
      callback,
      _file => true,
      endCallback
    );
  }



  getFileName(file) {
    return file.webkitRelativePath || file.name;
  }

  uploadFiles(files) {
    this.initializeFilesSize(files).then(res => {
      this.hideModal();

      // filter new files or files with error while uploading
      const newFiles = files.filter(file => {
        const entity = this.props.progress[this.getFileName(file)];
        return !entity || entity.error != null;
      });

      this.props.startUploadFiles(newFiles, this.getFileName);
      newFiles.forEach(file => this.props.uploadFile(this.props.match.params.projectId, file, this.getFileName(file)));
    })
  }

  initializeFilesSize(array, chunkSize = 100) {
    const promise = new Promise(resolve => {
      let index = 0;
      const doWork = () => {
        const to = Math.min(array.length, index + chunkSize);
        for (; index < to; index++) {
          // initialize file size, it takes a while on first call
          const _ = array[index].size;
        }

        this.setState({
          initializedFiles: index,
        })

        if (index < array.length) {
          setTimeout(doWork, 0);
        }
        else {
          resolve();
        }
      }

      doWork();
    })

    return promise;
  }


  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  hideModal = () => {
    this.setState({
      visible: false,
    });
  };




  getProgressBar = (row) => {
    const { progress, error, completed } = row;
    const style = {
      paddingRight: "10px",
    }

    if (error)
      return <Progress percent={progress} status="exception" style={style} />;

    if (completed)
      return <Progress percent={progress} style={style} />;

    return <Progress percent={progress} status="active" style={style} />;
  }

  getOverallProgressBar = (row) => {
    const { progress, error, completed } = row;

    if (error)
      return <Progress percent={progress} status="exception" />;

    if (completed)
      return <Progress percent={progress} />;

    return <Progress percent={progress} status="active" />;
  }

  columns = [
    {
      title: 'File name',
      dataIndex: 'file_name',
      render: text => (
        <React.Fragment>
          <span
            className={"fiv-viv fiv-icon-" + text.split('.').pop().toLowerCase()}
            style={{ fontSize: '1.5em', marginRight: '0.5em' }}
          />
          {text}
        </React.Fragment>
      ),
      sorter: (a, b) => a.file_name.localeCompare(b.file_name),
    },
    {
      title: 'Progress',
      dataIndex: 'progress',
      render: (text, row, index) => this.getProgressBar(row),
      sorter: (a, b) => a.progress - b.progress,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: (a, b) => a.status.localeCompare(b.status),
      filters: [
        {
          text: 'OK',
          value: 'OK',
        },
        {
          text: 'Existed',
          value: 'Existed',
        },
        {
          text: 'Pending',
          value: 'Pending',
        },
        {
          text: 'Uploading',
          value: 'Uploading',
        },
        {
          text: 'Error',
          value: 'Error',
        },
      ],
      filterMultiple: true,
      onFilter: (value, record) => {
        if (value === 'Error')
          return record.error != null;

        return record.status === value;
      },
    },
    {
      title: 'Size',
      dataIndex: 'size',
      render: text => filesize(text),
      sorter: (a, b) => a.size - b.size,
    }
  ];

  columnsProgress = [
    {
      title: 'Speed',
      dataIndex: 'speed',
      width: '10%',
    },
    {
      title: 'Uploaded',
      dataIndex: 'uploaded',
      width: '50%',
      render: (text, row) => (
        <>
          <span style={{ marginRight: "10px" }}>{text}</span>
          {this.getOverallProgressBar(row.overallProgress)}
        </>
      ),
    },
    {
      title: 'Elapsed',
      dataIndex: 'elapsed',
      width: '10%',
    },
    {
      title: 'ETA',
      dataIndex: 'eta',
      width: '10%',
    },
    {
      title: 'Files',
      dataIndex: 'filesProgress',
      render: (text, row) => {
        const percentCompleted = Math.round((row.filesProgress.completed * 100) / row.filesProgress.total);
        return (
          <>
            <span style={{ marginRight: "10px" }}>{row.filesProgress.completed} / {row.filesProgress.total}</span>
            <Progress percent={percentCompleted} />
          </>
        )
      },
    },
  ];

  render() {
    const { progress } = this.props;
    const { speed, elapsed, eta } = this.state;
    const rows = Object.entries(progress).map(([key, value]) => {
      return {
        ...value,
        file_name: key,
        size: value.file.size,
        key: key,
      }
    })


    const totalUploaded = rows.reduce((a, b) => a + b.uploadedBytes, 0);
    const totalSize = rows.reduce((a, b) => a + b.size, 0);
    const percentCompleted = Math.round((totalUploaded * 100) / totalSize);

    const anyError = rows.some(item => item.error);
    const isAllCompleted = rows.every(item => item.completed);
    const completedFiles = rows.filter(item => item.completed).length;

    const uploadProgressInfo = {
      speed: filesize(speed) + "/s",
      elapsed: sec2time(elapsed),
      eta: sec2time(eta),
      uploaded: filesize(totalUploaded) + ' of ' + filesize(totalSize),
      overallProgress: { progress: percentCompleted, error: anyError, completed: isAllCompleted },
      filesProgress: { total: rows.length, completed: completedFiles },
      key: 0,
    };

    return (
      <>
        <Modal title="Preparing files for upload" visible={this.state.visible} closable={false} footer={null}>
          <p>Scanned files: {this.state.scannedFiles}</p>
          <p>Initialized files: {this.state.initializedFiles}</p>
          <Spin />
        </Modal>

        <div style={{ marginBottom: "10px" }}>
          <Button type="primary" onClick={this.handleClickUploadFile} style={{ marginRight: "10px" }}>Upload file(s)</Button>
          <Button type="primary" onClick={this.handleClickUploadDirectory}>Upload directory</Button>
        </div>

        <input type="file" multiple style={{ display: "none" }} ref={this.fileInput} onChange={this.handleChangeFileInput} />
        <input type="file" webkitdirectory="true" multiple style={{ display: "none" }} ref={this.directoryInput} onChange={this.handleChangeDirectoryInput} />

        <div id="drop_zone" style={{ padding: "20px", marginBottom: "10px" }} onDrop={this.dropHandler} onDragOver={this.dragOverHandler} className="ant-upload ant-upload-drag">
          <p className="ant-upload-drag-icon"><InboxOutlined /></p>
          <p className="ant-upload-text">Drag file or folder to this area to upload</p>
          <p className="ant-upload-hint">Support for a single or bulk upload.</p>
        </div>

        <Card title="Upload progress">
          <Table dataSource={[uploadProgressInfo]} columns={this.columnsProgress} size="small" pagination={{ hideOnSinglePage: true }} />
          <Table dataSource={rows} columns={this.columns} size="small" style={{ marginTop: '16px' }} />
        </Card>
      </>
    )
  }
}
