import React from "react";
import {Button, Form, Input, message, Modal, Popconfirm, Row, Select, Space, Table} from "antd";
import LambdaFunctionsService from "../../services/LambdaFunctionsService";
import ace from 'brace';
import { JsonEditor } from 'jsoneditor-react';
import JsonInput from "../FormComponents/JsonInput";
import {lambdaTypesMapping} from "../../constants";


class LambdaFunctionModal extends React.Component {
  componentDidMount() {
    this.form = React.createRef()
  }

  handleFinish = values => {
    this.props.onOk({...values, id: this.props.initialValues?.id});
  }

  render() {
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };

    const tailLayout = {
      wrapperCol: { offset: 8, span: 16 },
    };

    return (
        <Modal
            title="New lambda function"
            visible={this.props.isVisible}
            destroyOnClose={true}
            onOk={() => this.form.current.submit()}
            onCancel={this.props.onCancel}
            width="80vw"
            bodyStyle={{height: '65vh', overflow: "scroll"}}
        >
          <Form {...layout} ref={this.form} name="new-pipeline-step-form" onFinish={this.handleFinish} initialValues={this.props.initialValues}>
            <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Please input lambda function name' }, { min: 3 }]}
                wrapperCol={{span: 8}}
            >
              <Input />
            </Form.Item>
            <Form.Item
                label="Lambda url"
                name="lambda_url"
                rules={[{ required: true}]}
                wrapperCol={{span: 8}}
            >
              <Input />
            </Form.Item>
            <Form.Item
                label="Lambda type"
                name="lambda_type"
                rules={[{ required: true}]}
                wrapperCol={{span: 8}}
            >
              <Select>{
                ['page_processing_step', 'report_custom_post_processing'].map(
                    lambdaType => (<Select.Option value={lambdaType}>{lambdaTypesMapping[lambdaType]}</Select.Option>)
                )
              }</Select>
            </Form.Item>
            <Form.Item
                label="Params schema" name="params_schema" rules={[{ required: true, message: 'JSON must be valid'}]}
                wrapperCol={{span: 12}}
            >
              <JsonInput
                  options={{
                    mode: 'code',
                    ace: ace,
                    htmlElementProps: {style: {height: '40vh'}}
                  }}
              />
            </Form.Item>

            <Form.Item
                label="Params UI schema" name="params_ui_schema" rules={[{ required: true, message: 'JSON must be valid'}]}
                wrapperCol={{span: 12}}
            >
              <JsonInput
                  options={{
                    mode: 'code',
                    ace: ace,
                    htmlElementProps: {style: {height: '40vh'}}
                  }}
              />
            </Form.Item>
          </Form>
        </Modal>
    )
  }
}

export class LambdaFunctionsSettings extends React.Component {
  state = {
    lambdaFunctionsList: [],
    lambdaModalVisible: false,
  }

  loadLambdasList() {
    LambdaFunctionsService.fetchLambdaFunctions(this.props.match.params.projectId).then(result => {
      this.setState({lambdaFunctionsList: result.data});
    }).catch(() => {
      message.error('Failed to load lambda functions list');
    });
  }

  showModal = () => {
    this.setState({lambdaModalVisible: true});
  }

  hideModal = () => {
    this.setState({lambdaModalVisible: false});
  }

  handleAddLambdaFunction = () => {
    this.setState({
      currentLambdaFunction: {
        name: '',
        lambda_url: '',
        lambda_type: null,
        params_schema: {},
        params_ui_schema: {},
      }
    }, this.showModal);
  }

  handleSaveLambdaFunction = values => {
    const lambdaFunction = {
      name: values.name,
      lambda_url: values.lambda_url,
      lambda_type: values.lambda_type,
      params_schema_json: JSON.stringify(values.params_schema),
      params_ui_schema_json: JSON.stringify(values.params_ui_schema),
    }
    if (values.id) {
      lambdaFunction.id = values.id;
      LambdaFunctionsService.updateLambdaFunction(this.props.match.params.projectId, lambdaFunction).then(() => {
        message.success('Lambda function updated');
        this.loadLambdasList();
        this.hideModal();
      }).catch(() => {
        message.error('Failed to updated lambda function');
      });
    } else {
      LambdaFunctionsService.addLambdaFunction(this.props.match.params.projectId, lambdaFunction).then(() => {
        message.success('Lambda function added');
        this.loadLambdasList();
        this.hideModal();
      }).catch(() => {
        message.error('Failed to add lambda function');
      });
    }
  }

  handleDeleteLambdaFunction = (projectId, lambdaId) => {
    LambdaFunctionsService.deleteLambdaFunction(projectId, lambdaId).then(() => {
      message.success('Lambda function removed');
      this.loadLambdasList();
    }).catch(() => {
      message.error('Failed to remove lambda function');
    });
  }

  handleEditLambdaFunction = (projectId, lambdaId) => {
    LambdaFunctionsService.fetchLambdaFunctionById(projectId, lambdaId).then((res) => {
      this.setState({
        currentLambdaFunction: {
          id: res.data.id,
          name: res.data.name,
          lambda_url: res.data.lambda_url,
          lambda_type: res.data.lambda_type,
          params_schema: JSON.parse(res.data.params_schema_json),
          params_ui_schema: JSON.parse(res.data.params_ui_schema_json),
        }
      }, this.showModal);
    }).catch(() => {
      message.error('Failed to remove lambda function');
    });

  }

  componentDidMount() {
    this.loadLambdasList();
  }

  render() {
    const lambdasListColumns = [
      {
        title: 'Name',
        dataIndex: 'name',
      },
      {
        title: 'Type',
        dataIndex: 'lambda_type',
        render: lambdaType => {
          return lambdaTypesMapping[lambdaType];
        }
      },
      {
        title: 'Actions',
        dataIndex: 'id',
        render: (id) => {
          return (
              <Space>
                <a href="#" onClick={() => this.handleEditLambdaFunction(this.props.match.params.projectId, id)}>Edit</a>
                <Popconfirm title="Are you sure to delete the lambda function？"
                            onConfirm={() => this.handleDeleteLambdaFunction(this.props.match.params.projectId, id)}
                >
                  <a href="#">Delete</a>
                </Popconfirm>
              </Space>
          )
        }
      }
    ]
    return (
        <div>
          <Row style={{justifyContent: 'flex-end'}}>
            <Button type="primary" onClick={this.handleAddLambdaFunction}>Add lambda function</Button>
          </Row>
          <Table id="lambda-functions-list"
                 dataSource={this.state.lambdaFunctionsList}
                 columns={lambdasListColumns} size="small" style={{paddingTop: '16px'}} />
          <LambdaFunctionModal
              isVisible={this.state.lambdaModalVisible}
              onOk={lambda => this.handleSaveLambdaFunction(lambda)}
              onCancel={() => this.hideModal()}
              initialValues={this.state.currentLambdaFunction}
          />
        </div>
    )
  }
}
