import {useContext, useRef, useState} from "react";
import {CustomAttributesContext} from "./CustomAttributesContext";
import React from "react";
import {Button, Form, Input, Popover, Row, Space} from "antd";
import {ObjectDataContext} from "../ObjectDataContext";

export function CreateNewCustomAttribute({}) {
    const {
        createAttribute,
        usesCustomAttributes,
        notFound
    } = useContext(CustomAttributesContext);

    const {
        isEditable
    } = useContext(ObjectDataContext);

    const [popoverVisible, setPopoverVisible] = useState(false);
    const [newAttribute, setNewAttribute] = useState<{
        name: string;
        value: string;
    }>({
        name: "",
        value: ""
    });
    const newAttributeNameRef = useRef<Input>(null);

    const formLayout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };

    const newAttributePopoverContent = (
        <div style={{width: "188px"}}>
            <Form
                {...formLayout}
            >
                <Form.Item label="name" style={{marginBottom: "4px"}}>
                    <Input
                        id="new-attribute-name"
                        size="small"
                        ref={newAttributeNameRef}
                        value={newAttribute.name}
                        onChange={(e) => {
                            const newName = e.target.value;
                            setNewAttribute(prev => ({
                                ...prev,
                                name: newName
                            }));
                        }}
                    />
                </Form.Item>
                <Form.Item label="value" style={{marginBottom: "12px"}}>
                    <Input
                        id="new-attribute-value"
                        size="small"
                        value={newAttribute.value}
                        onChange={(e) => {
                            const newValue = e.target.value;
                            setNewAttribute(prev => ({
                                ...prev,
                                value: newValue
                            }));
                        }}
                    />
                </Form.Item>
                <Row>
                    <Space>
                        <Button
                            size="small"
                            style={{width: 90}}
                            onClick={() => setPopoverVisible(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            id="add-new-attribute-button"
                            type="primary"
                            size="small"
                            style={{width: 90}}
                            onClick={() => {
                                createAttribute(newAttribute.name, newAttribute.value);
                                setPopoverVisible(false);
                            }}
                        >
                            Add
                        </Button>
                    </Space>
                </Row>
            </Form>
        </div>
    );

    return !notFound && isEditable && usesCustomAttributes ? (
        <Popover
            content={newAttributePopoverContent}
            title="Add new attribute"
            trigger="click"
            visible={popoverVisible}
            onVisibleChange={visible => {
                setPopoverVisible(visible);
                setNewAttribute({
                    name: "",
                    value: ""
                });
                if (visible) {
                    setTimeout(() => newAttributeNameRef.current?.select(), 100);
                }
            }}
        >
            <Button size="small">+</Button>
        </Popover>
    ) : null;
}