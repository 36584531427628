import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Comment} from "./Comment";
import MarkupManager from "../../../ImageViewerHandlers/Hierarchy/Markup/MarkupManager";
import {Alert, Button, Checkbox, List, Row, Select, Space} from "antd";
import {AddNewComment} from "./AddNewComment";
import {fabric} from "fabric";
import {CreateCommentContainer} from "../Containers/CreateCommentContainer";
import {InMemoryCommentsContainer} from "../Containers/InMemoryCommentsContainer";
import {
    CommentMarkerSelection,
    FromMarkerTargetCommentSelection,
    NoneCommentsSelection,
    OnlyCommentSelection
} from "../Selection/CommentsSelection";
import {ApiSyncCommentsContainer, DrawingInfo} from "../Containers/ApiSyncCommentsContainer";
import {filter, map, tap, withLatestFrom} from "rxjs/operators";
import {viewerModes} from "../../../../constants";
import {ExternalCreateCommentContainer} from "../Containers/ExternalCreateCommentContainer";
import {ImageViewerObjectFromCanvas} from "../../../../Utilities";
import {NoneScroll, ScrollableToIndexList} from "../../../Misc/ScrollableToIndexList";
import {SelectionContainer} from "../Containers/SelectionContainer";
import {NewMarkers} from "../NewMarkers";
import {SavedCommentMarkersLayer, UnsavedCommentMarkersLayer} from "../FabricRender/MarkupLayer";
import {MarkerTargetAsAnyCanvasObj, MarkerTargetAsProperObj} from "../Selection/MarkerBelonging";
import {MarkupContainer} from "../Containers/MarkupContainer";
import {
    AllCommentsFilter, AndFilter,
    ClosedCommentsFilter, CommentsFilter,
    FilterContainer,
    OpenedCommentsFilter
} from "../Containers/FilterContainer";
import {OpenCommentsTabContainer} from "../Containers/OpenCommentsTabContainer";
import {CommentLinks} from "../CommentLinks";
import {SelectFromLinkContainer} from "../Containers/SelectFromLinkContainer";
import {AssignTargets} from "../AssignTargets";
import {AuthContext} from "../../../../contexts/AuthContext";


export function Comments({imageViewer, newCommentSubject, zoomToCommentMarker, resetSelectedObject}) {
    const [commentMarkerIcon, setCommentMarkerIcon] = useState(null);

    const [commentLinks] = useState(new CommentLinks(imageViewer));
    const [filterMode, setFilterMode] = useState('opened');

    const [assignTargets] = useState(new AssignTargets(imageViewer));
    const [newMarkers] = useState(new NewMarkers(imageViewer));
    const [commentsSelection, setCommentsSelection] = useState(new NoneCommentsSelection());

    const [scroll, setScroll] = useState(new NoneScroll());
    const [drawingInfo] = useState(new DrawingInfo(imageViewer));

    const [markerCreationForbidden, setMarkerCreationForbidden] = useState(true);

    const createdCommentLayer = useCallback(
        data => new UnsavedCommentMarkersLayer(data, commentMarkerIcon),
        [commentMarkerIcon]
    );

    const savedCommentsLayer = useCallback(
        data => new SavedCommentMarkersLayer(data, commentMarkerIcon),
        [commentMarkerIcon]
    );

    const [markup] = useState(
        new MarkupManager(
            [
                new SavedCommentMarkersLayer([], null),
                new UnsavedCommentMarkersLayer([], null)
            ],
            imageViewer
        )
    );

    useEffect(() => {
        fabric.Image.fromURL(
            process.env.PUBLIC_URL + '/images/comment.png',
            (img) => {
                setCommentMarkerIcon(() => {
                    return () => fabric.util.object.clone(img)
                });
            }
        );
    }, []);

    useEffect(() => {
        const subs = [
            imageViewer.mouseDown$.pipe(
                withLatestFrom(imageViewer.mode$),
                filter(([_, mode]) => mode === viewerModes.NORMAL),
                map(([opt, _]) => opt),

                tap(opt => {
                    const curPoint = imageViewer.canvas.getPointer(opt.e);

                    const clickTarget = new ClickTarget(imageViewer.canvas.getObjects(), curPoint);

                    if (clickTarget.containsMarker()) {
                        if (!clickTarget.containsProperObject()) {
                            resetSelectedObject();
                        }

                        const mdata = clickTarget.clickedMarker(0).markerMetadata;
                        setCommentsSelection(new CommentMarkerSelection(mdata.commentId, mdata.markerId, true));
                    }
                })
            ).subscribe(),
            imageViewer.objectSelected$.subscribe(e => {
                if (e.source === 'commentMarker' || e.source === 'reset') return;

                if (e.target) {
                    const target = new MarkerTargetAsProperObj(
                        new ImageViewerObjectFromCanvas(e.target)
                    );
                    setCommentsSelection(new FromMarkerTargetCommentSelection(target, true));
                } else {
                    setCommentsSelection(new NoneCommentsSelection());
                }
            }),
            imageViewer.mode$.subscribe(mode => {
                setMarkerCreationForbidden(mode !== viewerModes.NORMAL)
            })
        ];

        return () => {
            subs.forEach(sub => sub.unsubscribe());
        }
    }, [imageViewer, scroll, resetSelectedObject]);

    const handleCommentClick = comment => {
        setCommentsSelection(new OnlyCommentSelection(comment.id, false));

        if (comment.markers.length > 0) {
            zoomToCommentMarker(comment.markers[0]);
        } else {
            resetSelectedObject();
        }
    }

    const handleMarkerClick = (comment, marker) => {
        setCommentsSelection(new CommentMarkerSelection(comment.id, marker.id, false));
        zoomToCommentMarker(marker);
    }

    const handleCommentFromLinkSelect = useCallback(comment => {
        setFilterMode('all');
        setCommentsSelection(new OnlyCommentSelection(comment.id, true));

        if (comment.markers.length > 0) {
            zoomToCommentMarker(comment.markers[0]);
        } else {
            resetSelectedObject();
        }
    }, [zoomToCommentMarker, resetSelectedObject]);

    return commentMarkerIcon && (
        <div style={{
            width: '100%',
            height: '100%',
            overflowY: 'auto',
            padding: '0 0.5rem'
        }}>
            <SelectFromLinkContainer
                imageViewer={imageViewer}
                onSelect={handleCommentFromLinkSelect}
            >
                {selectFromLinkProps => (
                    <ApiSyncCommentsContainer drawingInfo={drawingInfo} onDataLoad={selectFromLinkProps.onDataLoad}>
                        {apiSyncProps => (
                            <InMemoryCommentsContainer
                                onAddComment={apiSyncProps.onAddComment}
                                onDeleteComment={apiSyncProps.onDeleteComment}
                                onEditComment={apiSyncProps.onEditComment}
                                dataSource={apiSyncProps.data}

                                markup={markup}
                                layerCtor={savedCommentsLayer}
                            >
                                {inMemoryCommentsProps => (
                                    <FilterContainer
                                        data={inMemoryCommentsProps.data}
                                    >
                                        {filteredProps => (
                                            <MarkupContainer
                                                data={filteredProps.data}
                                                markup={markup}
                                                layerCtor={savedCommentsLayer}
                                            >
                                                {() => (
                                                    <SelectionContainer
                                                        selection={commentsSelection}
                                                        data={filteredProps.data}
                                                        scroll={scroll}
                                                    >
                                                        {selectionProps => (
                                                            <OpenCommentsTabContainer
                                                                imageViewer={imageViewer}
                                                                data={filteredProps.data}
                                                                selection={selectionProps.commentsSelection}
                                                            >
                                                                {() => (
                                                                    <ExternalCreateCommentContainer
                                                                        subject={newCommentSubject}
                                                                        onAddComment={inMemoryCommentsProps.onAddComment}
                                                                    >
                                                                        {() => (
                                                                            <div style={{
                                                                                display: 'flex',
                                                                                flexDirection: 'column',
                                                                                height: '100%',
                                                                                minWidth: '280px'
                                                                            }}>
                                                                                <div style={{
                                                                                    maxHeight: '50%',
                                                                                    overflowY: 'auto',
                                                                                    marginBottom: '9px',
                                                                                    marginTop: '8px',
                                                                                }}>
                                                                                    <Header
                                                                                        onAddComment={inMemoryCommentsProps.onAddComment}
                                                                                        markup={markup}
                                                                                        newMarkers={newMarkers}
                                                                                        onFilterChange={filteredProps.onFilterChange}
                                                                                        createdCommentLayer={createdCommentLayer}
                                                                                        filterMode={filterMode}
                                                                                        assignTargets={assignTargets}

                                                                                        {...selectionProps}
                                                                                    />
                                                                                </div>
                                                                                <div style={{
                                                                                    flex: 1,
                                                                                    overflowY: 'auto'
                                                                                }}>
                                                                                    <CommentsList
                                                                                        assignTargets={assignTargets}

                                                                                        data={filteredProps.data}
                                                                                        commentLinks={commentLinks}

                                                                                        commentsSelection={selectionProps.commentsSelection}
                                                                                        newMarkers={newMarkers}
                                                                                        markerCreationForbidden={markerCreationForbidden}

                                                                                        onDeleteComment={inMemoryCommentsProps.onDeleteComment}
                                                                                        onEditCommentCancel={inMemoryCommentsProps.onEditCommentCancel}
                                                                                        onEditCommentUpdate={inMemoryCommentsProps.onEditCommentUpdate}
                                                                                        onEditCommentApprove={inMemoryCommentsProps.onEditCommentApprove}

                                                                                        onMarkerClick={handleMarkerClick}
                                                                                        onCommentClick={handleCommentClick}

                                                                                        onScrollInit={setScroll}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </ExternalCreateCommentContainer>
                                                                )}
                                                            </OpenCommentsTabContainer>
                                                        )}
                                                    </SelectionContainer>
                                                )}
                                            </MarkupContainer>
                                        )}
                                    </FilterContainer>
                                )}
                            </InMemoryCommentsContainer>
                        )}
                    </ApiSyncCommentsContainer>
                )}
            </SelectFromLinkContainer>
        </div>
    );
}


function AddNewCommentWrap(
    {markup, onAddComment, layerCtor, newMarkers, markerCreationForbidden, children, assignTargets}
) {
    return (
        <CreateCommentContainer
            markup={markup}
            onAddComment={onAddComment}
            layerCtor={layerCtor}
        >
            {createCommentProps => (
                <AddNewComment
                    newMarkers={newMarkers}
                    markerCreationForbidden={markerCreationForbidden}
                    assignTargets={assignTargets}
                    {...createCommentProps}
                >
                    {children}
                </AddNewComment>
            )}
        </CreateCommentContainer>
    );
}


function MultipleSelection({currentSelectionIndex, totalSelectionTargets, onSelectTargetIndexChange}) {
    const nextIndex = ((currentSelectionIndex + 1) % totalSelectionTargets);

    const prevIndex = currentSelectionIndex - 1 >= 0 ? currentSelectionIndex - 1 : totalSelectionTargets - currentSelectionIndex - 1;

    if (totalSelectionTargets > 1) {
        return (
            <Alert
                type={"warning"}
                message={(
                    <Space direction={'vertical'}>
                        <Space>
                            <div>One more suitable comment</div>
                            <div>({currentSelectionIndex + 1}/{totalSelectionTargets})</div>
                        </Space>
                        <Space>
                            <a onClick={e => onSelectTargetIndexChange(prevIndex)}>prev</a>
                            <a onClick={e => onSelectTargetIndexChange(nextIndex)}>next</a>
                        </Space>
                    </Space>
                )}
            />
        );
    } else {
        return null;
    }
}


export function CommentsList(
    {data, newMarkers, markerCreationForbidden, onDeleteComment, onEditCommentApprove, onEditCommentCancel,
        onEditCommentUpdate, commentsSelection, onCommentClick, onMarkerClick, onScrollInit, commentLinks,
        assignTargets}
) {

    return (
        <ScrollableToIndexList
            data={data}
            onScrollInit={onScrollInit}
        >
            {scrollProps => (
                <List
                    dataSource={scrollProps.data}
                    split={false}
                    itemLayout={"vertical"}
                    renderItem={(comment, idx) => {
                        return (
                            <List.Item key={comment.id}>
                                <div ref={el => scrollProps.scrollRefs.current[idx] = el}>
                                    <Comment
                                        idx={idx}

                                        assignTargets={assignTargets}

                                        commentLinks={commentLinks}
                                        commentsSelection={commentsSelection}
                                        comment={comment}
                                        newMarkers={newMarkers}
                                        markerCreationForbidden={markerCreationForbidden}
                                        onDelete={_ => onDeleteComment(comment)}

                                        onEditApprove={onEditCommentApprove}
                                        onEditCancel={onEditCommentCancel}
                                        onEditUpdate={onEditCommentUpdate}

                                        onMarkerClick={marker => onMarkerClick(comment, marker)}
                                        onClick={e => onCommentClick(comment)}
                                    />
                                </div>
                            </List.Item>
                        );
                    }}
                />
            )}
        </ScrollableToIndexList>
    );
}


class FilterByMode {
    constructor(mode, filter) {
        this._filter = filter;
        this._mode = mode;
    }

    find(mode, callback) {
        if (mode === this._mode) {
            callback(this._filter);
        }
    }
}


class ComposeFilterByMode {
    constructor(origins) {
        this._origins = origins;
    }

    find(mode, callback) {
        this._origins.forEach(o => o.find(mode, callback));
    }
}


class ForYouFilter extends CommentsFilter {
    constructor(enabled, targetId) {
        super();
        this._enabled = enabled;
        this._targetId = targetId;
    }

    test(comment) {
        if (!this._enabled) return true;
        return comment.assignTargets.map(t => t.userId).includes(this._targetId);
    }
}


export function Filters({initialMode='opened', initialForYou=false, onFilterChange}) {
    const authContext = useContext(AuthContext);
    const [currentUser] = useState(authContext.user);

    const [mode, setMode] = useState(initialMode);
    const [forYou, setForYou] = useState(initialForYou);

    const filterByMode = useMemo(() => {
        const forYouFilter = new ForYouFilter(forYou, currentUser.id);

        return new ComposeFilterByMode([
            new FilterByMode(
                'all',
                new AndFilter(
                    forYouFilter,
                    new AllCommentsFilter()
                )
            ),
            new FilterByMode(
                'closed',
                new AndFilter(
                    forYouFilter,
                    new ClosedCommentsFilter()
                )
            ),
            new FilterByMode(
                'opened',
                new AndFilter(
                    forYouFilter,
                    new OpenedCommentsFilter()
                )
            )
        ]);
    }, [currentUser, forYou]);

    useEffect(() => {
        setMode(initialMode);
    }, [initialMode]);

    useEffect(() => {
        filterByMode.find(mode, onFilterChange);
    }, [mode, filterByMode, onFilterChange]);

    useEffect(() => {
        filterByMode.find(mode, onFilterChange)
    }, []);

    return (
        <Space>
            <Checkbox
                id={'for-you-checkbox'}
                checked={forYou}
                onChange={e => setForYou(e.target.checked)}
            >
                For you
            </Checkbox>
            <Space>
                <div>Show</div>
                <Select
                    id={'comments-show-mode'}
                    value={mode}
                    onChange={setMode}
                    size={"small"}
                    style={{minWidth: '86px'}}
                >
                    <Select.Option value={"all"}>All</Select.Option>
                    <Select.Option value={"closed"}>Closed</Select.Option>
                    <Select.Option value={"opened"}>Opened</Select.Option>
                </Select>
            </Space>
        </Space>
    );
}


function Header(
    {markup, onAddComment, createdCommentLayer, newMarkers,
        onFilterChange, currentSelectionIndex, totalSelectionTargets, onSelectTargetIndexChange,
        filterMode, assignTargets}
) {
    return (
        <AddNewCommentWrap
            markup={markup}
            onAddComment={onAddComment}
            layerCtor={createdCommentLayer}
            newMarkers={newMarkers}
            assignTargets={assignTargets}
        >
            {addNewCommentProps => (
                <div>
                    <Row justify={"space-between"}>
                        <Button
                            id={'add-new-comment'}
                            size={"small"}
                            type={"primary"}
                            onClick={addNewCommentProps.onEditStart}
                        >
                            Add new
                        </Button>
                        <Filters
                            initialMode={filterMode}
                            onFilterChange={onFilterChange}
                        />
                    </Row>
                    <div style={{marginTop: '8px'}}>
                        <MultipleSelection
                            currentSelectionIndex={currentSelectionIndex}
                            totalSelectionTargets={totalSelectionTargets}
                            onSelectTargetIndexChange={onSelectTargetIndexChange}
                        />
                    </div>
                </div>
            )}
        </AddNewCommentWrap>
    );
}


class ClickTarget {
    constructor(possibleTargets, clickPosition) {
        this._possibleTargets = possibleTargets;
        this._clickPosition = clickPosition;
    }

    containsMarker() {
        return this._possibleTargets
            .filter(obj => obj.isCommentMarker)
            .find(obj => new MarkerTargetAsAnyCanvasObj(obj).containsMarker({position: {...this._clickPosition}}));
    }

    clickedMarker(idx) {
        const markers = this._possibleTargets
            .filter(obj => obj.isCommentMarker)
            .filter(obj => new MarkerTargetAsAnyCanvasObj(obj).containsMarker({position: {...this._clickPosition}}));

        return markers[idx];
    }

    containsProperObject() {
        return this._possibleTargets
            .filter(obj => obj.isProperObject)
            .find(obj => new MarkerTargetAsAnyCanvasObj(obj).containsMarker({position: {...this._clickPosition}}));
    }
}