import {DataItemFilter} from "../../../../../../Misc/DataSourceFilter";
import {AnimatedCnDetails} from "../../../../Model/AnimatedCnDetails/AnimatedCnDetails";

export class CnDetailsMatchFilter extends DataItemFilter {
    constructor(value, key, alias) {
        super(value, el => el.details(), alias);

        this._key = key;
    }

    _matchLogic(itemValue) {
        const details = new AnimatedCnDetails(itemValue);

        if (!details.keys().includes(this._key)) return false;

        return details.hasValueByKey(this._key, this.value);
    }
}