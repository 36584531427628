import React from 'react'
import {Card, Form, Input, Button, Layout, Row, Col, Select} from 'antd'
import { Link } from 'react-router-dom';
import axios from "axios";
import {API_URL} from "../constants";
import {authHeader} from "../Utilities";



export class NewProject extends React.Component {
  state = {
    selectedWorkspace: null,
    workspacesList: [],
  }

  loadWorkspaces = () => {
    axios.get(
        API_URL + `/workspaces`,
        {headers: authHeader()}
    ).then(result => {
      const workspacesList = result.data;
      this.setState({
        workspacesList: workspacesList,
      });
    });
  }

  componentDidMount() {
    this.loadWorkspaces();
  }

  render() {
    let projectName
    let projectDescription
    return (
      <>
        <Row style={{justifyContent: 'center'}}>
          <Card title="Create new project" style={{width: '50%'}}>
            <Form
              labelCol={{span: 8}}
              wrapperCol={{span: 16}}
            >
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Please input the project name' }]}
              >
                <Input ref={node => (projectName = node)}/>
              </Form.Item>

              <Form.Item
                label="Description"
                name="description"
                rules={[{ required: true, message: 'Please input the project description' }]}
              >
                <Input ref={node => (projectDescription = node)} />
              </Form.Item>

              <Form.Item label="Workspace" name="workspace" rules={[{required: true}]}>
                <Select
                    id="workspaceSelect"
                    value={this.state.selectedWorkspace}
                    onChange={(value) => this.setState({selectedWorkspace: value})}
                >
                  {this.state.workspacesList.map((workspace) => {
                    return (
                        <Select.Option value={workspace.id}>{workspace.name}</Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>

              <Row style={{justifyContent: 'space-between'}}>
                <Button><Link to="/projects">Cancel</Link></Button>
                <Button
                  type="primary"
                  onClick={(e) => 
                    this.props.createProject({
                      name: projectName.props.value,
                      description: projectDescription.props.value,
                      workspace_id: this.state.selectedWorkspace
                    })
                  }
                >
                  Create
                </Button>
              </Row>
            </Form>
          </Card>
        </Row>
      </>
    )
  }
}