import React from 'react'
import { Link } from 'react-router-dom'
import { API_URL } from '../constants'
import axios from 'axios'
import { push } from 'connected-react-router'
import { ofType } from 'redux-observable'
import { map, mergeMap, catchError } from 'rxjs/operators'
import { from, of } from 'rxjs'
import {authHeader} from "../Utilities";

// export const REQUEST_PROJECTS = 'REQUEST_PROJECTS'
// function requestProjects() {
//   return {
//     type: REQUEST_PROJECTS
//   }
// }

// export const RECEIVE_PROJECTS = 'RECEIVE_PROJECTS'
// function receiveProjects(json) {
//   return {
//     type: RECEIVE_PROJECTS,
//     projects: json.data.projects.map((project) => {
//       {
//         return Object.assign({}, project, {
//           'link': (<Link to={'/project/' + project.id}>Open</Link>)
//         })
//       }
//     })
//   }
// }

// export function fetchProjects() {
//   return function (dispatch) {
//     dispatch(requestProjects())
//     return axios.get(
//       API_URL + '/projects'
//     ).then(response => {
//       return dispatch(receiveProjects(response))
//     })
//   }
// }


// export const CREATE_NEW_PROJECT = 'CREATE_NEW_PROJECT'
export function createNewProject(project) {
  return function (dispatch) {
    return axios.post(
      API_URL + '/projects',
      project,
      {headers: authHeader()}
    ).then(response => {
      return dispatch(push('/projects'))
    });
  }
}

export const REQUEST_DOCUMENTS_LIST = 'REQUEST_DOCUMENTS_LIST'
function requestDocumentsList() {
  return {
    type: REQUEST_DOCUMENTS_LIST
  }
}

export const RECEIVE_DOCUMENTS_LIST = 'RECEIVE_DOCUMENTS_LIST'
function receiveDocumentsList(json) {
  return {
    type: RECEIVE_DOCUMENTS_LIST,
    documents: json.data.documents.map((document) => {
        return {
          'id': document.id,
          'file_name': document.file_path,
          'size': document.file_size,
          'key': document.id
        }
        // Object.assign({}, document, {
        //   'link': (<Link to={'/project/' + project.id}>Open</Link>)
        // })
    })
  }
}


export const REQUEST_STATISTICS = 'REQUEST_STATISTICS'
function requestStatistics() {
  return {
    type: REQUEST_STATISTICS
  }
}

export const RECEIVE_STATISTICS = 'RECEIVE_STATISTICS'
function receiveStatistics(json) {
  return {
    type: RECEIVE_STATISTICS,
    summary: json.data.summary,
    fileTypesStatistics: json.data.file_types_statistics.map(statItem => ({ ...statItem, key: statItem.file_type }))
  }
}

export function fetchStatistics(projectId) {
  return function (dispatch) {
    dispatch(requestStatistics)
    return axios.get(
      API_URL + `/projects/${projectId}/statistics`,
      {headers: authHeader()}
    ).then(response => {
      return dispatch(receiveStatistics(response))
    })
  }
}


export const REQUEST_PROJECTS = 'REQUEST_PROJECTS'
export const RECEIVE_PROJECTS = 'RECEIVE_PROJECTS'
export const fetchProjects = () => ({ type: REQUEST_PROJECTS })
const receiveProjects = payload => {
  return ({
    type: RECEIVE_PROJECTS,
    projects: payload.data.projects.map(
      (project) => {
          return {
            ...project,
            'link': (<Link to={'/project/' + project.id}>Open</Link>),
            'key': project.id
          }
      }
    )
  })
}

export const fetchProjectsEpic = action$ => action$.pipe(
  ofType(REQUEST_PROJECTS),
  mergeMap(action =>
    from(axios.get(API_URL + '/projects', {headers: authHeader()})).pipe(map(
      response => {
        return receiveProjects(response)
      }
    ))
  )
)

export const SCHEDULE_DOCUMENTS_PROCESSING = 'SCHEDULE_DOCUMENTS_PROCESSING'
export const DOCUMENTS_PROCESSING_SCHEDULED = 'DOCUMENTS_PROCESSING_SCHEDULED'
export const scheduleDocumentsProcessing = (projectId, extractFlag) => ({
  type: SCHEDULE_DOCUMENTS_PROCESSING, projectId: projectId, extractFlag: extractFlag
})
const documentsProcessingScheduled = (payload, projectId) => ({
  type: DOCUMENTS_PROCESSING_SCHEDULED,
  payload: payload,
  projectId: projectId
})

export const scheduleDocumentsProcessingEpic = action$ => action$.pipe(
  ofType(SCHEDULE_DOCUMENTS_PROCESSING),
  mergeMap(action =>
    from(axios.post(API_URL + `/projects/${action.projectId}/process_documents`, {extractFlag: `${action.extractFlag}`}, {headers: authHeader()})).pipe(map(
      response => {
        return documentsProcessingScheduled(response, action.projectId)
      }
    ))
  )
)


export const REQUEST_RUNS = 'REQUEST_RUNS'
export const RECEIVE_RUNS = 'RECEIVE_RUNS'
export const fetchRuns = (projectId) => ({ type: REQUEST_RUNS, projectId: projectId })
const receiveRuns = payload => {
  return ({
    type: RECEIVE_RUNS,
    payload: payload
  })
}

export const fetchRunsEpic = action$ => action$.pipe(
  ofType(REQUEST_RUNS),
  mergeMap(action =>
    from(axios.get(API_URL + `/projects/${action.projectId}/processing_runs`, {headers: authHeader()})).pipe(map(
      response => {
        return receiveRuns(response)
      }
    ))
  )
)

export const refreshRunsListEpic = action$ => action$.pipe(
  ofType(DOCUMENTS_PROCESSING_SCHEDULED),
  map(action => fetchRuns(action.projectId))
)


export const REQUEST_RUN_RESULTS = 'REQUEST_RUN_RESULTS'
export const RECEIVE_RUN_RESULTS = 'RECEIVE_RUN_RESULTS'
export const fetchRunResults = (projectId, runId, search, page, limit) => ({
  type: REQUEST_RUN_RESULTS,
  projectId,
  runId,
  search,
  page,
  limit
})

export const receiveRunResults = payload => {
  return ({
    type: RECEIVE_RUN_RESULTS,
    payload: payload
  })
}

export const fetchRunResultsEpic = action$ => action$.pipe(
  ofType(REQUEST_RUN_RESULTS),
  mergeMap(action => {
    const params = {
      search: action.search,
      page: action.page,
      limit: action.limit
    }
    return from(axios.get(API_URL + `/projects/${action.projectId}/processing_runs/${action.runId}/results`, {
      params,
      timeout: 30 * 1000,
      headers: authHeader()
    })).pipe(
      map(response => receiveRunResults(response)),
      catchError(error => of({
        type: RECEIVE_RUN_RESULTS,
        payload: { data: {} },
        //error.xhr.response
        error: true
      }))
    )
  })
)


export const REQUEST_DOCUMENT_ANNOTATION = 'REQUEST_DOCUMENT_ANNOTATION'
export const RECEIVE_DOCUMENT_ANNOTATION = 'RECEIVE_DOCUMENT_ANNOTATION'
export const fetchDocumentAnnotation = (projectId, runId, documentId) => ({
  type: REQUEST_DOCUMENT_ANNOTATION,
  projectId: projectId,
  runId: runId,
  documentId: documentId
})

export const receiveDocumentAnnotation = payload => {
  return ({
    type: RECEIVE_DOCUMENT_ANNOTATION,
    payload: payload
  })
}

export const fetchDocumentAnnotationEpic = action$ => action$.pipe(
  ofType(REQUEST_DOCUMENT_ANNOTATION),
  mergeMap(action =>
    from(axios.get(
          API_URL + `/projects/${action.projectId}/processing_runs/${action.runId}/results/${action.documentId}`,
          {headers: authHeader()}
        )).pipe(map(
      response => {
        return receiveDocumentAnnotation(response)
      }
    ))
  )
)
