import {MgConflicts} from "../MgConflicts";
import {
    AddedMgConflict,
    DynamicMgConflictDecorator, ModifiedMgConflict, MovedMgConflict,
    RemovedMgConflict,
    ResolvedMgConflict, SameMgConflict
} from "../MgConflict";
import {Space, Tooltip} from "antd";
import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import {DefaultMgTableRow} from "../DefaultMgTableRow";
import {TagCnStatus} from "../../../DrawingComparison/Model/Tag/TagCnStatus";
import React from "react";
import {CnResultDetails} from "../../../DrawingComparison/Component/Summary/CnResultDetails";
import {AcceptButton, RejectButton} from "../../../DrawingComparison/Component/MgResolveButtons";

export class RemoteMgConflicts extends MgConflicts {
    constructor(data, onMgConflictSelect, createNewObject,
                selectCanvasObjectById, selectOtherObjectById, canvas,
                deleteObject, renderObjectData, replaceObject) {
        super();

        this._data = data;
        this._selectCanvasObjectById = selectCanvasObjectById;
        this._selectOtherObjectById = selectOtherObjectById;
        this._canvas = canvas;
        this._onMgConflictSelect = onMgConflictSelect;
        this._createNewObject = createNewObject;
        this._deleteObject = deleteObject;
        this._renderObjectData = renderObjectData;
        this._replaceObject = replaceObject;
    }

    values() {
        return this._data.map((animatedConflictData, idx) => {
            return new DynamicMgConflictDecorator(
                () => {
                    let origin;

                    const tableRow = new DefaultMgTableRow(
                        animatedConflictData.identity().text,
                        animatedConflictData.identity().label,
                        new TagCnStatus(animatedConflictData.cnStatus()),
                        animatedConflictData.mgStatus(),
                        (
                            <Space key={idx}>
                                <Tooltip title={"Accept"}>
                                    <CheckOutlined style={{color: "#52c41a"}} onClick={() => {
                                        origin.accept();
                                    }}/>
                                </Tooltip>
                                <Tooltip title={"Reject"}>
                                    <CloseOutlined style={{color: "#f5222d"}} onClick={() => {
                                        origin.reject();
                                    }}/>
                                </Tooltip>
                            </Space>
                        )
                    );

                    const detailsTag = (
                        <CnResultDetails
                            detailsTarget={{details: () => animatedConflictData.cnDetails()}}
                            colorMap={{
                                MODIFIED: "#e6f7ff",
                                REMOVED: "#f6ffed",
                                ADDED: "#fff1f0",
                                SAME: ''
                            }}
                        />
                    );

                    if (animatedConflictData.cnStatus() === 'ADDED') {
                        origin = new AddedMgConflict(
                            animatedConflictData,
                            this._createNewObject,
                            tableRow,
                            detailsTag,
                            this._canvas,
                            this._selectOtherObjectById,
                            this._selectCanvasObjectById
                        );
                    } else if (animatedConflictData.cnStatus() === 'REMOVED') {
                        origin = new RemovedMgConflict(
                            animatedConflictData,
                            this._deleteObject,
                            tableRow,
                            detailsTag,
                            this._canvas,
                            this._selectCanvasObjectById,
                            this._selectOtherObjectById
                        );
                    } else if (animatedConflictData.cnStatus() === 'MOVED') {
                        origin = new MovedMgConflict(
                            animatedConflictData,
                            this._replaceObject,
                            tableRow,
                            detailsTag,
                            this._canvas,
                            this._selectCanvasObjectById
                        );
                    } else if (animatedConflictData.cnStatus() === 'MODIFIED') {
                        origin = new ModifiedMgConflict(
                            animatedConflictData,
                            this._replaceObject,
                            tableRow,
                            detailsTag,
                            this._canvas,
                            this._selectCanvasObjectById
                        );
                    } else if (animatedConflictData.cnStatus() === 'SAME') {
                        origin = new SameMgConflict(
                            animatedConflictData,
                            this._canvas
                        );
                    }

                    if (animatedConflictData.mgStatus() !== 'UNRESOLVED') {
                        origin = new ResolvedMgConflict(
                            origin,
                            animatedConflictData,
                            this._onMgConflictSelect,
                            this._selectCanvasObjectById,
                            this._canvas,
                            this._renderObjectData
                        );
                    }

                    return origin;
                }
            );
        });
    }

    loaded() {
        return true;
    }
}