import {Button} from "antd";
import React from "react";

export function AcceptButton({onClick, children, ...rest}) {
    return (
        <Button size={"small"} style={{ color: "#52c41a", borderColor: "#b7eb8f" }} onClick={onClick} {...rest}>
            {children}
        </Button>
    );
}

export function RejectButton({onClick, children, ...rest}) {
    return (
        <Button size={"small"} danger onClick={onClick} {...rest}>
            {children}
        </Button>
    );
}