import React, {useEffect, useState} from "react";
import {AutoComplete, Input, Row} from "antd";
import {EditOutlined, WarningOutlined} from "@ant-design/icons";
import { Tooltip } from "antd";

export function EditableAutoCompleteField({value, editIconShown, onUpdate, optionsList, autoCompleteProps}) {
    const [isEditing, setEditing] = useState(false);
    const [isWarning, setWarning] = useState(false);
    const [inputValue, setInputValue] = useState(value);
    const [displayedOptions, setDisplayedOptions] = useState(optionsList)

    useEffect(() => {
        setWarning(!displayedOptions.includes(value) && displayedOptions.length > 0);
    }, [value]);    

    const renderAsTextField = () => {
        return (
            <Row style={{justifyContent: 'space-between'}}>
                <span>{value}</span>
                <div>
                    {editIconShown &&
                    <a onClick={() => setEditing(true)} style={{ marginRight: '8px' }}>
                        <EditOutlined/>
                    </a>
                    }
                    {isWarning &&         
                        <Tooltip title="Input doesn't match any available options">
                            <WarningOutlined style={{ color: 'red', fontSize: '16px' }} />
                        </Tooltip>
                    }
                </div>
            </Row>
        )
    }

    const renderAsAutoCompleteField = () => {
        return (
            <Row style={{flexFlow: 'initial', width: 'auto', margin: '-15px'}} className="editable-select-field-cell">
                <AutoComplete
                    id="attribute-edit-text-input"
                    {...autoCompleteProps}
                    autoFocus={true}
                    defaultOpen={isEditing}
                    autoClearSearchValue={false}
                    defaultValue={value}
                    className="editable-select-field"
                    onChange={newValue => {
                        setInputValue(newValue);
                    }}
                    onSelect={selected => {
                        onUpdate(selected);
                        setEditing(false);
                    }}
                    onSearch={text => setDisplayedOptions([...optionsList].sort((a, b) => {
                      return b.toLowerCase().includes(text.toLowerCase()) - a.toLowerCase().includes(text.toLowerCase());
                    }))}
                    options={displayedOptions.map(option => ({value: option}))}
                    onKeyDown={e => {
                        if (e.nativeEvent.key === 'Escape') {
                            setEditing(false);
                        }
                    }}
                    onBlur={_ => setEditing(false)}
                >
                    <Input
                        onPressEnter={_ => {
                            onUpdate(inputValue);
                            setEditing(false);
                        }}
                    />
                </AutoComplete>
            </Row>
        )
    }

    return(
        <>
            {isEditing? renderAsAutoCompleteField() : renderAsTextField()}
        </>
    )
}
