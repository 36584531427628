import {CnObject} from "../CnObject";
import TagUrlLinkService from "../../../../../services/TagUrlLinkService";
import React from "react";
import {ObjectTextLabel} from "../../../../Misc/ObjectTextLabel";

export class AnnotationCnObject extends CnObject {
    constructor(annotationObj, selectObjectById, attrs, cnCanvasObject) {
        super();

        this._data = annotationObj;
        this._selectObjectById = selectObjectById;
        this._attrs = attrs;
        this._cnCanvasObject = cnCanvasObject;
    }

    toTag() {
        return (
            <ObjectTextLabel text={this._data.text} label={this._data.label}/>
        );
    }

    toCnCanvasObject() {
        return this._cnCanvasObject;
    }

    toTagHash() {
        return TagUrlLinkService.getTagHash(this._data.text, this._data.id.toString());
    }

    selectOnCanvas() {
        this._selectObjectById(this._data.id);
    }

    relatedToCanvasObject(canvasObj) {
        if (!canvasObj) return false;

        return canvasObj.objectMetadata.id === this._data.id;
    }

    attrs() {
        return this._attrs;
    }
}