import {Details} from "./Details";
import {SafeAttributes} from "./Safe/SafeAttributes";

export class DetailsFromAttributes extends Details {
    constructor(a, b) {
        super();

        this._a = a;
        this._b = b;
    }

    value() {
        const curAttrs = new SafeAttributes(this._a);
        const anotherAttrs = new SafeAttributes(this._b);

        const allKeys = [...new Set(curAttrs.keys().concat(anotherAttrs.keys()))];

        const result = allKeys.map(key => ({
            key: key,
            current: {
                value: curAttrs.value(key),
                absent: !curAttrs.keys().includes(key)
            },
            another: {
                value: anotherAttrs.value(key),
                absent: !anotherAttrs.keys().includes(key)
            }
        }));

        return result;
    }
}