export class CnCanvasObject {
    select() {

    }

    deselect() {

    }

    addToCanvas() {

    }

    removeFromCanvas() {

    }

    addHighlight() {

    }

    removeHighlight() {

    }

    show() {

    }

    hide() {

    }
}