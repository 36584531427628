import { API_URL } from '../constants'
import axios from 'axios'
import {authHeader} from "../Utilities";

export const START_LOADING_FOLDER_CONTENT = 'START_LOADING_FOLDER_CONTENT'
function _startLoadingFolderContent(projectId, folder) {
  return {
    type: START_LOADING_FOLDER_CONTENT,
    projectId,
    folder,
  }
}


export const LOADED_FOLDER_CONTENT = 'FOLDER_CONTENT_LOADED'
function _loadFolderContent(projectId, folder, search, page, pageSize, sortField, sortOrder, dirs, files, total, error) {
  return {
    type: LOADED_FOLDER_CONTENT,
    projectId: projectId,
    folder,
    search,
    page,
    pageSize,
    sortField,
    sortOrder,
    dirs,
    files,
    total,
    error,
  }
}

export function loadFolderContent(projectId, folder, search, page, pageSize, sortField, sortOrder) {
  return dispatch => {
    dispatch(_startLoadingFolderContent(projectId, folder));

    return _getFolderContent(projectId, folder, search, page, pageSize, sortField, sortOrder)
      .then(result => {
        const { dirs, files, total } = result;
        dispatch(_loadFolderContent(projectId, folder, search, page, pageSize, sortField, sortOrder, dirs, files, total, null));
      })
      .catch(err => {
        dispatch(_loadFolderContent(projectId, folder, search, page, pageSize, sortField, sortOrder, [], [], 0, err));
      })
  }
}

function _getFolderContent(projectId, folder, search, page, size, sortField, sortOrder) {
  const params = {
    folder,
    search,
    page,
    size,
    sortField,
    sortOrder
  };

  const url = API_URL + `/projects/${projectId}/folder/content`;
  return axios.get(url, { params, headers: authHeader() }).then(res => res.data);
}

