import React from 'react';
import { Table, Progress, Select, Card, Space } from 'antd';
import filesize from 'filesize';
import { sec2time } from '../../Utilities';
import { API_URL } from '../../constants';
import axios from 'axios';
import { authHeader } from "../../Utilities";
import { S3FilesNavigationContainer } from "../../containers/S3FilesNavigationContainer";

const { Option } = Select;

export class UploadS3 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      buckets: [],
      bucketsProgress: {},
    };

    this.getBucketsList().then(buckets => {
      this.setState({
        buckets,
      });
    });
  }

  getBucketsList() {
    const params = {
      aws_access_key_id: this.props.awsAccessKeyId,
      aws_secret_access_key: this.props.awsSecretAccessKey,
    };

    return axios.get(`${API_URL}/s3/buckets`, { params, headers: authHeader() })
      .then(response => {
        return response.data.buckets;
      });
  }

  handleSelectedBucket = (value) => {
    this.setState({
      selected: value,
    });
  };

  syncBucket = (bucket, folder) => {
    const projectId = this.props.match.params.projectId;
    const params = {
      bucket,
      folder,
      aws_access_key_id: this.props.awsAccessKeyId,
      aws_secret_access_key: this.props.awsSecretAccessKey,
    };

    const updateBucketState = (mergeState) => {
      const progressKey = `${bucket} (${folder})`;
      this.setState((state) => {
        const bucketsProgress = { ...state.bucketsProgress };
        bucketsProgress[progressKey] = {
          ...bucketsProgress[progressKey],
          ...mergeState,
        };

        return {
          ...state,
          bucketsProgress,
        };
      });
    };

    updateBucketState({
      progress: {},
      startDate: new Date(),
      endDate: undefined,
      error: undefined,
    });

    axios.get(`${API_URL}/projects/${projectId}/s3/sync_bucket`, { params, headers: authHeader() })
      .then(response => {
        const taskId = response.data.task_id;

        const getProgress = () => {
          axios.get(`${API_URL}/s3/sync_bucket/progress/${taskId}`, { headers: authHeader() })
            .then(response => {
              const progress = response.data;

              if (progress == null || (!progress.error && progress.completed !== true)) {
                updateBucketState({
                  progress,
                });
                setTimeout(() => getProgress(), 1000);
              } else {
                updateBucketState({
                  progress,
                  endDate: new Date(),
                });
              }
            })
            .catch(error => {
              updateBucketState({
                endDate: new Date(),
                error,
              });
            });
        };

        getProgress();
      })
      .catch(error => {
        updateBucketState({
          endDate: new Date(),
          error,
        });
      });
  };

  getProgressBar = (row) => {
    const { progress, error, completed } = row;

    if (error)
      return <Progress percent={progress} status="exception" />;

    if (completed)
      return <Progress percent={progress} />;

    return <Progress percent={progress} status="active" />;
  };

  columnsProgress = [
    {
      title: 'Bucket',
      dataIndex: 'bucket',
    },
    {
      title: 'Elapsed',
      dataIndex: 'elapsed',
      width: '5%',
    },
    {
      title: 'Current file',
      dataIndex: 'currentFile',
      width: '50%',
    },
    {
      title: 'Downloaded size',
      dataIndex: 'downloaded',
      width: '10%',
      render: (text, row) => (
        <>
          <span style={{ marginRight: "10px" }}>{text}</span>
          {this.getProgressBar(row.overallProgress)}
        </>
      ),
    },
    {
      title: 'Files',
      dataIndex: 'filesProgress',
      render: (text, row) => {
        const percentCompleted = Math.round((row.filesProgress.completed * 100) / row.filesProgress.total);
        return (
          <>
            <span style={{ marginRight: "10px" }}>{row.filesProgress.completed} / {row.filesProgress.total}</span>
            <Progress percent={percentCompleted} />
          </>
        );
      },
    },
    {
      title: 'Error',
      dataIndex: 'error',
    },
  ];

  render() {
    const { buckets, selected, bucketsProgress } = this.state;

    const getBucketTableData = (bucket, bucketState) => {
      const { startDate, endDate, error: requestError } = bucketState;

      let {
        completed,
        total_files: totalFiles,
        total_size: totalSize,
        total_transferred_files: totalTransferredFiles,
        total_transferred_size: totalTransferredSize,
        current_file: currentFile,
        error: progressError,
      } = bucketState.progress || {};
      let { name: currentFileName, transferred_size: currentFileTransferredSize } = currentFile || {};

      totalFiles = totalFiles || 0;
      totalSize = totalSize || 0;
      totalTransferredFiles = totalTransferredFiles || 0;
      totalTransferredSize = totalTransferredSize || 0;
      currentFileTransferredSize = currentFileTransferredSize || 0;

      const totalDownloaded = totalTransferredSize + currentFileTransferredSize;
      let percentCompleted = Math.round((totalDownloaded * 100) / (totalSize));
      if (completed && totalSize === 0)
        percentCompleted = 100;
      const elapsedSeconds = startDate != null ? ((endDate || new Date()) - startDate) / 1000.0 : 0;
      const error = requestError || progressError;

      return {
        bucket,
        elapsed: sec2time(elapsedSeconds),
        downloaded: filesize(totalDownloaded) + ' of ' + filesize(totalSize),
        overallProgress: { progress: percentCompleted, error: error, completed: completed },
        filesProgress: { total: totalFiles, completed: totalTransferredFiles },
        currentFile: currentFileName,
        error: error?.toString()
      };
    };

    const data = Object.entries(bucketsProgress).map((entry, index) => {
      const [bucket, bucketState] = entry;
      const progressInfo = getBucketTableData(bucket, bucketState);
      progressInfo.key = index;
      return progressInfo;
    });

    return (
      <>
        <Space direction="vertical" style={{ width: "100%" }}>
          <div>
            <h1>S3 Buckets</h1>
            <Select
              style={{ width: 240 }}
              onChange={this.handleSelectedBucket}
              loading={buckets.length === 0}>

              {buckets.map((value) => {
                return <Option value={value} key={value}>{value}</Option>;
              })}
            </Select>
          </div>

          {selected &&
            <Card title="S3 File explorer">
              <S3FilesNavigationContainer key={selected}
                {...this.props}
                bucket={selected}
                onDownloadFolder={folder => this.syncBucket(selected, folder)} />
            </Card>
          }

          <Card title="Sync progress">
            <Table dataSource={data} columns={this.columnsProgress} size="small" pagination={{ hideOnSinglePage: true }} />
          </Card>
        </Space>
      </>
    );
  }
}
