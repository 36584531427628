import React from "react";
import {MgConflictsTable} from "./MgConflictsTable";
import {Button, Row, Tooltip} from "antd";
import {ArrowLeftOutlined} from "@ant-design/icons";

export function MgSummary({mgConflicts, selectedMgConflict, onClose, onFiltersChanged}) {
    return (
        <>
            <Row justify="space-between" style={{marginBottom: '16px'}}>
                <Tooltip title="Close">
                    <Button id="close-mg-conflicts-button" shape="circle" size="medium" icon={<ArrowLeftOutlined />}
                            onClick={onClose}
                    />
                </Tooltip>
            </Row>
            <MgConflictsTable
                selectedMgConflict={selectedMgConflict}
                mgConflicts={mgConflicts}
                onFiltersChanged={onFiltersChanged}
                onClose={onClose}
            />
            {selectedMgConflict.detailsTag()}
        </>
    );
}