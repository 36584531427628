import * as queryString from "query-string";

export class SortedQuery {
    constructor(query) {
        this.query = query.toString();
    }

    toString() {
        const parsed = queryString.parse(this.query);
        return queryString.stringify(parsed);
    }
}

export class QueryWithPage {
    constructor(query, pageNumber) {
        this.query = query.toString();
        this.pageNumber = pageNumber;
    }

    toString() {
        return [this.query, `page=${this.pageNumber.toString()}`].join("&");
    }
}

export class QueryWithShowMode {
    constructor(query, showMode) {
        this.query = query.toString();
        this.showMode = showMode;
    }

    toString() {
        return [this.query, `showMode=${this.showMode.toString()}`].join("&");
    }
}


export class ShowModeFromQuery {
    constructor(query) {
        this.query = query;
    }

    getValue() {
        const queryObj = queryString.parse(decodeURI(this.query));

        return queryObj['showMode'] || 'documents';
    }
}


export class QueryWithIgnoreTextObjects {
    constructor(query, ignoreTextObjects) {
        this.query = query.toString();
        this.ignoreTextObjects = ignoreTextObjects;
    }

    toString() {
        return [this.query, `ignoreTextObjects=${this.ignoreTextObjects.toString()}`].join("&");
    }
}


export class TabFromQuery {
    constructor(query) {
        this.query = query;
    }

    getValue() {
        const queryObj = queryString.parse(decodeURI(this.query));

        return (queryObj['tab'] || 'drawings').toString();
    }
}


export class QueryWithTab {
    constructor(query, tab) {
        this.query = query;
        this.tab = tab;
    }

    toString() {
        const parts = [];
        const strQuery = this.query.toString();

        if (strQuery) {
            parts.push(strQuery);
        }

        parts.push(`tab=${this.tab.toString()}`);

        return parts.join("&");
    }
}