import {CnCanvasObject} from "../CnCanvasObject";

export class SafeCnCanvasObject extends CnCanvasObject {
    constructor(origin) {
        super();
        this._origin = origin;
    }

    select() {
        try {
            this._origin.select();
        } catch (e) {

        }
    }

    deselect() {
        try {
            this._origin.deselect();
        } catch (e) {

        }
    }

    addToCanvas() {
        try {
            this._origin.addToCanvas();
        } catch (e) {

        }
    }

    removeFromCanvas() {
        try {
            this._origin.removeFromCanvas();
        } catch (e) {

        }
    }

    addHighlight() {
        try {
            this._origin.addHighlight();
        } catch (e) {

        }
    }

    removeHighlight() {
        try {
            this._origin.removeHighlight();
        } catch (e) {

        }
    }

    show() {
        try {
            this._origin.show();
        } catch (e) {

        }
    }

    hide() {
        try {
            this._origin.hide();
        } catch (e) {

        }
    }
}