import { connect } from 'react-redux'
import { ProjectsList } from '../components/ProjectsList';


const mapStateToProps = state => {
  return {
    dataSource: state.projects.items
  }
}

const ProjectsListContainer = connect(mapStateToProps)(ProjectsList)

export default ProjectsListContainer