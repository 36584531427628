import {MgConflicts} from "../MgConflicts";
import {Cached} from "../MgConflict";

export class CachedMgConflicts extends MgConflicts {
    constructor(origin) {
        super();

        this._values = new Cached(() => origin.values()).value;
        this._loaded = new Cached(() => origin.loaded()).value;
    }

    values() {
        return this._values();
    }

    loaded() {
        return this._loaded();
    }
}