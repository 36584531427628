import React, {useEffect, useState} from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Table,
  Radio,
  Select,
  Divider,
  Tag,
  Popconfirm,
  message,
  Checkbox, Tooltip
} from "antd";
import {DragSortingTable} from "../Misc/DragSortingTable";
import {EditOutlined, DeleteOutlined, PlusOutlined, CloseOutlined} from '@ant-design/icons';
import WorkspacesService from "../../services/WorkspacesService";
import EditableTagGroup from "../Misc/EditableTagGroup";
import {DeleteWorkspaceReportAction} from "../Misc/DeleteWorkspaceReportAction";

const customColumnNames = ['file_name', 'page_number', 'link', 'attributes', 'empty'];

class ColumnData {
  constructor(name, value, value_type) {
    this.name = name;
    this.value = value;
    this.value_type = value_type;
  }

  isStandardField() {
    if (this.value_type === 'attribute' && ['text', 'label', 'description'].includes(this.value)) return true;
    if (this.value_type === 'custom') return true;
    return false;
  }

  toFormValues() {
    const formValues = {
      name: this.name,
      column_type: '',
      standard_field_name: '',
      page_field_name: '',
      attribute_name: '',
    }
    if (this.isStandardField()) {
      formValues.column_type = 'standard_field';
      formValues.standard_field_name = this.value;
    } else if (this.value_type === 'field') {
      formValues.column_type = 'page_field';
      formValues.page_field_name = this.value;
    } else if (this.value_type === 'attribute') {
      formValues.column_type = 'attribute';
      formValues.attribute_name = this.value;
    }
    return formValues;
  }

  static fromFormValues(formValues) {
    const value = formValues[`${formValues.column_type}_name`];
    let value_type;
    if (formValues.column_type === 'standard_field') {
      if (['text', 'label', 'description'].includes(value)) value_type = 'attribute';
      else value_type = 'custom';
    } else if (formValues.column_type === 'page_field') {
      value_type = 'field';
    } else if (formValues.column_type === 'attribute') {
      value_type = 'attribute';
    }
    return new ColumnData(formValues.name, value, value_type)
  }

  static fromObject(obj) {
    return new ColumnData(obj.name, obj.value, obj.value_type);
  }
}


export default class ConfigureExcelReports extends React.Component {

  prettyColumnName = {
    'text': 'Text',
    'label': 'Class',
    'description': 'Description',
    'file_name': 'File name',
    'page_number': 'Page number',
    'link': 'Link',
    'attributes': 'Attributes',
    'empty': 'Empty',
  }

  defaultColumns = [
    {name: 'Tag number', value: 'text', value_type: 'attribute'},
    {name: 'Tag type', value: 'label', value_type: 'attribute'},
    {name: 'Tag description', value: 'description', value_type: 'attribute'},
    {name: 'File name', value: 'file_name', value_type: 'custom'},
    {name: 'Page number', value: 'page_number', value_type: 'custom'},
    {name: 'Link', value: 'link', value_type: 'custom'},
    {name: 'Attributes', value: 'attributes', value_type: 'custom'}
  ];

  defaultLabelsToIgnore = ["text"];

  state = {
    reports: [],
    mode: 'list',
    isModalShown: false,
    currentColumns: [],
    currentLabelsToIgnore: [],
    currentColumnIndex: null,
    currentReport: null,
    postProcessingFunctions: [],
  }

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.newReportFormRef = React.createRef();
  }

  componentDidMount() {
    this.loadReportsConfigs();
    this.loadPostProcessingFunctions();
  }

  loadReportsConfigs = () => {
    WorkspacesService.fetchExcelReportsConfigs(this.props.workspaceId).then(res => {
      this.setState({reports: res.data});
    }).catch(() => message.error('Failed to load reports configs'));
  }

  loadPostProcessingFunctions = () => {
    WorkspacesService.fetchPostProcessingLambdaFunctions(this.props.workspaceId).then(res => {
      this.setState({postProcessingFunctions: res.data});
    }).catch(() => message.error('Failed to load post processing functions'));
  }

  hideModal = () => {
    this.setState({isModalShown: false});
  }

  handleNewColumn = () => {
    this.setState({isModalShown: true, currentColumnIndex: null}, () => this.formRef.current.setFieldsValue({
      column_type: 'standard_field',
      standard_field_name: '',
      page_field_name: '',
      attribute_name: '',
      name: '',
      unique_tags: false
    }))
  }

  handleEditColumn = (colIndex) => {
    this.setState({isModalShown: true, currentColumnIndex: colIndex},
        () => this.formRef.current.setFieldsValue(ColumnData.fromObject(this.state.currentColumns[colIndex]).toFormValues())
    )
  }

  handleSaveColumnData = (values) => {
    if (this.state.currentColumnIndex === null) {
      // add new column
      this.setState({currentColumns: [...this.state.currentColumns, ColumnData.fromFormValues(values)]});
    } else {
      // edit existing column
      const updatedColumns = this.state.currentColumns.slice();
      updatedColumns[this.state.currentColumnIndex] = ColumnData.fromFormValues(values);
      this.setState({currentColumns: updatedColumns});
    }
    this.hideModal();
  }

  getPrettyValue = (columnData) => {
    const curFormValues = ColumnData.fromObject(columnData).toFormValues();
    if (curFormValues.column_type === 'standard_field') return <Tag color="blue">{this.prettyColumnName[curFormValues.standard_field_name]}</Tag>;
    else if (curFormValues.column_type === 'page_field') return <Tag color="green">{curFormValues.page_field_name}</Tag>;
    else return <Tag color="orange">{curFormValues.attribute_name}</Tag>;
  }

  handleDeleteColumn = (colIndex) => {
    this.setState({currentColumns: this.state.currentColumns.filter((_, i) => i !== colIndex)});
  }

  handleNewReport = () => {
    this.setState({mode: 'new', currentColumns: this.defaultColumns, currentReport: null,
          currentLabelsToIgnore: this.defaultLabelsToIgnore},
      () => this.newReportFormRef.current.setFieldsValue({name: '', unique_tags: false, post_processing_lambda_id: null})
    );
  }

  handleEditReport = (report) => {
    this.setState({mode: 'edit', currentColumns: report.columns, currentReport: report,
          currentLabelsToIgnore: report.labels_to_ignore},
        () => {
          this.newReportFormRef.current.setFieldsValue({
            name: report.name,
            unique_tags: report.unique_tags,
            post_processing_lambda_id: report.post_processing_lambda_id,
          })
        }
    );
  }

  handleSaveReport = (values) => {
    if (this.state.mode === 'new') {
      WorkspacesService.addExcelReportConfig(this.props.workspaceId, {
        name: values.name,
        unique_tags: values.unique_tags,
        columns: this.state.currentColumns,
        labels_to_ignore: this.state.currentLabelsToIgnore,
        post_processing_lambda_id: values.post_processing_lambda_id,
      }).then(() => {
        message.success('Report config saved');
        this.loadReportsConfigs();
        this.setState({mode: 'list'});
      }).catch(() => {
        message.error('Failed to save report config')
      })
    } else if (this.state.mode === 'edit') {
      WorkspacesService.updateExcelReportConfig(this.props.workspaceId, {
        id: this.state.currentReport.id,
        name: values.name,
        unique_tags: values.unique_tags,
        columns: this.state.currentColumns,
        labels_to_ignore: this.state.currentLabelsToIgnore,
        post_processing_lambda_id: values.post_processing_lambda_id,
      }).then(() => {
        message.success('Report config updated');
        this.loadReportsConfigs();
        this.setState({mode: 'list'});
      }).catch(() => {
        message.error('Failed to update report config')
      })
    }
  }

  handleDeleteReport = (reportId) => {
    WorkspacesService.deleteExcelReportConfig(this.props.workspaceId, reportId).then(() => {
      message.success('Report config removed');
      this.loadReportsConfigs();
    }).catch(() => {
      message.error('Failed to remove report config');
    })
  }

  render() {
    const reportsListColumns = [
      {
        title: 'Report name',
        dataIndex: 'name',
      },
      {
        title: 'Actions',
        dataIndex: 'id',
        render: (id, record) => {
          return (
            <>
              <Space size="large">
                <a onClick={() => this.handleEditReport(record)}>Edit</a>
                <DeleteWorkspaceReportAction onDelete={() => this.handleDeleteReport(id)}/>
              </Space>
            </>
          )
        }
      }
    ];
    return (
      <>
        {this.state.mode === 'list' &&
          <Card title="Configure tags reports" extra={<Button type="primary" onClick={this.handleNewReport}>New tags report</Button>}>
            <Row>
              <Col span={24}>
                <Table columns={reportsListColumns} dataSource={this.state.reports}/>
              </Col>
            </Row>
          </Card>
        }
        {['new', 'edit'].includes(this.state.mode)  &&
          <div style={{display: 'flex',  justifyContent:'center'}}>
            <Card title={this.state.mode === 'new' ? "New report" : 'Edit report'} style={{width: '50%'}} >
              <Form ref={this.newReportFormRef} onFinish={this.handleSaveReport} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
                <Form.Item name="name" label="Report name" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
                <Form.Item name="unique_tags" label="Unique tags" rules={[{ required: false }]} valuePropName={"checked"}>
                  <Checkbox/>
                </Form.Item>
                <Form.Item label={"Classes to ignore"}>
                  <EditableTagGroup
                      dataSource={this.state.currentLabelsToIgnore}
                      onTagsChanged={(labels) => {
                        this.setState({currentLabelsToIgnore: labels});
                      }}
                      newTagText={"New Class"}
                      tagColor={'cyan'}
                      withClearAllButton={false}
                      withEditTagAbility={false}
                  />
                </Form.Item>
                <Form.Item name="post_processing_lambda_id" label="Post processing function" rules={[{ required: false }]}>
                  <Select>
                    {[{id: null, name: 'No post processing'}, ...this.state.postProcessingFunctions].map(lambdaFunction => {
                      return (
                          <Select.Option value={lambdaFunction.id}>{lambdaFunction.name}</Select.Option>
                      )
                    })}
                  </Select>
                </Form.Item>
                <Card title="Columns" extra={<Button type="primary" onClick={this.handleNewColumn}>New column</Button>} type="inner" size="small">
                  <DragSortingTable
                    dataSource={this.state.currentColumns}
                    onRowsMoved={newRows => this.setState({currentColumns: newRows})}
                    size="small"
                    scroll={{y: '40vh'}}
                    pagination={{pageSize: 1000000, hideOnSinglePage: true}}
                    >
                    <Table.Column
                        title='Column name'
                        dataIndex='name'
                    />
                    <Table.Column
                        title='Value'
                        // dataIndex='value'
                        render={(value, record) => {
                          return this.getPrettyValue(record)
                        }}

                        // dataIndex=''
                    />
                    <Table.Column
                        title='Actions'
                        dataIndex='id'
                        render={(id, record, index) => (
                            <>
                              <a onClick={() => this.handleEditColumn(index)}><EditOutlined style={{fontSize: '16px', marginRight: '16px'}} /></a>
                              <Popconfirm title="Are you sure to delete the column？"
                                          onConfirm={() => this.handleDeleteColumn(index)}
                              >
                                <a><DeleteOutlined style={{fontSize: '16px'}} /></a>
                              </Popconfirm>
                            </>
                        )}
                    />
                  </DragSortingTable>
                </Card>
                <div style={{marginTop: '16px'}}>
                  <Button onClick={() => this.setState({mode: 'list'})}>Cancel</Button>
                  <Button type="primary" htmlType="submit" style={{float: 'right'}}>Save</Button>
                </div>
              </Form>
            </Card>

          </div>
        }
        <Modal
            title={this.state.currentColumnIndex === null ? 'New column' : 'Edit column'}
            visible={this.state.isModalShown}
            footer={null}
            onCancel={this.hideModal}
        >
          <Form name="new-edit-column-form" ref={this.formRef} onFinish={this.handleSaveColumnData} initialValues={{ column_type: 'standard_field' }}>
            <Form.Item
                label="Column name"
                name="name"
                rules={[{ required: true }]}
            >
              <Input/>
            </Form.Item>
            <Divider orientation="left">Column value</Divider>
            <Form.Item name="column_type">
              <Radio.Group style={{width: '100%'}}>
                <Row gutter={[8, 8]}>
                  <Col span={8}>
                    <Radio value="standard_field">Standard field</Radio>
                  </Col>
                  <Col span={16}>
                    <Form.Item name="standard_field_name">
                      <Select>
                        {
                          ['text', 'label', 'description', 'file_name', 'page_number', 'link', 'attributes', 'empty'].map(val => (
                              <Select.Option value={val}>{this.prettyColumnName[val]}</Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[8, 8]}>
                  <Col span={8}>
                    <Radio value="page_field">Page field</Radio>
                  </Col>
                  <Col span={16}>
                    <Form.Item name="page_field_name">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[8, 8]}>
                  <Col span={8}>
                    <Radio value="attribute">Attribute</Radio>
                  </Col>
                  <Col span={16}>
                    <Form.Item name="attribute_name">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Radio.Group>
            </Form.Item>
            <Form.Item>
              <Row>
                <Button onClick={this.hideModal}>Cancel</Button>
                <Button type="primary" htmlType="submit" style={{marginLeft: 'auto'}}>
                  {this.state.currentColumnIndex === null ? 'Add' : 'Update'}
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </Modal>
      </>
    )
  }
}
