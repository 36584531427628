import React from 'react';
import {Layout, Menu} from 'antd';
import KHHeader from './KHHeader';
import {Link} from "react-router-dom";
import axios from "axios";
import {API_URL} from "../constants";
import {authHeader} from "../Utilities";
import {AuthContext} from "../contexts/AuthContext";

export class ProjectsLayout extends React.Component {
  static contextType = AuthContext;

  render() {
    let selectedMenuItem
    switch (this.props.location.pathname.split('/')[1]) {
      case 'projects':
        selectedMenuItem = 'projects'
        break;
      case 'workspaces':
        selectedMenuItem = 'workspaces'
        break;
      case 'workspace':
        selectedMenuItem = 'workspaces'
        break;
      case 'users':
        selectedMenuItem = 'users'
        break;
      case 'organizations':
        selectedMenuItem = 'organizations';
        break;
      case 'global_settings':
        selectedMenuItem = 'global_settings';
        break;
      default:
        selectedMenuItem = 'projects'
    }

    const showExtraFeatures = this.context.user && this.context.user.roles.includes('admin');

    return (
      <Layout  style={{height:"100vh"}}>
        <KHHeader />
        <Layout>
          <Layout.Sider width={'20%'} theme='light'>
            <Menu theme="light" mode="inline" selectedKeys={[selectedMenuItem]}>
              <Menu.Item key="projects">
                <Link to={`/projects`}>
                  Projects
                </Link>
              </Menu.Item>
              {showExtraFeatures &&
              <Menu.Item key="workspaces">
                <Link to={`/workspaces`}>
                  Workspaces
                </Link>
              </Menu.Item>
              }
              {showExtraFeatures &&
              <Menu.Item key="users">
                <Link to={`/users`}>
                  Users
                </Link>
              </Menu.Item>
              }
              {showExtraFeatures &&
              <Menu.Item key="organizations">
                <Link to={`/organizations`}>
                  Organizations
                </Link>
              </Menu.Item>
              }
              {showExtraFeatures &&
              <Menu.Item key="global_settings">
                <Link to={`/global_settings`}>
                  Global settings
                </Link>
              </Menu.Item>
              }
            </Menu>
          </Layout.Sider>

          <Layout.Content style={{width: '75%', padding: '24px'}}>
            {this.props.children}
          </Layout.Content>
        </Layout>
      </Layout>
    ) 
  }
}
