import React, {useEffect, useMemo, useState} from 'react'
import {AsyncDataSource, TablePage} from "../../Misc/Table/ManualPagedTable";
import {SearchContextConsumer} from "../../../contexts/SearchContext";
import {withRouter} from "react-router";
import * as queryString from "query-string";
import axios from "axios";
import {API_URL} from "../../../constants";
import {authHeader} from "../../../Utilities";
import {Checkbox, Col, message, Row, Select, Space} from "antd";
import {HighlightedMatch} from "../../Misc/HighlightedMatch";
import {FiltersList} from "../../Misc/DataSourceFilter";
import {SearchFiltersFromUrl} from "../SearchFilters/SearchFiltersFromUrl";
import {SearchFilter} from "../SearchFilters/SearchFilters";
import {CommentMarker} from "../../ImageViewer/Comments/Components/Static/StaticComment";


class CommentsDataSource extends AsyncDataSource {
    constructor(filters, showMode, forYou) {
        super();

        this._filters = filters;
        this._showMode = showMode;
        this._forYou = forYou;
    }

    _mapShowMode(value) {
        switch (value) {
            case 'opened':
                return 'open'
            case 'closed':
                return 'closed'
            case 'all':
                return 'all'
            default:
                return ''
        }
    }

    async values(page, limit) {
        const searchFilters = this._filters.filter(filter => filter instanceof SearchFilter);
        const searchFilter = searchFilters[0] || {
            value: '',
            useWildcards: false
        };

        const data = await axios.get(
            API_URL + `/user_view/comments`,
            {
                params: {
                    page: encodeURIComponent(page),
                    for_you: this._forYou,
                    status: 'all',
                    limit: encodeURIComponent(limit),
                    search: encodeURIComponent(searchFilter.value),
                    use_wildcards: encodeURIComponent(searchFilter.useWildcards),
                    show_mode: this._mapShowMode(this._showMode)
                },
                headers: authHeader()
            }
        ).then(res => res.data).catch(err => {
            message.error('Failed to load comments');
            return [];
        });

        return data;
    }
}


class LocationProps {
    constructor(location, query) {
        this._location = location;
        this._query = query;
    }

    page() {
        const pageNumber = parseInt(this._query['page']?.toString()) || 1;
        return pageNumber;
    }

    showMode() {
        const showMode = this._query['showMode']?.toString() || 'opened';
        return showMode;
    }

    filters() {
        const filters = new SearchFiltersFromUrl(this._location.search).getArray();
        return filters;
    }

    forYou() {
        const forYou = (this._query['forYou']?.toString() ?? 'false') === 'true';
        return forYou;
    }
}


function UserViewCommentsFromLocation({location, context, history}) {
    useEffect(() => {
        const searchHandlerSchema = {
            owner: 'global-comments-search',
            searchType: 'search_in_all_comments',
            handler: (value, searchSettings) => {
                context.setQuery(value);

                const url = location.search;
                const query = queryString.parse(decodeURI(url));
                query['q'] = value;
                query['useWildcards'] = searchSettings.useWildcards;

                history.push({
                    pathname: location.pathname,
                    search: queryString.stringify(query)
                });
            }
        }

        context.addSearchHandlerSchema(searchHandlerSchema);

        return () => {
            context.removeSearchHandlerSchemasByOwner('global-comments-search');
        }
    }, [location]);

    const url = location.search;
    const query = queryString.parse(decodeURI(url));
    const locationProps = new LocationProps(location, query);

    const pushToHistory = (searchQuery) => {
        history.push({
            pathname: location.pathname,
            search: queryString.stringify(searchQuery)
        });
    }

    const updateFilters = (filters) => {
        const searchFilters = filters.filter(filter => filter instanceof SearchFilter);

        if (searchFilters.length > 0) {
            const searchFilter = searchFilters[0];
            pushToHistory(
                {...query, page: 1, q: searchFilter.value, useWildcards: searchFilter.useWildcards}
            );
        } else {
            pushToHistory({showMode: locationProps.showMode(), forYou: locationProps.forYou(), page: 1});
        }
    }

    const changePage = (page) => {
        pushToHistory({...query, page: page});
    }

    const changeShowMode = (showMode) => {
        pushToHistory({...query, page: 1, showMode: showMode});
    }

    const changeForYou = forYou => {
        pushToHistory({...query, page: 1, forYou: forYou});
    }

    return (
        <UserViewCommentsInner
            showMode={locationProps.showMode()}
            forYou={locationProps.forYou()}
            filters={locationProps.filters()}
            page={locationProps.page()}

            onChangeFilters={updateFilters}
            onChangePage={changePage}
            onChangeShowMode={changeShowMode}
            onChangeForYou={changeForYou}
        />
    );
}


function UserViewCommentsInner(
    {forYou, onChangeForYou, showMode, onChangeShowMode, page, onChangePage, filters, onChangeFilters}
) {
    const [dataSource, setDataSource] = useState(new AsyncDataSource());

    useEffect(() => {
        setDataSource(new CommentsDataSource(filters, showMode, forYou));
    }, [JSON.stringify(filters), showMode, forYou]);

    const columns = useMemo(() => {
        const matchColum = [];

        if (filters.length > 0) {
            matchColum.push({
                title: 'Match',
                dataIndex: 'match',
                fixed: 'left',
                render: (match) => {
                    return (
                        <HighlightedMatch matchText={match.text} occurrences={match.occurrences}/>
                    );
                }
            })
        }

        return [
            ...matchColum,
            {
                title: 'Message',
                dataIndex: 'message',
            },
            {
                title: 'File name',
                dataIndex: 'file_name'
            },
            {
                title: 'Run name',
                dataIndex: 'run_name'
            },
            {
                title: 'Status',
                dataIndex: 'status',
            },
            {
                title: 'References',
                dataIndex: 'markers',
                render: markers => {
                    return markers.map(marker => (
                        <CommentMarker key={marker.id} marker={marker}/>
                    ))
                }
            },
            {
                title: 'Assigned to',
                dataIndex: 'assign_targets',
                render: targets => {
                    if ((targets?.length ?? 0) === 0) return '';

                    return (
                        <a>{targets[0].name}</a>
                    )
                }
            },
            {
                title: 'Links',
                fixed: 'right',
                width: '5%',
                render: (comment) => {
                    return (
                        <a href={comment.link}>Open</a>
                    );
                }
            }
        ];
    }, [filters]);

    return (
        <>
            <Row justify={"space-between"} style={{marginBottom: 8}}>
                <Col span={15}>
                    <FiltersList itemFilters={filters} onItemFiltersChanged={onChangeFilters}/>
                </Col>
                <Space>
                    <Checkbox
                        id={'for-you-checkbox'}
                        checked={forYou}
                        onChange={e => onChangeForYou(e.target.checked)}
                    >
                        For you
                    </Checkbox>
                    <Space>
                        <div>Show</div>
                        <Select id={'show-select'} value={showMode} onChange={onChangeShowMode} size={"small"}>
                            <Select.Option value={"all"}>All</Select.Option>
                            <Select.Option value={"closed"}>Closed</Select.Option>
                            <Select.Option value={"opened"}>Opened</Select.Option>
                        </Select>
                    </Space>
                </Space>
            </Row>
            <TablePage
                rowKey={record => record.id}
                id="comments-list"
                page={page}
                limit={10}
                columns={columns}
                showSizeChanger={false}
                onPageChange={onChangePage}
                asyncDataSource={dataSource}
                scroll={{x: 1500}}
                size="small"
            />
        </>
    );
}


const UserViewCommentsInnerWithRouter = withRouter(UserViewCommentsFromLocation);


function UserViewComments() {
    return (
        <SearchContextConsumer>
            {context => (
                <UserViewCommentsInnerWithRouter context={context}/>
            )}
        </SearchContextConsumer>
    )
}

export default UserViewComments;