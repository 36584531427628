import React from 'react';
import {Button, Form, Input, Col, Row, Layout, Card, Modal, Space, message} from 'antd'
import KHHeader from "../KHHeader";
import AuthService from "../../services/AuthService";

export class Login extends React.Component {
  state = {
    forgotPasswordModalVisible: false,
  }

  showModal = () => {
    this.setState({forgotPasswordModalVisible: true})
  }

  hideModal = () => {
    this.setState({forgotPasswordModalVisible: false})
  }

  handleResetPassword = (values) => {
    const userEmail = values.email;
    AuthService.requestPasswordReset(userEmail).then(() => {
      message.success(`Password reset instructions have been sent to ${userEmail}`, 5);
      this.hideModal();
    }).catch(() => {
      message.error('Failed to reset password');
    })
  }

  onFinish = (values) => {
    AuthService.login(values.email, values.password, () => {
      const { from } = this.props.location.state || { from: { pathname: '/' } };
      if (from.pathname === '/') {
        this.props.history.push(from.pathname);
        window.location.reload();
      } else {
        window.open(from.pathname, "_self");
      }
    });
  }

  render() {
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };
    const tailLayout = {
      wrapperCol: { offset: 8, span: 16 },
    };
    return (
      <>
        <Layout  style={{height:"100vh"}}>
          <KHHeader skipUser={true}/>
          <Layout.Content style={{width: '75%', alignSelf: 'center', padding: '24px'}}>
            <Col span={8} offset={8}>
              <Card title="Sign in">
                <Form
                    {...layout}
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={this.onFinish}
                >
                  <Form.Item
                      label="Email"
                      name="email"
                      rules={[{ required: true, message: 'Please input your email!' }, { type: 'email', message: 'Please input valid email'}]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                      label="Password"
                      name="password"
                      rules={[{ required: true, message: 'Please input your password!' }]}
                  >
                    <Input.Password />
                  </Form.Item>
                    <Form.Item {...tailLayout}>
                      <Row>
                        <Button type="primary" htmlType="submit">
                          Login
                        </Button>
                        <a onClick={this.showModal} style={{marginLeft: 'auto', alignSelf: 'center'}}>Forgot password?</a>
                      </Row>
                    </Form.Item>
                </Form>
              </Card>
            </Col>
          </Layout.Content>
        </Layout>
        <Modal
            title="Forgot password"
            visible={this.state.forgotPasswordModalVisible}
            footer={null}
            // onOk={this.handleResetPassword}
            onCancel={this.hideModal}
        >
          <Form name="reset-password-form"
            onFinish={this.handleResetPassword}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
          >
            <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: 'Please input your email!' },
                  { type: 'email', message: 'Please input valid email!'}
                ]}
            >
              <Input />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }} >
              <Row>
                <Button type="primary" htmlType="submit" style={{marginLeft: 'auto'}}>
                  Reset password
                </Button>
              </Row>
            </Form.Item>

          </Form>
        </Modal>
    </>
    )
  }
}