import {EditedFilter} from "../../../../../../Misc/CommonFilterEditor/Model/EditedFilter";
import {CnStatusFilter} from "./CnStatusFilter";

export class CnStatusEditedFilter extends EditedFilter {
    constructor(values) {
        super();
        this._values = values;
    }

    canBeAdded() {
        if (this._values.length <= 0) {
            return false;
        }

        if (this._values.find(el => !el)) {
            return false;
        }

        if (this._values.find(el => !['ADDED', 'MOVED', 'REMOVED', 'MODIFIED', 'SAME'].includes(el))) {
            return false;
        }

        return true;
    }

    toConcreteFilter() {
        return new CnStatusFilter(this._values);
    }
}