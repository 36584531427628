export function EditMarkerContainer({marker, onUpdate, children}) {
    const updateMarkerText = text => {
        const updated = {...marker, text: text};
        onUpdate(updated);
    };

    return children({
        marker: marker,
        onMarkerTextUpdate: updateMarkerText
    });
}