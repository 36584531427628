import axios from "axios";
import {API_URL, KEYCLOAK_AUTH_SERVER_URL, KEYCLOAK_REALM, KEYCLOAK_CLIENT_ID, KEYCLOAK_ENABLED} from "../constants";
import {message} from "antd";
import Keycloak from "keycloak-js";

let _kc;
if (KEYCLOAK_ENABLED) {
  _kc = new Keycloak({
    url: KEYCLOAK_AUTH_SERVER_URL,
    realm: KEYCLOAK_REALM,
    clientId: KEYCLOAK_CLIENT_ID
  });
}


class KeycloakAuthService {
  init(onAuthenticatedCallback) {
    _kc.init({
      onLoad: 'login-required',
      pkceMethod: 'S256'
    })
        .then(onAuthenticatedCallback)
        .catch(console.error);
  }

  login = _kc.login;

  logout = _kc.logout;

  isAuthenticated() {
    return !!_kc.token;
  }

  headers() {
    return {Authorization: 'Bearer ' + _kc.token};
  }

  updateToken = (successCallback) =>
      _kc.updateToken(5)
          .then(successCallback)
          .catch(this.login);
}

class VanillaAuthService {
  init(callback) {
    callback();
  }

  login(email, password, onSuccessCallback) {
    return axios.post(API_URL + '/auth/signin', {
        email: email.toLowerCase(),
        password
      }).then(response => {
        if (response.data.access_token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        onSuccessCallback();
      }).catch(err => {
        message.error('Login failed');
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  isAuthenticated() {
    return this.getCurrentUser() !== null;
  }

  headers() {
    const user = this.getCurrentUser();
    if (user && user.access_token) {
      return { Authorization: 'Bearer ' + user.access_token };
    } else {
      return {};
    }
  }

  changePassword(email, token, password) {
    return axios.post(API_URL + '/auth/change_password', {
      email: email.toLowerCase(),
      token,
      new_password: password
    })
  }

  validateResetPasswordToken(email, token) {
    return axios.post(API_URL + '/auth/validate_reset_password_token', {
      email: email.toLowerCase(),
      token,
    })
  }

  requestPasswordReset(email, period='short') {
    return axios.post(API_URL + '/auth/reset_password', { email: email.toLowerCase(), period })
  }
}


function service() {
  return KEYCLOAK_ENABLED ? new KeycloakAuthService() : new VanillaAuthService()
}

export default service();
