import React from 'react'
import {fetchProjects} from "../actions/actions";
import {Button, Dropdown, Menu, message, Popconfirm, Row, Table, Typography} from "antd";
import {Link} from "react-router-dom";
import axios from "axios";
import {API_URL} from "../constants";
import {authHeader, download} from "../Utilities";
import contentDisposition from 'content-disposition'

export class FinalResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      finalResultsList: [],
      page: 1,
      limit: 20,
      maxPage: 0
    };
  }

  loadFinalResults = (projectId) => {
    const params = {
      search: this.state.searchText,
      page: this.state.page,
      limit: this.state.limit
    }

    axios.get(
        API_URL + `/projects/${projectId}/final_results`,
        {params, headers: authHeader()}
    ).then(result => {
      const finalResultsList = result.data.final_results;

      this.updateTotal(params.page, finalResultsList.length, params.limit);

      this.setState({
        finalResultsList: finalResultsList.slice(0, params.limit),
      });
    });
  }

  updateTotal(page, resultsSize, limit) {
    if (page > this.state.maxPage) {
      this.setState({maxPage: page, total: (page - 1) * limit + resultsSize})
    }
  }

  componentDidMount() {
    this.loadFinalResults(this.props.match.params.projectId)
  }

  handleFinalResultRemoval = (projectId, sampleId) => {
    axios.delete(API_URL + `/projects/${projectId}/final_results/${sampleId}`, {headers: authHeader()}).then(result => {
      this.loadFinalResults(projectId)
    }).catch(err => {
      message.error('Error removing final result')
    });
  }

  handleExportResults = (projectId) => {
    axios.post(`${API_URL}/projects/${projectId}/export_final_results`,
        {},{
          headers: authHeader(),
          responseType: 'blob'
        }).then((response) => {
          const blob = new window.Blob([response.data], { type: 'application/zip' });
          const contentDispositionHeader = response.headers['content-disposition'];
          let fileName = 'results.zip';
          if (contentDispositionHeader) {
            fileName = contentDisposition.parse(contentDispositionHeader).parameters.filename
          }
          download(blob, fileName);
      }).catch(err => message.error('Results export failed'))
  }

  render() {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        // key: 'name',
      },
      {
        title: 'Actions',
        dataIndex: 'id',
        render: (sampleId) => {
          return (
              <>
                <Row style={{justifyContent: 'space-between'}}>
                  <Link to={`/project/${this.props.match.params.projectId}/final_results/view_result/${sampleId}`}>
                    Open
                  </Link>

                  <Popconfirm title="Are you sure to delete the result？" onConfirm={() => this.handleFinalResultRemoval(this.props.match.params.projectId, sampleId)}>
                    <a href="#">Delete</a>
                  </Popconfirm>
                </Row>

              </>
          )
        }
      }
    ];

    const actionsMenu = (
        <Menu>
          <Menu.Item onClick={() => this.handleExportResults(this.props.match.params.projectId)}>
            Export results
          </Menu.Item>
        </Menu>
    )

    return (
        <>
          <Row style={{justifyContent: 'space-between', padding: '1em'}}>
            <h1>Final results</h1>

            <Dropdown overlay={actionsMenu} trigger="click">
              <Button>Actions</Button>
            </Dropdown>
          </Row>
          <Table id="project-final-results-list" dataSource={this.state.finalResultsList} columns={columns}
                 pagination={{ position: 'bottomCenter', current: this.state.page, defaultPageSize: this.state.limit, total: this.state.total,
                   showSizeChanger: false,
                   onChange: (page, pageSize)=> this.setState({page: page}, ()=>this.loadFinalResults(this.props.match.params.projectId))}}
          />
        </>
    );
  }
}