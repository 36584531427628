import {InMemoryAsyncDataSource as JsInMemoryAsyncDataSource} from "./ManualPagedTable";

export interface AsyncDataSource<T> {
    values(page: number, limit: number): Promise<T[]>
}

export class InMemoryAsyncDataSource<T> extends JsInMemoryAsyncDataSource implements AsyncDataSource<T> {
    constructor(data: (readonly T[]) | (() => Promise<T[]>)) {
        super(data);
    }

    async values(page: number, limit: number): Promise<T[]> {
        return super.values(page, limit);
    }
}
