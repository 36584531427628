import {Table, message} from "antd";
import React from "react";
import axios from "axios";
import {API_URL} from "../../constants";
import {authHeader} from "../../Utilities";
import {Link} from "react-router-dom";

export class UserViewExploreResultsRunsList extends React.Component {
  state = {
    runsList: []
  }

  loadRuns = () => {
    axios.get(
        API_URL + `/user_view/runs`,
        {headers: authHeader()}
    ).then(result => {
      const runsList = result.data;

      runsList.sort((a, b)=>Date.parse(b.created_at) - Date.parse(a.created_at));

      this.setState({
        runsList: runsList,
      });
    }).catch(err => message.error('Failed to load runs list'));
  }

  componentDidMount() {
    this.loadRuns();
  }

  render() {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
      },
      {
        title: 'Link',
        dataIndex: 'id',
        render: (id) => {
          return (<Link to={`/user_view/explore_results/${id}`}>Open</Link>)
        }
      }
    ]
    return (
      <>
        <h1>Runs list</h1>
        <Table id="runs-list-table" dataSource={this.state.runsList} columns={columns} size="small" style={{paddingTop: '16px'}} />
      </>
    )
  }
}
