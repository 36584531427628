import {JsonEditor} from "jsoneditor-react";
import React, {useRef} from "react";

function JsonInput({ value = {}, onChange, options }) {
  const editorRef = useRef(null);

  const triggerChange = (changedValue) => {
    onChange(changedValue);
  };


  return (
      <JsonEditor {...options} value={value}
                  onChange={val => triggerChange(val)}
                  onValidationError={(errs) => {
                    if (errs.length > 0) triggerChange('');
                  }}
                  ref={editorRef}/>
  )
}

export default JsonInput;
