import BaseLayer from "../../../ImageViewerHandlers/Hierarchy/Markup/Layers/BaseLayer";
import {fabric} from "fabric";

export class CommentMarkersLayer extends BaseLayer {
    constructor(layerId, comments, icon) {
        super(layerId);

        this.comments = comments;
        this.icon = icon;
    }

    getCanvasObjects() {
        const defaultArgs = {
            lockScalingFlip: true,
            fill: 'rgba(0,0,0,0)',
            cornerColor: 'lime',
            cornerStrokeColor: 'gray',
            cornerStyle: 'circle',
            transparentCorners: false,
            cornerSize: 10,
            noScaleCache: false,
            strokeUniform: true,
            objectCaching: false,
        };

        return this.comments.flatMap(comment => comment.markers.flatMap(marker => {
            const obj = new fabric.Rect({
                ...defaultArgs,
                isCommentMarker: true,
                fill: 'rgba(255,241,0,0.5)',
                top: marker.position.y - 16,
                left: marker.position.x - 16,
                width: 32,
                height: 32,
                evented: false,
                rx: 5,
                ry: 5,
                markerMetadata: {
                    commentId: comment.id,
                    markerId: marker.id
                }
            });

            const commentIcon = this.icon();

            commentIcon.set({
                top: marker.position.y - 16,
                left: marker.position.x - 16,

                opacity: 0.6,

                selectable: false,
                visible: true,
            });

            commentIcon.scaleToWidth(32);
            commentIcon.scaleToHeight(32);

            return [commentIcon, obj];
        }));
    }
}


export class SavedCommentMarkersLayer extends CommentMarkersLayer {
    constructor(comments, icon) {
        super('comment-markers-layer', comments, icon);
    }
}


export class UnsavedCommentMarkersLayer extends CommentMarkersLayer {
    constructor(comments, icon) {
        super('unsaved-comment-markers-layer', comments, icon);
    }
}