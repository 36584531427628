import {fabric} from "fabric";
import {Subject} from "rxjs";

const EDGE_STROKE_WIDTH = 35;


export class WrapperObjectHelper {
  constructor(wrapperObject, imageViewer) {
    this.wrapperObject = wrapperObject;
    this.imageViewer = imageViewer;

    this.activeVertex = null;
    this.activeVertexExtraObject = null;
    this.activeVertexTracingLineObject = null;

    this.selected = false;

    this.selectionObjects = [];

    this.graphChanged$ = new Subject();

    this.imageViewer.subscriptions.push(this.graphChanged$.subscribe(() => {
      // TODO: speedup redrawing here
      this.updateSelectionHighlighting();
      this.getVerticesRaw().forEach(v => this.getWrappedObjects().get(v.id).bringToFront());
    }));
  }

  setColor = newColor => {
    this.wrapperObject.objectMetadata.shape.shape_color = newColor;
    this.getEdgesRaw().forEach(edge => this.getWrappedObjects().get(edge.id).set({stroke: newColor}));
    this.imageViewer.canvas.requestRenderAll();
  }

  addToCanvas = () => {
    const canvas = this.imageViewer.canvas;
    canvas.add(this.wrapperObject);
    this.wrapperObject.wrappingData.wrappedObjects.forEach((value, key, _) => canvas.add(value));
    this.imageViewer.objectsChanged$.next(1);
    this.graphChanged$.next(1);
  }

  removeItself = () => {
    this.discardActiveVertex();
    const canvas = this.imageViewer.canvas;
    canvas.remove(this.wrapperObject);
    this.wrapperObject.wrappingData.wrappedObjects.forEach((value, key, _) => canvas.remove(value));
  }

  static buildEmpty(imageViewer) {
    imageViewer.lastObjectIndex += 1;
    return this.buildWrapperObject({
      id: imageViewer.lastObjectIndex,
      label: 'Piping',
      text: '',
      metadata: {
        description: '',
        attributes: []
      },
      shape: {
        shape_type: 'graph',
        shape_color: '#FF000099',
        shape_data: {
          vertices: [],
          edges: [],
        },
      }
    }, imageViewer);
  }

  static buildWrapperObject(objectDetails, imageViewer) {
    let metadata = objectDetails.metadata ?? {};
    metadata.description = metadata.description ?? '';
    metadata.attributes = metadata.attributes ?? [];
    const wrapperObject = new fabric.Rect({
      left: 100, top: 100, width: 100, height: 100,
      evented: false,
      selectable: false,
      visible: false,
      fill: 'rgba(0,0,0,0)',
      objectMetadata: {
        id: objectDetails.id,
        label: objectDetails.label,
        text: objectDetails.text,
        metadata: metadata,
        shape: objectDetails.shape,
      },
      isProperObject: true,
      wrappingData: {
        isWrapperObject: true,
        wrappedObjects: new Map(),
      },
    });
    wrapperObject.hasControls = wrapperObject.hasBorders = false;
    wrapperObject.wrappingData.helper = new WrapperObjectHelper(wrapperObject, imageViewer);

    objectDetails.shape.shape_data.vertices.forEach(v => wrapperObject.wrappingData.helper._createVertexObject(v.id, v.x, v.y));
    objectDetails.shape.shape_data.edges.forEach(edge => wrapperObject.wrappingData.helper._createEdgeObject(edge.id, edge.start_vertex, edge.end_vertex));

    wrapperObject.wrappingData.helper.updateBBox();
    wrapperObject.wrappingData.helper.setLock(true);

    return wrapperObject;
  }

  updateBBox = () => {
    let minx = 10000;
    let miny = 10000;
    let maxx = 0;
    let maxy = 0;

    this.wrapperObject.objectMetadata.shape.shape_data.vertices.forEach(v => {
      minx = Math.min(minx, v.x);
      maxx = Math.max(maxx, v.x);
      miny = Math.min(miny, v.y);
      maxy = Math.max(maxy, v.y);
    });

    this.wrapperObject.set({left: minx, top: miny, width: maxx-minx, height: maxy-miny});
    this.wrapperObject.setCoords();
  }

  getWrappedObjects = () => {
    return this.wrapperObject.wrappingData.wrappedObjects;
  }

  getVerticesRaw = () => {
    return this.wrapperObject.objectMetadata.shape.shape_data.vertices;
  }

  getEdgesRaw = () => {
    return this.wrapperObject.objectMetadata.shape.shape_data.edges;
  }

  getNextId = () => {
    return 1 + Math.max(-1, ...this.getWrappedObjects().keys());
  }

  setVisibility = visible => {
    this.getVerticesRaw().forEach(v => {
      const curVertex = this.getWrappedObjects().get(v.id);
      curVertex.visible = visible;
      curVertex.selectable = visible;
    });

    this.getEdgesRaw().forEach(edge => {
      const curEdge = this.getWrappedObjects().get(edge.id);
      curEdge.visible = visible;
    });
  }

  setLock = locked => {
    this.wrapperObject.objectMetadata.shape.shape_data.vertices.forEach(v => {
      const vObj = this.getWrappedObjects().get(v.id);
      vObj.lockMovementX = locked;
      vObj.lockMovementY = locked;
      vObj.lockScalingX = locked;
      vObj.lockScalingY = locked;
    });
  }

  highlightSelection = () => {
    this.getEdgesRaw().forEach(edge => {
      const v1 = this.getWrappedObjects().get(edge.start_vertex);
      const v2 = this.getWrappedObjects().get(edge.end_vertex);
      const dashedLine = new fabric.Line(
          [v1.left, v1.top, v2.left, v2.top], {
            stroke: 'rgba(255,255,255,0.8)', strokeWidth: 5, selectable: false, evented: false,
            strokeDashArray: [15, 15],
            isProperObject: false,
            wrappingData: {
              wrapperObject: this.wrapperObject,
              objectType: 'dashed-line',
            },
          });
      this.selectionObjects.push(dashedLine);
      this.imageViewer.canvas.add(dashedLine);
    })
  }

  clearHighlighting = () => {
    this.selectionObjects.forEach(obj => this.imageViewer.canvas.remove(obj));
    this.selectionObjects = [];
  }

  updateSelectionHighlighting = () => {
    if (!this.selected) return;
    this.clearHighlighting();
    this.highlightSelection();
    this.imageViewer.canvas.requestRenderAll();
  }

  setSelected = selected => {
    this.selected = selected;

    if (this.selected) {
      this.highlightSelection();
    } else {
      this.clearHighlighting();
    }
    this.imageViewer.canvas.requestRenderAll();
  }

  // returns null if edge doesn't exist
  getEdgeId = (vertexObject1, vertexObject2) => {
    const startId = vertexObject1.wrappingData.selfId;
    const endId = vertexObject2.wrappingData.selfId;
    const matchedEdges = this.getEdgesRaw().filter(edge => {
      if (edge.start_vertex === startId && edge.end_vertex === endId) return true;
      if (edge.start_vertex === endId && edge.end_vertex === startId) return true;
      return false;
    })
    if (matchedEdges.length > 0) return matchedEdges[0].id;
    return null;
  }

  removeEdge = edgeId => {
    const edgeObj = this.getWrappedObjects().get(edgeId);
    this.imageViewer.canvas.remove(edgeObj);
    this.getWrappedObjects().delete(edgeObj);
    this.wrapperObject.objectMetadata.shape.shape_data.edges = this.getEdgesRaw().filter(edge => edge.id !== edgeId);
    this.graphChanged$.next(1);
  }

  removeVertex = vertexId => {
    const edgesToRemove = this.getEdgesRaw().filter(edge => edge.start_vertex === vertexId || edge.end_vertex === vertexId);
    edgesToRemove.forEach(edge => this.removeEdge(edge.id));

    const vertexObj = this.getWrappedObjects().get(vertexId);
    if (vertexObj === this.activeVertex) this.discardActiveVertex();
    this.imageViewer.canvas.remove(vertexObj);
    this.getWrappedObjects().delete(vertexObj);
    this.wrapperObject.objectMetadata.shape.shape_data.vertices = this.getVerticesRaw().filter(vertex => vertex.id !== vertexId);
    this.updateBBox();
    this.graphChanged$.next(1);
  }

  addEdge = (vertexObject1, vertexObject2) => {
    const edgeId = this.getNextId();
    const startId = vertexObject1.wrappingData.selfId;
    const endId = vertexObject2.wrappingData.selfId;
    this.getEdgesRaw().push({id: edgeId, start_vertex: startId, end_vertex: endId});
    const newEdge = this._createEdgeObject(edgeId, startId, endId);
    this.imageViewer.canvas.add(newEdge);
    this.graphChanged$.next(1);
  }


  toggleEdge = (vertexObject1, vertexObject2) => {
    const edgeId = this.getEdgeId(vertexObject1, vertexObject2);
    if (edgeId !== null) this.removeEdge(edgeId);
    else {
      this.addEdge(vertexObject1, vertexObject2);
    }
    this.imageViewer.canvas.renderAll();
  }

  discardActiveVertex = () => {
    if (this.activeVertex) {
      this.activeVertex = null;
      this.imageViewer.canvas.remove(this.activeVertexExtraObject);
      this.activeVertexExtraObject = null;
      this.imageViewer.canvas.remove(this.activeVertexTracingLineObject);
    }
  }

  setActiveVertex = vertexObject => {
    this.discardActiveVertex();
    this.activeVertex = vertexObject;
    this.activeVertexExtraObject = new fabric.Circle({
      left: vertexObject.left, top: vertexObject.top, originX: 'center', originY: 'center',
      strokeWidth: 10, radius: 20, fill: 'rgba(0,0,0,0)', stroke: 'cyan', isProperObject: false, evented: false,
      selectable: false,
    });

    this.activeVertexTracingLineObject = new fabric.Line(
        [this.activeVertex.left, this.activeVertex.top, this.activeVertex.left, this.activeVertex.top], {
          fill: 'red', stroke: 'red', strokeWidth: 5, strokeDashArray: [15, 15], selectable: false, evented: false,
          isProperObject: false,
        })

    this.imageViewer.canvas.add(this.activeVertexExtraObject);
    this.imageViewer.canvas.add(this.activeVertexTracingLineObject);
    this.imageViewer.canvas.renderAll();
  }

  updateEdges = () => {
    this.getEdgesRaw().forEach(edge => {
      const vStart = this.getWrappedObjects().get(edge.start_vertex);
      const vEnd = this.getWrappedObjects().get(edge.end_vertex);
      const curEdge = this.getWrappedObjects().get(edge.id);
      curEdge.set({x1: vStart.left - EDGE_STROKE_WIDTH / 2, y1: vStart.top - EDGE_STROKE_WIDTH / 2,
        x2: vEnd.left - EDGE_STROKE_WIDTH / 2, y2: vEnd.top - EDGE_STROKE_WIDTH / 2});
    });
    this.graphChanged$.next(1);
    this.imageViewer.canvas.renderAll();
  }

  // doesn't add the object to canvas
  _createVertexObject = (id, x, y) => {
    const vertex = new fabric.Circle({
      left: x, top: y, originX: 'center', originY: 'center', strokeWidth: 5, radius: 12, fill: '#fff', stroke: '#666',
      isProperObject: false, selectable: false, evented: false,
      wrappingData: {
        selfId: id,
        wrapperObject: this.wrapperObject,
        objectType: 'vertex',
      },
    });
    vertex.hasControls = vertex.hasBorders = false;
    // vertex.on('moving', e => {
    //   const p = this.imageViewer.canvas.getPointer(e);
    //   const verts = this.getVerticesRaw();
    //   const vertInd = verts.findIndex(vert => vert.id === id);
    //   verts[vertInd] = {...verts[vertInd], x: p.x, y: p.y};
    //   this.updateEdges();
    // });
    this.getWrappedObjects().set(id, vertex);
    this.updateBBox()

    // vertex.on('moved', e => {
    //   this.updateBBox();
    // })


    return vertex;
  }

  // doesn't add the object to canvas
  _createEdgeObject = (id, start_vertex_id, end_vertex_id) => {
    const v1 = this.getWrappedObjects().get(start_vertex_id);
    const v2 = this.getWrappedObjects().get(end_vertex_id);
    // related to the bug: https://github.com/fabricjs/fabric.js/issues/5860
    const curLine = new fabric.Line(
        [v1.left - EDGE_STROKE_WIDTH / 2, v1.top - EDGE_STROKE_WIDTH / 2, v2.left - EDGE_STROKE_WIDTH / 2, v2.top - EDGE_STROKE_WIDTH / 2], {
          stroke: this.wrapperObject.objectMetadata.shape.shape_color, strokeWidth: EDGE_STROKE_WIDTH, strokeLineCap: 'round', selectable: false, evented: false,
          // strokeDashArray: [50, 15],
          isProperObject: false,
          wrappingData: {
            selfId: id,
            wrapperObject: this.wrapperObject,
            objectType: 'edge',
          },
        });
    this.wrapperObject.wrappingData.wrappedObjects.set(id, curLine);

    return curLine;
  }

  // returns id of the new vertex
  // adds the object to canvas
  addNewVertex = (x, y) => {
    const newId = this.getNextId();
    this.getVerticesRaw().push({id: newId, x: x, y: y});
    const newVertex = this._createVertexObject(newId, x, y);
    this.imageViewer.canvas.add(newVertex);
    this.graphChanged$.next(1);
    return newId;
  }
}
