import React, {useEffect, useRef} from "react";

export function FitParentSize({children}) {
    const divRef = useRef();
    const observeRef = useRef();

    useEffect(() => {
        const observer = new ResizeObserver(() => {
            if (divRef.current == null) return;
            if (observeRef.current == null) return;
            divRef.current.style.width = `${observeRef.current.clientWidth}px`;
            divRef.current.style.height = `${observeRef.current.clientHeight}px`;
        });

        observer.observe(observeRef.current);
    }, []);

    return (
        <div ref={observeRef} style={{height: "100%", width: "100%"}}>
            <div style={{overflow: "visible", height: "0px", width: "0px"}}>
                <div ref={divRef}>
                    <div style={{
                        overflow: "auto",
                        maxHeight: "100%",
                        maxWidth: "100%",
                        height: "100%",
                        width: "100%"
                    }}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}
