import React from 'react';
import { connect } from 'react-redux'
import { loadFolderContent } from '../actions/fileExplorer';
import { FilesNavigation } from '../components/FilesNavigation';
import * as queryString from 'query-string'
import { API_URL } from '../constants'
import { Modal } from 'antd';
import filesize from 'filesize';
import axios from 'axios'
import { authHeader, download } from "../Utilities";


class FilesNavigationWithUrl extends React.Component {
  defaultPageSize = 20;
  defaultPage = 1;

  constructor(props) {
    super(props);

    this.state = {}
    this.needUpdate = true;
  }

  componentDidMount() {
    const { folder, total, isLoading } = this.props;

    if (!isLoading && total) {
      const urlFolder = this.getFolderForUrl(folder);
      const url = `${this.props.match.url}${urlFolder}${this.propsToQueryUrl(this.props)}`;
      this.props.history.replace(url);
      this.needUpdate = false;
    }
    else {
      this.refreshData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.needUpdate) {
      this.needUpdate = true;
      return;
    }

    if (this.props.error && this.props.error != prevProps.error) {
      Modal.error({
        title: 'Error loading files',
      })
    }

    if (prevProps.location !== this.props.location) {
      this.refreshData();
    }
  }

  getFolderForUrl(folder) {
    let urlFolder = folder;
    if (urlFolder !== '' && !urlFolder.startsWith('/'))
      urlFolder = '/' + urlFolder;

    return urlFolder;
  }

  propsToQueryUrl(props) {
    const { search, page, pageSize, sortField, sortOrder } = props;
    const query = {
      search,
      page,
      pageSize,
      sort: this.constructSortParameter(sortField, sortOrder),
    }

    return this.queryToUrl(query);
  }

  parseFolderFromUrl(props) {
    const rootUrl = props.match.url;
    const fullUrl = props.location.pathname;
    const folder = fullUrl.substring(rootUrl.length + 1);
    return folder;
  }

  parseQueryFromUrl(props) {
    const query = queryString.parse(props.location.search);

    const page = +query['page'] || this.defaultPage;
    const pageSize = +query['pageSize'] || this.defaultPageSize;
    const search = query['search'];
    const sort = query['sort'];

    return {
      search,
      page,
      pageSize,
      sort,
    };
  }

  queryToUrl(query) {
    const queryUrl = queryString.stringify(query, { encode: false });
    return "?" + queryUrl;
  }

  constructSortParameter(sortField, sortOrder) {
    let sorterQuery = undefined;
    if (sortField != null && sortOrder != null) {
      sorterQuery = `${sortField},${sortOrder}`;
    }
    return sorterQuery;
  }

  parseSortParameter(sort) {
    const [sortField, sortOrder] = sort?.split(',') ?? [undefined, undefined];
    return {
      field: sortField,
      order: sortOrder,
    };
  }

  propsToRenderProps(props) {
    const query = this.parseQueryFromUrl(props);

    return {
      search: query.search,
      tableProps: {
        pagination: {
          pageSize: query.pageSize,
          current: query.page,
        },
      },
      tableColumnsProps: {
        sorter: this.parseSortParameter(query.sort),
      },
    }
  }

  constructFullPath(item) {
    const fullPath = item.folder === '' ? item.name : item.folder + '/' + item.name;
    return fullPath;
  }

  refreshData() {
    const folder = this.parseFolderFromUrl(this.props);
    const { search, page, pageSize, sort } = this.parseQueryFromUrl(this.props);
    const { field: sortField, order: sortOrder } = this.parseSortParameter(sort);

    if (folder != null)
      this.props.loadFolderContent(this.props.match.params.projectId, folder, search, page, pageSize, sortField, sortOrder);
  }

  getFileBlob(item) {
    const url = API_URL + `/documents/${item.id}`;
    return axios.get(url, {
      headers: authHeader(),
      responseType: 'blob'
    });
  }

  onFileDoubleClick(item) {
    this.getFileBlob(item).then((response) => {
      const blob = new window.Blob([response.data], { type: response.headers['content-type'] });
      const url = URL.createObjectURL(blob);
      window.open(url);
    })
  }

  onDirDoubleClick(item) {
    this.changeDir(this.constructFullPath(item));
  }

  onDownloadFile(item) {
    this.getFileBlob(item).then(response => {
      const blob = new window.Blob([response.data], { type: response.headers['content-type'] });
      download(blob, item.name);
    });
  }

  onDownloadFolder(item) {
    const folder = this.constructFullPath(item);

    const projectId = this.props.match.params.projectId;
    const params = { folder };

    axios.get(`${API_URL}/projects/${projectId}/folders`, { params, headers: authHeader() })
      .then(response => {
        const taskId = response.data.task_id;
        const getProgress = () => {
          axios.get(`${API_URL}/projects/${projectId}/folders/${taskId}/progress`, { headers: authHeader() })
            .then(response => {
              const progress = response.data;

              if (progress == null || (!progress.error && progress.completed !== true)) {
                setTimeout(() => getProgress(), 1000);
              }
              else {
                axios.get(`${API_URL}/projects/${projectId}/folders/${taskId}/download`, {
                  headers: authHeader(),
                  responseType: 'blob'
                }).then((response) => {
                  const blob = new window.Blob([response.data], { type: 'application/zip' });
                  download(blob, item.name);
                })
              }
            })
        }

        getProgress();
      })
  }

  onDeleteFile(item) {
    this.setState({
      fileToDelete: item,
    })
  }

  onBreadcrumbClick(folder) {
    this.changeDir(folder);
  }

  tableChange(pagination, filters, sorter, extra) {
    const queryObject = {
      ...this.parseQueryFromUrl(this.props),
      page: pagination.current,
      pageSize: pagination.pageSize,
      sort: this.constructSortParameter(sorter.field, sorter.order),
    }
    this.props.history.push(this.queryToUrl(queryObject));
  }

  onSearch(value) {
    if (!value)
      value = undefined

    const queryObject = {
      ...this.parseQueryFromUrl(this.props),
      search: value,
      page: 1,
    }

    this.props.history.push(this.queryToUrl(queryObject));
  }

  changeDir(folder) {
    const queryObject = {
      ...this.parseQueryFromUrl(this.props),
      page: 1,
      search: undefined,
    }

    const urlFolder = this.getFolderForUrl(folder);
    const url = `${this.props.match.url}${urlFolder}${this.queryToUrl(queryObject)}`;
    this.props.history.push(url);
  }

  handleOkDeleteConfirmationDialog() {
    const { fileToDelete } = this.state;

    this.setState({
      confirmLoading: true,
    });

    axios.delete(`${API_URL}/documents/${fileToDelete.id}`, { headers: authHeader() })
      .then(res => {
        this.refreshData();
      })
      .catch(res => {
        Modal.error({
          title: 'Error deleting file',
          content: (
            <>
              <span>{`File name: ${fileToDelete.name}`}</span>
              <br />
              <span>{`File size: ${filesize(fileToDelete.size)}`}</span>
            </>
          ),
        })
      })
      .finally(res => {
        this.closeDeleteConfirmationDialog();
      })
  }

  closeDeleteConfirmationDialog() {
    this.setState({
      confirmLoading: false,
      fileToDelete: undefined
    });
  }

  render() {
    const { search, dirs, files, total, isLoading } = this.props;
    const folder = this.parseFolderFromUrl(this.props);
    const { tableProps, tableColumnsProps } = this.propsToRenderProps(this.props);
    tableProps.pagination.total = total;

    const data = dirs.concat(files).map(item => {
      return {
        ...item,
        key: this.constructFullPath(item),
      }
    });

    const { fileToDelete, confirmLoading } = this.state;

    return (
      <>
        <Modal
          title="Are you sure delete this file?"
          visible={fileToDelete}
          onOk={this.handleOkDeleteConfirmationDialog.bind(this)}
          confirmLoading={confirmLoading}
          cancelButtonProps={{
            disabled: confirmLoading,
          }}
          maskClosable={!confirmLoading}
          closable={!confirmLoading}
          keyboard={!confirmLoading}
          onCancel={this.closeDeleteConfirmationDialog.bind(this)}
          destroyOnClose={true}
        >
          {fileToDelete &&
            <>
              <span>{`File name: ${fileToDelete?.name}`}</span>
              <br />
              <span>{`File size: ${filesize(fileToDelete?.size)}`}</span>
            </>
          }
        </Modal>

        <FilesNavigation
          data={data}
          folder={folder}
          search={search}
          isLoading={isLoading}
          tableProps={tableProps}
          tableColumnsProps={tableColumnsProps}

          onBreadcrumbClick={this.onBreadcrumbClick.bind(this)}
          onSearch={this.onSearch.bind(this)}
          tableChange={this.tableChange.bind(this)}
          onDirDoubleClick={this.onDirDoubleClick.bind(this)}
          onFileDoubleClick={this.onFileDoubleClick.bind(this)}
          onDownloadFile={this.onDownloadFile.bind(this)}
          onDownloadFolder={this.onDownloadFolder.bind(this)}
          onDeleteFile={this.onDeleteFile.bind(this)}
        />
      </>
    );
  }
}


const mapStateToProps = (state, ownProps) => {
  const projectId = ownProps.match.params.projectId;
  const content = state.folderContent.content;
  const resultContent = projectId === content.projectId
    ? content : {
      folder: null,
      dirs: [],
      files: [],
      total: 0,
      error: undefined,
      isLoading: false,
    }

  return {
    ...resultContent,
  }
}


const mapDispatchToProps = {
  loadFolderContent,
}


const FilesNavigationContainer = connect(mapStateToProps, mapDispatchToProps)(FilesNavigationWithUrl)

export default FilesNavigationContainer
