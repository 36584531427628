import { START_LOADING_FOLDER_CONTENT, LOADED_FOLDER_CONTENT } from '../actions/fileExplorer';


export function folderContent(
  state = {
    content: {
      projectId: undefined,
      folder: undefined,
      dirs: [],
      files: [],
      total: 0,
      error: undefined,
      isLoading: false,
    }
  },
  action
) {
  switch (action.type) {
    case LOADED_FOLDER_CONTENT: {
      const content = {
        projectId: action.projectId,
        folder: action.folder,
        search: action.search,
        page: action.page,
        pageSize: action.pageSize,
        sortField: action.sortField,
        sortOrder: action.sortOrder,
        dirs: action.dirs,
        files: action.files,
        total: action.total,
        error: action.error,
        isLoading: false,
      }
      return {
        ...state,
        content
      }
    }
    case START_LOADING_FOLDER_CONTENT: {
      const content = {
        projectId: action.projectId,
        folder: action.folder,
        search: action.search,
        page: action.page,
        pageSize: action.pageSize,
        sortField: action.sortField,
        sortOrder: action.sortOrder,
        dirs:[],
        files: [],
        total: 0,
        error: undefined,
        isLoading: true,
      }
      return {
        ...state,
        content
      }
    }
    default:
      return state
  }
}