import {ObjectAttributeFilter, PageFieldFilter, SearchFilter} from "./SearchFilters";
import {QueryWithIgnoreTextObjects, QueryWithPage, QueryWithShowMode, SortedQuery} from "../../Misc/Query";

class BaseQueryFromFilters {
    constructor(filters) {
        this.filters = filters;
    }

    toString() {
        return "";
    }
}

export class QueryFromFilters extends BaseQueryFromFilters {
    constructor(filters) {
        super(filters);

        this.searchQueries = [
            new PageFieldsQueryFromFilters(filters),
            new ObjectAttributesQueryFromFilters(filters),
            new SearchQueryFromFilters(filters)
        ];
    }

    toString() {
        if (this.filters.length === 0) return '';

        return this.searchQueries.map(query => query.toString()).filter(value => value !== '').join("&");
    }
}

class ObjectAttributesQueryFromFilters extends BaseQueryFromFilters {
    toString() {
        const attrFilters = this.filters.filter(filter => filter instanceof ObjectAttributeFilter);

        return attrFilters
            .map(e =>
                `object_attr[${encodeURIComponent(e.key)}][${encodeURIComponent(e.operation)}]=${encodeURIComponent(e.value)}`)
            .join("&");
    }
}

class PageFieldsQueryFromFilters extends BaseQueryFromFilters {
    toString() {
        const pageFilters = this.filters.filter(filter => filter instanceof PageFieldFilter);

        return pageFilters
            .map(e =>
                `page_field[${encodeURIComponent(e.key)}][${encodeURIComponent(e.operation)}]=${encodeURIComponent(e.value)}`)
            .join("&");
    }
}

class SearchQueryFromFilters extends BaseQueryFromFilters {
    toString() {
        const searchFilters = this.filters.filter(filter => filter instanceof SearchFilter);

        return searchFilters.map(e => `q=${encodeURIComponent(e.value)}&useWildcards=${encodeURIComponent(e.useWildcards)}`)
            .join("&");
    }
}

export class DefaultSearchQuery extends BaseQueryFromFilters {
    constructor(filters, pageNumber, showMode, ignoreTextObjects=true) {
        super(filters);

        this.origin = new SortedQuery(
            new QueryWithIgnoreTextObjects(
                new QueryWithShowMode(
                    new QueryWithPage(
                        new QueryFromFilters(filters),
                        pageNumber),
                    showMode
                ),
                ignoreTextObjects
            )
        );
    }

    toString() {
        return this.origin.toString();
    }
}