import React from 'react';
import {Row, Col, Modal, Form, Input, message, Tag} from 'antd';
import { Typography } from 'antd';
import { Table, Button } from 'antd';
import { showNewProjectForm, fetchProjects } from '../../actions/actions'
import { Link } from "react-router-dom"
import axios from "axios";
import {API_URL, KEYCLOAK_ENABLED, roleColors} from "../../constants";
import {addTableTextFilters, authHeader} from "../../Utilities";
import { EditOutlined } from '@ant-design/icons';
import UsersService from "../../services/UsersService";

const { Title } = Typography;

// class NewWorkspaceModal extends React.Component {
//   state = {
//     name: '',
//   }
//
//   handleOK = () => {
//     axios.post(
//         API_URL + `/workspaces`,
//         { name: this.state.name },
//         {headers: authHeader()},
//     ).then(result => {
//       this.props.handleRefresh();
//       this.props.handleHide();
//     }).catch(err => {
//       message.error('Failed to create workspace');
//     });
//   }
//
//   handleCancel = () => {
//     this.props.handleHide();
//   }
//
//   render() {
//     return (
//         <>
//           <Modal
//               title="New workspace"
//               visible={this.props.visible}
//               onOk={this.handleOK}
//               onCancel={this.handleCancel}
//           >
//             <Form.Item
//                 label="Name"
//                 name="name"
//                 rules={[{ required: true, message: 'Please input the workspace name' }]}
//             >
//               <Input value={this.state.name} onChange={(e) => this.setState({name: e.target.value})}/>
//             </Form.Item>
//           </Modal>
//         </>
//     )
//   }
// }

export class UsersList extends React.Component {
  state = {
    usersList: [],
    newWorkspaceModalVisible: false,
    hoveredRowIndex: null,
  }

  loadUsers = () => {
    UsersService.fetchUsers().then(result => {
      const usersList = result.data;
      this.setState({
        usersList: usersList,
      });
    });
  }

  hideModal = () => {
    this.setState({newWorkspaceModalVisible: false});
  }

  showModal = () => {
    this.setState({newWorkspaceModalVisible: true});
  }

  constructor(props) {
    super(props);

    addTableTextFilters(this);
  }

  componentDidMount() {
    this.loadUsers();
  }

  render() {
    const organizationsFilters = Object.entries(
        Object.fromEntries(this.state.usersList.map(u => u.organization).map(org => [org.id, org.name]))
    ).map(([orgId, orgName]) => ({ text: orgName, value: orgId })).sort((x, y) => x.text < y.text)

    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        ...this.getColumnSearchProps('name'),
      },
      {
        title: 'Email',
        dataIndex: 'email',
        ...this.getColumnSearchProps('email'),
      },
      {
        title: 'Organization',
        dataIndex: 'organization',
        filters: organizationsFilters,
        onFilter: (value, record) => value === record.organization.id,
        render: match => {
          return match.name;
        }
      },
      {
        title: 'Roles',
        dataIndex: 'roles',
        filters: [
          {
            text: 'User',
            value: 'user',
          },
          {
            text: 'Manager',
            value: 'manager',
          },
          {
            text: 'Analyst',
            value: 'analyst',
          },
          {
            text: 'Admin',
            value: 'admin',
          },
        ],
        onFilter: (value, record) => record.roles.indexOf(value) !== -1,
        render: (roles) => {
          return (
              <>
              {
                roles.map(role => (<Tag color={roleColors[role]}>{role}</Tag>))
              }
              </>
          )
        }
      },
      {
        title: '',
        dataIndex: 'id',
        width: '48px',
        render: (id, record, index) => {
          return (
              <>
                { index === this.state.hoveredRowIndex && (
                  <Link to={`${this.props.root}users/${id}/edit`}>
                    <EditOutlined />
                  </Link>
                )}
              </>
          )
        }
      }
    ];

    return (
        <>
          <Row style={{justifyContent: 'space-between', padding: '1em'}}>
            <Title level={4}>Users</Title>
            {!KEYCLOAK_ENABLED && (
                <Link to={`${this.props.root}users/new`}>
                  <Button type="primary" onClick={this.showModal}>New user</Button>
                </Link>
            )}
          </Row>
          <Table
              dataSource={this.state.usersList}
              columns={columns}
              // pagination={{showTotal: (total) => `Total users: ${total}`}}
              onRow={(record, rowIndex) => {
                return {
                  onMouseEnter: event => this.setState({hoveredRowIndex: rowIndex}),
                  onMouseLeave: event => this.setState({hoveredRowIndex: null}),
                }
              }}
          />
          {/*<NewWorkspaceModal*/}
          {/*    visible={this.state.newWorkspaceModalVisible}*/}
          {/*    handleHide={this.hideModal}*/}
          {/*    handleRefresh={this.loadWorkspaces}*/}
          {/*/>*/}
        </>
    );
  }
}
