import React, {useEffect, useState} from "react";
import {Col, Row, Space, Table} from "antd";
import axios from "axios";
import {API_URL} from "../../../../constants";
import {authHeader, ColoredColumns} from "../../../../Utilities";
import {RemoteCnDrawing} from "../Model/Remote/RemoteCnDrawing";
import {useHistory} from "react-router";
import {DataSourceFilterApplier, DataSourceFiltersEditor, ExactMatchItemFilter} from "../../../Misc/DataSourceFilter";

class DrawingVersionFilter extends ExactMatchItemFilter {
    constructor(value) {
        super(
            value,
            el => el.toColumnData().same_page_version ? "Same page" : "Another page",
            "Version"
        );
    }
}

export function SelectDrawingsToCompare({currentCnDrawing, onCompareClick}) {
    const history = useHistory();
    const [dataSource, setDataSource] = useState([]);

    const [filters, setFilters] = useState([new DrawingVersionFilter("Same page")]);
    const [filterSchemas, setFilterSchemas] = useState([]);

    const filtersRenderer = (tagsList, editor) => (
        <Row justify={"space-between"} style={{marginBottom: 8}}>
            <Col span={15}>
                {tagsList}
            </Col>
            <Col>
                {editor}
            </Col>
        </Row>
    );

    useEffect(() => {
        axios.post(API_URL + `/user_view/drawings_to_compare`,
            {result_reference: currentCnDrawing.toResultReference()},
            {headers: authHeader()})
            .then(res => {
                const cnDrawings = res.data.map(el => new RemoteCnDrawing(el, history))
                    .filter(el => el.id() !== currentCnDrawing.id());

                setDataSource(cnDrawings);

                const schemas = [
                    {
                        type: "attribute",
                        attribute: "File name",
                        allowedValues: cnDrawings.map(el => el.toColumnData().file_name),
                        constructor(value) {
                            return new ExactMatchItemFilter(value, el => el.toColumnData().file_name, "File name");
                        }
                    },
                    {
                        type: "attribute",
                        attribute: "Version",
                        allowedValues: cnDrawings.filter(el => el.toColumnData().same_page_version).map(el => 'Same page'),
                        constructor(value) {
                            return new DrawingVersionFilter(value);
                        }
                    }
                ];

                setFilterSchemas(schemas);
            });
    }, []);

    const columns = [
        {
            title: 'File name',
            render: (text, record) => record.toColumnData().file_name
        },
        {
            title: 'Run',
            render: (text, record) => record.toColumnData().run_name
        },
        {
            title: 'Page',
            render: (text, record) => record.toColumnData().page_number
        },
        {
            title: 'Title',
            render: (text, record) => record.toColumnData().title
        },
        {
            title: 'Actions',
            render: (text, record) => {
                return (
                    <Space>
                        <a onClick={e => record.open()}>Open</a>
                        <a onClick={e => onCompareClick(record)}>Select</a>
                    </Space>
                );
            }
        }
    ];

    const coloredColumns = new ColoredColumns(columns,
        (text, record, idx) => record.id() === currentCnDrawing.id() ? '#eee' : ''
    );

    return (
        <>
            <DataSourceFiltersEditor
                itemFilters={filters}
                onItemFiltersChanged={f => setFilters([...f])}
                filterSchemas={filterSchemas}
                contentRenderer={filtersRenderer}
            />
            <DataSourceFilterApplier
                itemFilters={filters}
                dataSource={dataSource}
                dataSourceRenderer={
                    (filteredDataSource) => (
                        <Table
                            id="drawings-to-compare-table"
                            scroll={{x: 400}}
                            size="small"
                            dataSource={filteredDataSource}
                            columns={coloredColumns.values()}
                        />
                    )
                }
            />
        </>
    );
}