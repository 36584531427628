import React from 'react'
import { Breadcrumb } from 'antd'

export class PathBreadcrumbs extends React.Component {
  constructor(props) {
    super(props);

    this.handleBreadcrumb = this.handleBreadcrumb.bind(this);
  }

  handleBreadcrumb(value) {
    if (this.props.onBreadcrumbClick) {
      this.props.onBreadcrumbClick(value);
    }
  }

  render() {
    const { path } = this.props;
    const foldersNames = (path || "").split('/').filter(s => s);

    const folderPaths = [];
    let prevValue = '';

    for (let i = 0; i < foldersNames.length; i++) {
      if (prevValue === '')
        prevValue = foldersNames[i]
      else
        prevValue = prevValue + '/' + foldersNames[i]

      folderPaths.push(prevValue)
    }

    folderPaths.unshift("");
    foldersNames.unshift("Root");

    const breadcrumbs = folderPaths.map((value, index) => {
      const style = { fontSize: "150%" }
      if (index === folderPaths.length - 1) {
        return (
          <Breadcrumb.Item key={value}>
            <a style={style}>{foldersNames[index]}</a>
          </Breadcrumb.Item>
        );
      }

      return (
        <Breadcrumb.Item key={value} onClick={() => this.handleBreadcrumb(value)}>
          <a style={style}>{foldersNames[index]}</a>
        </Breadcrumb.Item>
      );
    });

    return (
      <Breadcrumb>{breadcrumbs}</Breadcrumb>
    )
  }
}