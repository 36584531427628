import React from "react";
import {ObjectTextLabel} from "../../../Misc/ObjectTextLabel";
import {SimpleMgTableRow} from "./SimpleMgTableRow";
import {Tag} from "antd";

export class DefaultMgTableRow extends SimpleMgTableRow {
    constructor(text, label, status, mgStatusName, actions) {
        super(
            () => <ObjectTextLabel text={text} label={label}/>,
            () => status.toTag(),
            () => <Tag>{mgStatusName}</Tag>,
            () => actions
        );
    }
}