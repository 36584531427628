export class ColoredCnDetail {
    constructor(data, colorMap) {
        this._data = data;

        // this._colorMap = colorMap || {
        //     MODIFIED: "#57eaef",
        //     REMOVED: "#ef5757",
        //     ADDED: "#cce8a3",
        //     SAME: ''
        // };
        //
        // this._colorMap = colorMap || {
        //     MODIFIED: "#91d5ff",
        //     REMOVED: "#ffa39e",
        //     ADDED: "#b7eb8f",
        //     SAME: ''
        // };
        //
        // this._colorMap = colorMap || {
        //     MODIFIED: "#1890ff",
        //     REMOVED: "#f5222d",
        //     ADDED: "#52c41a",
        //     SAME: ''
        // };

        this._colorMap = colorMap || {
            MODIFIED: "#e6f7ff",
            REMOVED: "#fff1f0",
            ADDED: "#f6ffed",
            SAME: ''
        };
    }

    color() {
        let result;

        if (this._data.another.absent) {
            result = this._colorMap.ADDED;
        } else if(this._data.current.absent) {
            result = this._colorMap.REMOVED;
        } else if (this._data.another.value !== this._data.current.value) {
            result = this._colorMap.MODIFIED;
        } else if (this._data.current.absent === this._data.another.absent
            && this._data.another.value === this._data.current.value) {
            result = this._colorMap.SAME;
        }

        return result;
    }
}