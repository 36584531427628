import Cookies from "universal-cookie";

class WorkspaceViewModeService {
  constructor() {
    this._cookies = new Cookies();
  }

  addChangeListener(callback) {
    const changeListener = (args) => {
      if (args.name === 'view_workspace_id') {
        callback(args.value);
      }
    };

    this._cookies.addChangeListener(changeListener);

    return {
      release: () => {
        this._cookies.removeChangeListener(changeListener);
      }
    };
  }

  enterWorkspace = (workspaceId) => {
    this._cookies.set('view_workspace_id', workspaceId, { path: '/' });
  }

  exitWorkspace = () => {
    this._cookies.remove('view_workspace_id', { path: '/' });
  }

  getCurrentWorkspaceId = () => {
    const currentWorkspaceId = this._cookies.get('view_workspace_id');
    return currentWorkspaceId;
  }

  isInViewingMode = () => {
    return !!this.getCurrentWorkspaceId();
  }
}

export default new WorkspaceViewModeService();