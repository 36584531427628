import thunkMiddleware from 'redux-thunk'
import { createBrowserHistory } from 'history'
import { createStore, applyMiddleware, compose } from 'redux'
import createRootReducer from './reducers/reducers'
import { routerMiddleware } from 'connected-react-router'
import { createEpicMiddleware, combineEpics } from 'redux-observable'
import { fetchProjectsEpic, scheduleDocumentsProcessingEpic, fetchRunsEpic, refreshRunsListEpic, fetchRunResultsEpic, fetchDocumentAnnotationEpic } from './actions/actions'

export const history = createBrowserHistory()

const epicMiddleware = createEpicMiddleware();

export const rootEpic = combineEpics(
  fetchProjectsEpic,
  scheduleDocumentsProcessingEpic,
  fetchRunsEpic,
  refreshRunsListEpic,
  fetchRunResultsEpic,
  fetchDocumentAnnotationEpic,
);

export default function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    compose(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        epicMiddleware,
        thunkMiddleware
      ),
    ),
  )

  epicMiddleware.run(rootEpic)

  return store
}