import {CnResults} from "./CnResults";

export class CnIntent {
    switch(selectedCnResult) {

    }

    async cnResults() {
        return new CnResults();
    }

    prepared() {
        return false;
    }
}