import React, {useContext} from 'react';
import {Layout, Menu} from 'antd';
import KHHeader from '../KHHeader';
import {Link} from "react-router-dom";
import axios from "axios";
import {API_URL} from "../../constants";
import {AnimatedAuthUser, authHeader} from "../../Utilities";
import {AuthContext} from "../../contexts/AuthContext";
import {ViewWorkspaceSwitch} from "../Misc/ViewWorkspaceSelect";


function AdvancedSubMenu(props) {
  const authContext = useContext(AuthContext);
  const user = new AnimatedAuthUser(authContext.user);

  if (!user.canModifyCurrentWorkspaceReports()) return null;

  return (
      <Menu.SubMenu title="Advanced" {...props}>
        <Menu.Item key="configure_reports" {...props}>
          <Link to={`/user_view/configure_reports`}>
            Reports
          </Link>
        </Menu.Item>
      </Menu.SubMenu>
  );
}


function CommentsMenuItem(props) {
  const authContext = useContext(AuthContext);
  const user = new AnimatedAuthUser(authContext.user);

  if (!user.canSearchForComments()) return null;

  return (
      <Menu.Item key="comments" {...props}>
        <Link to={`/user_view/comments`}>
          Comments
        </Link>
      </Menu.Item>
  );
}

export class UserViewLayout extends React.Component {
  static contextType = AuthContext;

  render() {
    let selectedMenuItem
    switch (this.props.location.pathname.split('/')[2]) {
      case 'search':
        selectedMenuItem = 'search'
        break;
      case 'explore_results':
        selectedMenuItem = 'explore_results'
        break;
      case 'finalize_results':
        selectedMenuItem = 'finalize_results'
        break;
      case 'users':
        selectedMenuItem = 'users';
        break;
      case 'organizations':
        selectedMenuItem = 'organizations';
        break;
      case 'configure_reports':
        selectedMenuItem = 'configure_reports';
        break;
      case 'comments':
        selectedMenuItem = 'comments';
        break;
      default:
        selectedMenuItem = 'search'
    }

    const showExtraFeatures = this.context.user && this.context.user.roles.includes('manager');

    return (
        <Layout  style={{height:"100vh"}}>
          <KHHeader />
          <Layout>
            <Layout.Sider width={'20%'} theme='light'>
              <Menu id="main-menu" theme="light" mode="inline" selectedKeys={[selectedMenuItem]}>
                <Menu.Item key="search">
                  <Link to={`/user_view/search`}>
                    Search
                  </Link>
                </Menu.Item>
                <Menu.Item key="explore_results">
                  <Link to={`/user_view/explore_results`}>
                    Explore results
                  </Link>
                </Menu.Item>
                <Menu.Item key="finalize_results">
                  <Link to={`/user_view/finalize_results`}>
                    Finalized results
                  </Link>
                </Menu.Item>
                <CommentsMenuItem/>
                {showExtraFeatures &&
                <Menu.Item key="users">
                  <Link to={`/user_view/users`}>
                    Users
                  </Link>
                </Menu.Item>
                }
                {showExtraFeatures &&
                <Menu.Item key="organizations">
                  <Link to={`/user_view/organizations`}>
                    Organizations
                  </Link>
                </Menu.Item>
                }
                <AdvancedSubMenu/>
              </Menu>
              <div style={{position: 'absolute', bottom: 0, width: '100%', marginBottom: '20%'}}>
                <ViewWorkspaceSwitch
                    onChange={newId => {
                      this.props.history.replace({
                        pathname: '/',
                        search: ''
                      });
                    }}
                />
              </div>
            </Layout.Sider>

            <Layout.Content style={{width: '75%', padding: '24px'}}>
              {this.props.children}
            </Layout.Content>
          </Layout>
        </Layout>
    )
  }
}
