import React from "react";
import {Col, Row, Tag} from "antd";

export default class ParentChildSelector extends React.Component {
  render() {
    let childText;
    if (this.props.selectorState.child) {
      if (this.props.selectorState.child.targetType === 'object')
        childText = this.props.selectorState.child.target.objectMetadata.text;
      else
        childText = this.props.selectorState.child.target.text;
    }
    return (
      <Row>
        <Col span={24}>
          <Row>
            <Col span={4}>
              <Row>
                <span style={{marginLeft: 'auto', marginRight: '8px'}}>Parent:</span>
              </Row>
            </Col>
            <Col span={20}>
              {this.props.selectorState.parent &&
              <Tag color="orange" closable onClose={
                () => this.props.onChange({...this.props.selectorState, parent: null})
              }>{this.props.selectorState.parent.text}</Tag>
              }
            </Col>
          </Row>
          <Row>
            <Col span={4}>
              <Row>
                <span style={{marginLeft: 'auto', marginRight: '8px'}}>Child:</span>
              </Row>
            </Col>
             <Col span={20}>
               {this.props.selectorState.child && <Tag color="geekblue" closable onClose={
                 () => this.props.onChange({...this.props.selectorState, child: null})
                }
              >{childText}</Tag>}
             </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}