import {Popconfirm} from "antd";
import React, {useContext} from "react";
import {AuthContext} from "../../contexts/AuthContext";
import {AnimatedAuthUser} from "../../Utilities";

export function DeleteWorkspaceReportAction({onDelete}) {
    const authContext = useContext(AuthContext);
    const user = new AnimatedAuthUser(authContext.user);

    if (!user.isAdmin()) return null;

    return (
        <Popconfirm title="Are you sure to delete the column？"
                    onConfirm={onDelete}
        >
            <a>Delete</a>
        </Popconfirm>
    );
}