import React, { Component } from "react";

export const SearchContext = React.createContext();

const { Provider, Consumer } = SearchContext;

class SearchContextProvider extends Component {
  state = {
    searchQuery: '',
    searchHandler: null,
    //must be a string array
    autoCompleteOptions: [],
    searchHandlersSchemas: [],
    searchSettings: {
      useWildcards: false
    }
  };

  setAutoCompleteOptions = (options) => {
    this.setState({
      autoCompleteOptions: options
    })
  }

  setSearchQuery = (searchQuery) => {
    this.setState({
      searchQuery: searchQuery,
    })
  }

  setSearchHandler = (handler) => {
    this.setState({
      searchHandler: handler,
    })
  }

  setSearchSettings = (settings) => {
    this.setState({searchSettings: settings});
  }

  addSearchHandlerSchema = (schema) => {
    this.setState(prevState => ({searchHandlersSchemas: [...prevState.searchHandlersSchemas, schema]}));
  }

  removeSearchHandlerSchema = (schemaToRemove) => {
    this.setState(prevState => (
        {searchHandlersSchemas: prevState.searchHandlersSchemas.filter(schema => schema !== schemaToRemove)}
        ));
  }

  removeSearchHandlerSchemasByOwner = (schemaOwner) => {
    this.setState(prevState => (
        {searchHandlersSchemas: prevState.searchHandlersSchemas.filter(schema => schema.owner !== schemaOwner)}
        ));
  }

  render() {
    return <Provider value={{query: this.state.searchQuery, setQuery: this.setSearchQuery,
        handleQuery: this.state.searchHandler, setSearchHandler: this.setSearchHandler,
        autoCompleteOptions: this.state.autoCompleteOptions, setAutoCompleteOptions: this.setAutoCompleteOptions,
        addSearchHandlerSchema: this.addSearchHandlerSchema, searchHandlersSchemas: this.state.searchHandlersSchemas ,
        removeSearchHandlerSchema: this.removeSearchHandlerSchema,
        removeSearchHandlerSchemasByOwner: this.removeSearchHandlerSchemasByOwner,
        searchSettings: this.state.searchSettings, setSearchSettings: this.setSearchSettings
    }}
    >
      {this.props.children}
    </Provider>;
  }
}

export { SearchContextProvider, Consumer as SearchContextConsumer };
