import axios from "axios"
import {API_URL} from "../constants";
import {authHeader} from "../Utilities";

class LambdaFunctionsService {
  fetchLambdaFunctions(projectId) {
    return axios.get(API_URL + `/projects/${projectId}/lambda_functions`, {headers: authHeader()});
  }

  addLambdaFunction(projectId, lambdaFunction) {
    return axios.post(API_URL + `/projects/${projectId}/lambda_functions`, lambdaFunction, {headers: authHeader()});
  }

  updateLambdaFunction(projectId, lambdaFunction) {
    return axios.put(
        API_URL + `/projects/${projectId}/lambda_functions/${lambdaFunction.id}`,
        lambdaFunction,
        {headers: authHeader()}
    );
  }

  fetchLambdaFunctionById(projectId, lambdaFunctionId) {
    return axios.get(API_URL + `/projects/${projectId}/lambda_functions/${lambdaFunctionId}`, {headers: authHeader()});
  }

  deleteLambdaFunction(projectId, lambdaFunctionId) {
    return axios.delete(API_URL + `/projects/${projectId}/lambda_functions/${lambdaFunctionId}`, {headers: authHeader()});
  }
}

export default new LambdaFunctionsService();
