import React, {useEffect, useState} from "react";
import {Tag, Tooltip} from "antd";


export function TrimmedTag({text, maxLength=20, children, ...rest}) {
    const [showTooltip, setShowTooltip] = useState(false);

    useEffect(() => {
        setShowTooltip(text.length >= maxLength);
    }, [text, maxLength]);

    const tooltipRender = sourceText => (
        <Tooltip title={sourceText}>
            {children(`${sourceText.slice(0, maxLength)}...`)}
        </Tooltip>
    );

    const rawRender = sourceText => children(sourceText);

    const render = showTooltip ? tooltipRender : rawRender;

    return (
        <Tag {...rest}>
            {render(text)}
        </Tag>
    );
}