import {viewerConsts, viewerModes} from "../../constants";

export class HoveringHandler {
  constructor(imageViewer) {
    this.imageViewer = imageViewer;
  }

  registerEvents = () => {
    const canvas = this.imageViewer.canvas;
    canvas.on('mouse:over', e => {
      if (e.target !== null && (e.target.isProperObject || e.target.fromAnotherDrawing)) {
        e.target.set('fill', 'rgba(109,45,161,0.31)');
        this.imageViewer.renderAll$.next(1);
        this.imageViewer.visualChanged$.next(1);
      }
    });

    canvas.on('mouse:out', e => {
      if (e.target !== null && (e.target.isProperObject || e.target.fromAnotherDrawing)) {
        const rollbackColor = e.target.baseFillColor ?? 'rgba(0,0,0,0)';
        e.target.set('fill', rollbackColor);

        this.imageViewer.renderAll$.next(1);
        this.imageViewer.visualChanged$.next(1);
      }
    });
  }
}
