import {isObjectMatchFilters} from "../../../../Misc/DataSourceFilter";
import {CnResults} from "../CnResults";

export class SmartCnResults {
    constructor(cnResults) {
        this._cnResults = cnResults || new CnResults();
    }

    addToCanvas() {
        this._cnResults.values().forEach(el => el.toCnCanvasObject().addToCanvas());
    }

    removeFromCanvas() {
        this._cnResults.values().forEach(el => el.toCnCanvasObject().removeFromCanvas());
    }

    updateVisibility(imageViewer, canvasObj) {
        const selectionParams = imageViewer.state.selectionParams;
        const cnFilters = imageViewer.state.cnFilters || [];
        const extraVisibleObjectsIds = imageViewer.state.extraVisibleObjectsIds;
        const canvas = imageViewer.canvas;

        let isVisible = selectionParams.includes(canvasObj.objectMetadata.label) || canvasObj === canvas.getActiveObject()
            || extraVisibleObjectsIds.has(canvasObj.objectMetadata.id);

        const filters = cnFilters;

        const cnResult = this._cnResults.values()
            .find(el => el.relatedToCanvasObject(canvasObj));

        if (!cnResult) return;

        const isMatchedFilters = isObjectMatchFilters(cnResult, filters);

        isVisible = isVisible || isMatchedFilters;

        // if (canvasObj === canvas.getActiveObject()) {
        //   cnResult.toCnCanvasObject().select();
        // } else {
        //   cnResult.toCnCanvasObject().deselect();
        // }

        if (isVisible) {
            cnResult.toCnCanvasObject().show();
        } else {
            cnResult.toCnCanvasObject().hide();
        }

        if (isMatchedFilters) {
            cnResult.toCnCanvasObject().addHighlight();
        } else {
            cnResult.toCnCanvasObject().removeHighlight();
        }
    }
}