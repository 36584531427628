import React, {useEffect, useState} from "react";
import {ControlOutlined} from "@ant-design/icons";
import {Button, Checkbox, Menu, Popover, Row, Space, Tabs} from "antd";
import {JSONChangesDetector} from "../../../services/ImageViewerChangesDetectService";

function ColumnCheckbox({item, onChange, ...rest}) {
    const {name, readonly, checked} = {...item};

    return (
        <Menu.Item {...rest} disabled={readonly}
                   onClick={e => {
                       if (readonly) return;

                       onChange(!checked);
                   }}>
            <Row justify={"space-between"}>
                {name}
                <Checkbox checked={checked} disabled={readonly}/>
            </Row>
        </Menu.Item>
    );
}

function VisibleColumns({dataSource, onChange}) {
    return (
        <Menu id="custom-columns" selectable={false} style={{border: 'none'}}>
            {dataSource.map((el, idx) => (
                <>
                    <ColumnCheckbox item={el}
                                    id={`custom-column_${idx}`}
                                    onChange={val => {
                                        const updated = dataSource.map(prevItem => {
                                            if (prevItem === el) {
                                                return {
                                                    ...prevItem,
                                                    checked: val
                                                }
                                            }

                                            return prevItem;
                                        });

                                        onChange(updated);
                                    }}
                    />
                    {idx < dataSource.length - 1 && <Menu.Divider />}
                </>
            ))}
        </Menu>
    );
}

function SearchColumnsEditor({dataSource, onSave, onCancel}) {
    const [config, setConfig] = useState(dataSource);
    const [changesDetected, setChangesDetected] = useState(false);

    useEffect(() => {
        const changesDetector = new JSONChangesDetector();

        changesDetector.register(config);
        setChangesDetected(changesDetector.detect(dataSource));
    }, [config, setChangesDetected, dataSource]);

    return (
        <div style={{width: "288px"}}>
            <div style={{height: '200px', overflowY: 'scroll', overflowX: 'hidden'}}>
                <VisibleColumns dataSource={config.columns} onChange={items => {
                    setConfig({
                        ...config,
                        columns: items
                    });
                }}/>
            </div>
            <Row>
                {changesDetected ? <span style={{color: 'var(--color-yellow)'}}>Changes may be lost</span> : null}
                <Space style={{marginLeft: 'auto'}}>
                    <Button
                        id="customize-table-cancel-button"
                        size="small"
                        onClick={e => onCancel()}
                    >
                        Cancel
                    </Button>
                    <Button
                        id="customize-table-save-button"
                        type="primary" size="small"
                        onClick={e => onSave(config)}
                    >
                        Save
                    </Button>
                </Space>
            </Row>
        </div>
    );
}

function AdvancedSearchSettings({dataSource}) {
    return (
        <div style={{width: "288px"}}>
            <IgnoreTextObjectsEditor dataSource={dataSource}/>
        </div>
    );
}

function IgnoreTextObjectsEditor({dataSource}) {
    return (
        <Checkbox
            id="include-text-objects-checkbox"
            checked={!dataSource.ignoreTextObjects.data}
            onChange={e => {
                dataSource.ignoreTextObjects.onSave(!e.target.checked);
            }}
        >
            Include text objects
        </Checkbox>
    );
}

export function SearchTableSettings({dataSource}) {
    const [visible, setVisible] = useState(false);

    const getContent = () => {
        return (
            <Tabs defaultActiveKey={"columns"}>
                <Tabs.TabPane tab={"Show columns"} key={"columns"}>
                    <SearchColumnsEditor
                        dataSource={dataSource.customize.data}
                        onSave={data => {
                            setVisible(false);
                            dataSource.customize.onSave(data);
                        }}
                        onCancel={e => {
                            setVisible(false);
                        }}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab={"Advanced"} key={"advanced"}>
                    <AdvancedSearchSettings dataSource={dataSource.advanced}/>
                </Tabs.TabPane>
            </Tabs>
        );
    }

    return (
        <Popover
            trigger="click"
            visible={visible}
            content={getContent}
            placement="bottomLeft"
            destroyTooltipOnHide={true}
            onVisibleChange={v => setVisible(v)}
        >
            <Button id="customize-table-button"
                    onClick={e => setVisible(true)}
                    size="small"
                    icon={<ControlOutlined/>}
            />
        </Popover>
    );
}
