import Point from "./Point";

export default class Rectangle {
  constructor(x1, y1, x2, y2) {
    this.x1 = x1;
    this.y1 = y1;
    this.x2 = x2;
    this.y2 = y2;
  }

  getCenter() {
    return new Point((this.x1 + this.x2) * 0.5, (this.y1 + this.y2) * 0.5);
  }

  containsPoint(point) {
    return (this.x1 <= point.x && point.x <= this.x2) && (this.y1 <= point.y && point.y <= this.y2);
  }

  getWidth() {
    return Math.max(0, this.x2 - this.x1);
  }

  getHeight() {
    return Math.max(0, this.y2 - this.y1);
  }
}
