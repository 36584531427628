class UserPreferencesService {
  setDrawingImageQuality(quality) {
    localStorage.setItem("image_quality", quality);
  }

  getDrawingImageQuality() {
    return localStorage.getItem('image_quality') || 'medium'
  }

  setThumbnailsSize(size) {
    localStorage.setItem('thumbnails_size', size);
  }

  getThumbnailsSize() {
    return parseInt(localStorage.getItem('thumbnails_size') ?? '2');
  }

  setMiniMapState(miniMapState) {
    localStorage.setItem('minimap_state', miniMapState)
  }

  getMiniMapState() {
    return localStorage.getItem('minimap_state') ?? 'shown'
  }
}

export default new UserPreferencesService();