import React from "react";
import {ImageViewerCanvasObject} from "../../ImageViewerHandlers/ImageViewerCanvasObject";

type ObjectDataContextType = {
    isEditable: boolean,
    selectedObject?: ImageViewerCanvasObject,
};

export const ObjectDataContext = React.createContext<ObjectDataContextType>(
    {} as ObjectDataContextType
);

type Props = {
    isEditable: boolean,
    selectedObject?: ImageViewerCanvasObject,
    children: React.ReactNode,
};

export function ObjectDataContextProvider(
    {children, isEditable, selectedObject}: Props
) {
    return (
        <ObjectDataContext.Provider
            children={children}
            value={{
                isEditable,
                selectedObject
            }}
        />
    );
}
