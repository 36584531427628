import { ImageViewerCanvasObject } from "../../../ImageViewerHandlers/ImageViewerCanvasObject";
import React, { useContext, useState } from "react";
import { useFetchWithLoading } from "../../../../hooks/useFetch";
import { message, Popconfirm, Spin, Table } from "antd";
import {
    CustomObjectAttribute,
    CustomObjectAttributes,
    CustomObjectAttributesSource,
    isAutocompleteAttribute,
    isSelectAttribute,
    isTextAttribute,
} from "./CustomAttributesSource";
import {
    EllipsisOutlined,
    EyeTwoTone,
    DeleteOutlined,
} from "@ant-design/icons";
import { EditableAutoCompleteField } from "../../../EditableFields/EditableAutoCompleteField";
import { EditableTextField } from "../../../EditableFields/EditableTextField";
import { EditableSelectField } from "../../../EditableFields/EditableSelectField";
import { CustomAttributesContext } from "./CustomAttributesContext";

export function CustomAttributesTable({
    selectedObject,
    source,
}: {
    selectedObject: ImageViewerCanvasObject;
    source: CustomObjectAttributesSource;
}) {
    const { isLoading, attributes, deleteAttribute, updateAttribute } =
        useContext(CustomAttributesContext);

    const [hoveredColIndex, setHoveredColIndex] = useState(-1);
    const [hoveredRowIndex, setHoveredRowIndex] = useState(-1);
    const setHoveredColIndexCallback = (index: number) => {
        setHoveredColIndex(index);
    };

    const tableCols = [
        {
            title: "attribute",
            dataIndex: "key",
            width: "30%",

            render: (
                text: string,
                record: CustomObjectAttribute,
                index: number
            ) => {
                const deleteIconShown =
                    record.canBeDeleted && index === hoveredColIndex;
                return {
                    props: {
                        style: { background: "WhiteSmoke" },
                    },
                    children: (
                        <div
                            style={{
                                width: "auto",
                                height: "100%",
                                margin: "-8px",
                                padding: "8px",
                            }}
                            onMouseEnter={() =>
                                setHoveredColIndexCallback(index)
                            }
                            onMouseLeave={() =>
                                setHoveredColIndexCallback(index)
                            }
                        >
                            {record.alias ?? record.key}
                            <Popconfirm
                                placement="topLeft"
                                title={`Are you sure to delete attribute '${text}'?`}
                                onConfirm={() => deleteAttribute(record.key)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <a
                                    style={{
                                        visibility: deleteIconShown
                                            ? "initial"
                                            : "hidden",
                                    }}
                                >
                                    <DeleteOutlined />
                                </a>
                            </Popconfirm>
                        </div>
                    ),
                };
            },
        },
        {
            title: "value",
            dataIndex: "value",
            render: (
                value: string,
                record: CustomObjectAttribute,
                index: number
            ) => {
                if (isTextAttribute(record)) {
                    return (
                        <EditableTextField
                            value={value}
                            editIconShown={
                                !record.isReadonly && index === hoveredRowIndex
                            }
                            onUpdate={(newText: string) => {
                                updateAttribute(record.key, newText);
                            }}
                        />
                    );
                } else if (isSelectAttribute(record)) {
                    return (
                        <EditableSelectField
                            value={record.alias}
                            optionsList={record.options}
                            editIconShown={
                                !record.isReadonly && index === hoveredRowIndex
                            }
                            onUpdate={(newText: string) =>
                                updateAttribute(record.key, newText)
                            }
                        />
                    );
                } else if (isAutocompleteAttribute(record)) {
                    return (
                        <EditableAutoCompleteField
                            autoCompleteProps={record.options}
                            value={record.value}
                            optionsList={record.options}
                            editIconShown={
                                !record.isReadonly && index === hoveredRowIndex
                            }
                            onUpdate={(newText: string) =>
                                updateAttribute(record.key, newText)
                            }
                        />
                    );
                } else {
                    throw new Error(`Attribute type not implemented`);
                }
            },
        },
    ];

    return (
        <Spin spinning={isLoading}>
            <span style={{ color: "var(--color-yellow)" }}>
                Object from another drawing
            </span>
            <br />
            <Table
                id="object-attributes"
                dataSource={attributes}
                columns={tableCols}
                size="small"
                pagination={false}
                showHeader={false}
                bordered={true}
                onRow={(record, rowIndex) => {
                    return {
                        onMouseEnter: (event) => {
                            setHoveredRowIndex(rowIndex || -1);
                        },
                        onMouseLeave: (event) => {
                            setHoveredRowIndex(-1);
                        },
                    };
                }}
            />
        </Spin>
    );
}
