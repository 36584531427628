import React, {useCallback, useContext, useEffect, useState} from "react";
import HierarchiesService from "../../../services/HierarchiesService";
import CacheService from "../../../services/CacheService";
import {Button, Form, Input, message, Modal, Popconfirm, Row, Space, Table} from 'antd';
import {useParams} from "react-router";
import {AuthContext} from "../../../contexts/AuthContext";

export function HierarchiesList({onView}) {
  const [hierarchiesList, setHierarchiesList] = useState([]);
  const [modalShown, setModalShown] = useState(false);
  const auth = useContext(AuthContext)

  const params = useParams();

  const loadHierarchies = useCallback(() => {
    CacheService.getProjectData(params.projectId).then(projectData => {
      return HierarchiesService.fetchHierarchies(projectData.workspace_id).then(res => {
        setHierarchiesList(res.data);
      })
    }).catch(() => message.error('Failed to load hierarchies list'));
  }, [params]);

  const handleShowModal = useCallback(() => {
    setModalShown(true);
  }, []);

  const handleHideModal = useCallback(() => {
    setModalShown(false);
  }, []);

  const handleCreateNewHierarchy = useCallback(values => {
    CacheService.getProjectData(params.projectId).then(projectData => {
      const newHierarchy = {
        name: values.name,
        workspace_id: projectData.workspace_id,
      };
      HierarchiesService.addHierarchy(newHierarchy).then(res => {
        message.success('Created new hierarchy');
        handleHideModal();
        onView(res.data.id);
      }).catch(() => {
        message.error('Failed to create the new hierarchy');
      });
    });
  }, [handleHideModal, params, onView]);

  const handleDeleteHierarchy = useCallback(hierarchyId => {
    HierarchiesService.deleteHierarchy(hierarchyId).then(() => {
      message.success('Hierarchy removed');
      loadHierarchies();
    }).catch(() => {
      message.error('Failed to remove the hierarchy');
    })
  }, [loadHierarchies]);

  useEffect(() => {
    loadHierarchies();
  }, [loadHierarchies]);

  const isAdmin = auth.user && auth.user.roles.includes('admin');
  const hierarchiesTableColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      render: id => {
        return (
          <React.Fragment>
            <Space>
              <a onClick={() => onView(id)}>Open</a>
              {isAdmin &&
                <Popconfirm
                  title="Are you sure to delete the hierarchy？"
                  onConfirm={() => handleDeleteHierarchy(id)}>
                    <a>Delete</a>
                </Popconfirm>
              }
            </Space>
          </React.Fragment>
        )
      }
    }
  ];

  const formLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };


  return (
    <React.Fragment>
      <Row>
        <Button onClick={handleShowModal} type="primary" style={{marginLeft: 'auto'}}>New hierarchy</Button>
      </Row>
      <Table id="hierarchies-list-table" dataSource={hierarchiesList} columns={hierarchiesTableColumns} />


      <Modal
          title="Create new hierarchy"
          visible={modalShown}
          onCancel={handleHideModal}
          footer={null}
          // width="40vw"
          bodyStyle={{height: 'auto'}}
      >
        <Form {...formLayout} name="create-new-hierarchy" onFinish={handleCreateNewHierarchy}>
          <Form.Item name={'name'} label="Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Row>
            <Button type="primary" htmlType="submit" style={{marginLeft: 'auto'}}>
              Create
            </Button>
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  )
}
