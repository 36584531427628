import React from 'react';
import { ExploreTags } from '../components/ExploreTags';
import { API_URL } from '../constants'
import axios from 'axios'
import { authHeader, download } from "../Utilities";
import * as queryString from 'query-string'
import { Select, Space, Button, message } from 'antd';
import TagUrlLinkService from "../services/TagUrlLinkService";

const { Option } = Select;

class ExploreTagsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      runs: [],
      runId: null,
    }
  }

  componentDidMount() {
    this.loadRuns();
  }

  loadRuns = () => {
    this.setState({
      isRunsLoading: true,
    })
    axios.get(`${API_URL}/projects/${this.props.match.params.projectId}/processing_runs`, { headers: authHeader() })
      .then(response => {
        const runs = response.data.processing_runs;
        this.setState({
          runs: runs,
          isRunsLoading: false,
        })
      })
  }

  loadTags = (page, limit, searchColumns, sortColumns) => {
    const search = Object.entries(searchColumns).map(e => {
      const [column, value] = e;
      return `${column},${value}`;
    });

    const sort = sortColumns.map(e => {
      const [column, value] = e;
      return `${column},${value}`;
    });

    const params = {
      page,
      limit: limit + 1,
      search,
      sort
    };

    this.setState({
      isTagsLoading: true,
      search,
    })

    axios.get(`${API_URL}/projects/${this.props.match.params.projectId}/runs/${this.state.runId}/tags`, {
      params,
      headers: authHeader(),
      paramsSerializer: params => queryString.stringify(params),
    })
      .then(response => {
        const processing_results = response.data.processing_results;
        const hasMore = processing_results.length > limit;
        this.setState({
          processing_results: processing_results.slice(0, limit),
          hasMore,
          isTagsLoading: false,
        })
      })
  }

  exportTagsToExcel = () => {
    const params = {
      search: this.state.search,
    };

    axios.get(`${API_URL}/projects/${this.props.match.params.projectId}/runs/${this.state.runId}/tags/excel`,
      {
        params,
        headers: authHeader(),
        paramsSerializer: params => queryString.stringify(params),
      }).then(response => {
        const taskId = response.data.task_id;
        message.info('Tags export started')
        const getProgress = () => {
          axios.get(`${API_URL}/projects/${this.props.match.params.projectId}/runs/${this.state.runId}/tags/excel/progress/${taskId}`, { headers: authHeader() })
            .then(response => {
              const progress = response.data;

              if (progress == null || (!progress.error && progress.completed !== true)) {
                setTimeout(() => getProgress(), 1000);
              }
              else {
                axios.get(`${API_URL}/projects/${this.props.match.params.projectId}/runs/${this.state.runId}/tags/excel/download/${taskId}`, {
                  headers: authHeader(),
                  responseType: 'blob'
                }).then((response) => {
                  const blob = new window.Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                  download(blob, "tags.xlsx");
                })
              }
            })
        }

        getProgress();
      })
  }

  createTagLink = (runId, fileId, tagNumber, tagId) => {
    tagNumber = tagNumber ? tagNumber : "";
    tagId = tagId.toString();

    return TagUrlLinkService.generateRunTagLink(
        this.props.match.params.projectId,
        fileId,
        runId,
        tagNumber,
        tagId
    )
  }

  handleChange = (value) => {
    this.setState({
      runId: value,
    })
  }

  render() {
    const options = this.state.runs.map(run => {
      return <Option key={run.id} value={run.id}>{run.name}</Option>
    });

    return (
      <>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Select
            placeholder="Select run"
            style={{ width: 300 }}
            loading={this.state.isRunsLoading}
            disabled={this.state.isTagsLoading}
            onChange={this.handleChange}
          >
            {options}
          </Select>

          {
            this.state.runId &&
            <>
              <Button type="primary" onClick={() => this.exportTagsToExcel()}>Export to excel</Button>
              <ExploreTags
                projectId={this.props.match.params.projectId}
                key={this.state.runId}
                data={this.state.processing_results}
                hasMore={this.state.hasMore}
                loading={this.state.isTagsLoading}
                loadTags={this.loadTags}
                createTagLink={this.createTagLink}
              />
            </>
          }
        </Space>
      </>
    );
  }
}

export default ExploreTagsContainer
