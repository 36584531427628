import {MgTableRow} from "./MgTableRow";

export class SimpleMgTableRow extends MgTableRow {
    constructor(objectRender, statusRender, mgStatusRender, actionsRender) {
        super();
        this._objectRender = objectRender;
        this._statusRender = statusRender;
        this._actionsRender = actionsRender;
        this._mgStatusRender = mgStatusRender;
    }

    object() {
        return this._objectRender();
    }

    status() {
        return this._statusRender();
    }

    mgStatus() {
        return this._mgStatusRender();
    }

    actions() {
        return this._actionsRender();
    }
}