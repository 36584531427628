import React, {useState} from "react";
import {useParams} from "react-router";
import {useFetch} from "../../../../hooks/useFetch";
import CacheService from "../../../../services/CacheService";
import HierarchiesService from "../../../../services/HierarchiesService";
import {message, Select} from "antd";
import {SelectProps} from "antd/lib/select";
import {CustomObjectAttributesSource} from "./CustomAttributesSource";
import ImageViewer from "../../ImageViewer";
import {NodeAttributesSource} from "./Sources/NodeAttributesSource";
import {memoized} from "../../../../UtilitiesTs";

interface OptionType {
    label: string;
    value: string;
    source: CustomObjectAttributesSource | null;
}

interface ObjectDataSourceSelectProps {
    iv: ImageViewer,
    onChange: (source: CustomObjectAttributesSource | null) => void;
}

const defaultOption: OptionType = {label: "Current Drawing", value: "", source: null};

export function CustomAttributesSourceSelect({iv, onChange}: ObjectDataSourceSelectProps) {
    const [options, setOptions] = useState<OptionType[]>([]);
    const params: any = useParams();

    useFetch(
        () =>
            CacheService.getProjectData(params.projectId).then(projectData => {
                return HierarchiesService.fetchHierarchies(projectData.workspace_id).then(res => {
                    const hierarchies = res.data.map((hierarchy) => ({
                        label: hierarchy.name,
                        value: hierarchy.id,
                        source: new NodeAttributesSource(
                            iv,
                            hierarchy.id,
                            memoized(() => HierarchiesService.fetchSystemAttributes(hierarchy.id))
                        ),
                    }));
                    return [defaultOption, ...hierarchies];
                });
            }),
        setOptions,
        () => message.error("Failed to load hierarchies list"),
        [params.projectId]
    );

    const handleSelectChange = (_: any, option: OptionType) => {
        onChange(option.source);
    };

    return (
        <Select
            style={{minWidth: '128px'}}
            size={"small"}
            onChange={handleSelectChange as SelectProps<OptionType | String>["onChange"]}
            options={options}
            defaultValue={defaultOption.value}
        />
    );
}
