import {empty} from "../../../Utilities";

export class NewMarkers {
    constructor(imageViewer) {
        this._imageViewer = imageViewer;

        this._current = {abort: empty, unsubscribe: empty};
    }

    create(onFinish) {
        this._current.abort();
        this._current.unsubscribe();

        const subscriptions = [
            this._imageViewer.handlers.newCommentMarkerHandler.creatingNewMarkerFinished$.subscribe(onFinish)
        ];

        this._imageViewer.handlers.newCommentMarkerHandler.handleStart();

        const created = {
            unsubscribe: () => {
                subscriptions.forEach(x => x.unsubscribe());
            },
            abort: () => {
                this._imageViewer.handlers.newCommentMarkerHandler.handleAbort();
            }
        };

        this._current = created;

        return created;
    }
}
