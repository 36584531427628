import React from "react";
import {Button, Form, message, Modal, Select, Spin, Tooltip} from "antd";
import { DownloadOutlined } from '@ant-design/icons';
import axios from "axios";
import {API_URL} from "../../../constants";
import {authHeader, download} from "../../../Utilities";


export default class ExportHierarchy extends React.Component {
  state = {
    isModalShown: false,
    isLoading: false,
    statusMessage: '',
    reportsList: [],
  }

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.loadAvailableReports();
  }

  handleExportHierarchy = () => {
    this.setState({
      isLoading: true,
      statusMessage: 'Preparing hierarchy: 0%',
    })
    axios.post(`${API_URL}/hierarchies/export_hierarchy`, {
          hierarchy_id: this.props.hierarchyId,
          columns_config_id: this.formRef.current.getFieldValue('export_mode'),
        },{ headers: authHeader() }
    ).then(response => {
      const taskId = response.data.task_id;
      message.info('Hierarchy export started')
      const getProgress = () => {
        axios.get(`${API_URL}/hierarchies/export_hierarchy/progress/${taskId}`, { headers: authHeader() })
            .then(response => {
              const progress = response.data;

              if (progress == null || (!progress.error && progress.completed !== true)) {
                this.setState({
                  statusMessage: `Preparing hierarchy: ${progress?.progress || 0}%`,
                });
                setTimeout(() => getProgress(), 2000);
              }
              else {
                this.setState({statusMessage: 'Download started'});
                axios.get(`${API_URL}/hierarchies/export_hierarchy/download/${taskId}`, {
                  headers: authHeader(),
                  responseType: 'blob'
                }).then((response) => {
                  const blob = new window.Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                  download(blob, "exported_hierarchy.xlsx");
                  this.setState({statusMessage: 'Finished', isLoading: false});
                }).catch(() => {
                  message.error("Failed to download hierarchy");
                })
              }
            })
      }

      getProgress();
    });
  }

  loadAvailableReports = () => {
    axios.get(`${API_URL}/hierarchies/export_hierarchy/reports`, {
      headers: authHeader(),
    }).then(res => {
      this.setState({reportsList: res.data});
    }).catch(() => message.error('Failed to load reports list'))
  }

  showModal = () => {
    this.setState({
      isModalShown: true,
      isLoading: false,
      statusMessage: ''
    });
  }

  hideModal = () => {
    this.setState({isModalShown: false});
  }

  render() {
    const renderer = (id, onClick) => {
      return (
          <a id={id} onClick={onClick}>
            <Tooltip title="Export hierarchy to excel">
              <DownloadOutlined style={{fontSize: '24px'}} />
            </Tooltip>
          </a>
      )
    }

    return (
        <>
          {renderer("export-hierarchy-to-excel", this.showModal)}
          <Modal
              title="Export hierarchy"
              visible={this.state.isModalShown}
              footer={null}
              onCancel={this.hideModal}
          >
            <h2>{this.state.statusMessage} <Spin spinning={this.state.isLoading} style={{marginLeft: '16px'}}/></h2>
            <Form ref={this.formRef} initialValues={{ export_mode: null }}>
              <Form.Item label="Mode" name="export_mode">
                <Select id="modeSelect">
                  {
                    this.state.reportsList.map(rep => (<Select.Option value={rep.id}>{rep.name}</Select.Option>))
                  }
                </Select>
              </Form.Item>
              <Form.Item>
                <Button type="primary" onClick={this.handleExportHierarchy} style={{float: 'right'}}>Export hierarchy</Button>
              </Form.Item>
            </Form>
          </Modal>
        </>
    )
  }
}
