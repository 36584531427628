import React from 'react';
import AuthService from "../../services/AuthService";
import WorkspaceViewModeService from "../../services/WorkspaceViewModeService";

export class Logout extends React.Component {
    componentDidMount() {
        AuthService.logout();
        this.props.history.push('/');
        window.location.reload();
        WorkspaceViewModeService.exitWorkspace();
    }

    render() {
        return null;
    }
}