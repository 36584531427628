import {fabric} from "fabric";
import {combineLatest} from "rxjs";
import {ColorHighlighter} from "./Markup/Highlighters";

export default class ParentChildIconsHandler {
  constructor(imageViewer, hierarchyView) {
    this.imageViewer = imageViewer;
    this.hierarchyView = hierarchyView;
    this.canvasObjects = [];
  }

  shouldShowChildIcon(obj) {
    if (!this.hierarchyView.state.hierarchy) return false;

    const selectorState = this.hierarchyView.state.parentChildSelectorState;

    // if object is not tagged
    if (!obj.isValidForHierarchy()) return false;

    // same object is already selected as child
    if (selectorState.child && selectorState.child.targetType === 'object' && selectorState.child.target === obj.canvasObject) {
      return false;
    }

    // currently selected parent is already the parent of the current node
    if (selectorState.parent) {
      const currentNode = this.hierarchyView.state.hierarchy.findNodeByObject(obj);
      if (currentNode === selectorState.parent) return false;
      if (currentNode && currentNode.getParent() === selectorState.parent) {
        return false;
      }
    }

    return true;
  }

  getChildIconCanvasObjects(obj, verticalShift) {
    const objRect = obj.getRect();
    const circleObj = new fabric.Circle({
      left: objRect.x1 - 20, top: objRect.y1 + 20 + verticalShift, originX: 'center', originY: 'center',
      strokeWidth: 2, radius: 12, fill: 'rgb(56,119,255)', stroke: 'rgb(34,78,170)',
      // selectable: false,
      hoverCursor: 'pointer',
    });

    const fontSize = 15;
    const textColor = 'rgba(255, 255, 255, 1)';
    // const textColor = 'rgba(255, 0, 0, 1)';

    const textObj = new fabric.Text('C', {
      originX: 'center', originY: 'center',
      left: circleObj.left,
      top: circleObj.top,
      fontSize: fontSize,
      fontFamily: 'Calibri, sans-serif',
      fontStyle: 'bold',
      selectable: false,
      evented: false,
      fill: textColor,
    })

    circleObj.on('mousedown', () => {
      this.hierarchyView.handleSetChild({
        target: obj.canvasObject,
        targetType: 'object',
      });
    });

    return [circleObj, textObj];
  }

  shouldShowParentIcon(obj) {
    if (!this.hierarchyView.state.hierarchy) return false;
    const selectorState = this.hierarchyView.state.parentChildSelectorState;

    const currentNode = this.hierarchyView.state.hierarchy.findNodeByObject(obj);

    if (!currentNode) return false;

    if (selectorState.parent && currentNode === selectorState.parent) return false;

    if (selectorState.child) {
      if (selectorState.child.targetType === 'object') {
        // current object is already selected as child
        if (selectorState.child.target === obj.canvasObject) return false;

        // current object is already the parent of the currently selected child
        const childObj = this.imageViewer.canvasObjectToObject(selectorState.child.target);
        const childNode = this.hierarchyView.state.hierarchy.findNodeByObject(childObj);
        if (childNode && childNode.getParent() === currentNode) return false;
      }
    }

    return true;
  }

  getParentIconCanvasObjects(obj, verticalShift) {
    const objRect = obj.getRect();
    const circleObj = new fabric.Circle({
      left: objRect.x1 - 20, top: objRect.y1 + 20 + verticalShift, originX: 'center', originY: 'center',
      strokeWidth: 2, radius: 12, fill: 'rgb(255,189,56)', stroke: 'rgb(170,129,34)',
      // selectable: false,
      hoverCursor: 'pointer',
    });

    const fontSize = 15;
    const textColor = 'rgba(255, 255, 255, 1)';
    // const textColor = 'rgba(255, 0, 0, 1)';

    const textObj = new fabric.Text('P', {
      originX: 'center', originY: 'center',
      left: circleObj.left,
      top: circleObj.top,
      fontSize: fontSize,
      fontFamily: 'Calibri, sans-serif',
      fontStyle: 'bold',
      selectable: false,
      evented: false,
      fill: textColor,
    })

    circleObj.on('mousedown', () => {
      const currentNode = this.hierarchyView.state.hierarchy.findNodeByObject(obj);
      if (currentNode) {
        this.hierarchyView.handleSetParent(currentNode);
      }
    });

    return [circleObj, textObj];
  }

  getParentHighlighting() {
    const selectorState = this.hierarchyView.state.parentChildSelectorState;

    if (selectorState.parent) {
      const curObject = selectorState.parent.findReferencedObject();
      if (curObject && !curObject.isGraphObject()) {
        const orangeHighlighter = new ColorHighlighter('rgba(231,157,28,0.7)');
        return orangeHighlighter.getCanvasObjects(curObject);
      }
    }

    return [];
  }

  getChildHighlighting() {
    const selectorState = this.hierarchyView.state.parentChildSelectorState;

    if (selectorState.child) {
      let curObject = null;
      if (selectorState.child.targetType === 'object') {
        curObject = this.imageViewer.canvasObjectToObject(selectorState.child.target);
      } else if (selectorState.child.targetType === 'node') {
        curObject = selectorState.child.target.findReferencedObject();
      }
      if (curObject && !curObject.isGraphObject()) {
        const blueHighlighter = new ColorHighlighter('rgba(28,65,231,0.7)');
        return blueHighlighter.getCanvasObjects(curObject);
      }
    }

    return [];
  }

  updateIconsFor(obj) {
    this.clearIcons();

    let verticalShift = 0;

    if (this.shouldShowChildIcon(obj)) {
      this.canvasObjects = [...this.canvasObjects, ...this.getChildIconCanvasObjects(obj, verticalShift)];
      verticalShift += 40;
    }

    if (this.shouldShowParentIcon(obj)) {
      this.canvasObjects = [...this.canvasObjects, ...this.getParentIconCanvasObjects(obj, verticalShift)];
      verticalShift += 40;
    }

    this.canvasObjects.push(...this.getParentHighlighting());
    this.canvasObjects.push(...this.getChildHighlighting());


    this.canvasObjects.forEach(canvasObject => this.imageViewer.canvas.add(canvasObject));
  }

  registerEvents = () => {
    this.hierarchyView.subscriptions.push(
        combineLatest([
            this.imageViewer.objectSelected$,
            this.imageViewer.allObjectsStateChanged$,
            this.hierarchyView.parentChildSelectorStateChanged$,
            this.hierarchyView.hierarchyUpdated$,
        ]).subscribe(([e, ..._]) => {
          if (!e.target) {
            this.clearIcons();
            return;
          }
          const obj = this.imageViewer.canvasObjectToObject(e.target);
          if (!obj) return;
          this.updateIconsFor(obj);
        })
    );
  }

  clearIcons = () => {
    this.canvasObjects.forEach(canvasObject => this.imageViewer.canvas.remove(canvasObject));
    this.canvasObjects = [];
  }
}