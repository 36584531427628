import WorkspaceViewModeService from "./WorkspaceViewModeService";
import UserViewService from "./UserViewService";

class SearchCustomizeService {

    async getConfig() {
        const curWsId = this._currentWorkspaceId();

        const currentConfig = this._configs().find(el => el.ws_id === curWsId) || {
            ws_id: curWsId,
            data: {
                columns: []
            }
        };

        const defaultReadonlyColumnNames = ['Match', 'File name', 'Page', 'Link'];

        const filterColumnsResponse = await UserViewService.fetchSearchFiltersConfigs();
        const allowedColumns = filterColumnsResponse.data
            .flatMap(el => el.search_filters)
            .filter(el => el.target === 'page_field')
            .map(el => ({
                name: el.key,
                target: "page_field",
                readonly: false,
                checked: false
            }))
            .map(wsCol => {
                const columnInStorage = currentConfig.data.columns
                    .find(storageCol => storageCol.name === wsCol.name && storageCol.target === wsCol.target)

                return columnInStorage || wsCol;
            })
            .filter(column => !(column.name === "Title" && column.target === "page_field"))
            .filter(column => !(defaultReadonlyColumnNames.includes(column.name) && column.target === "page_field"))
        ;

        const titleColumn = currentConfig.data.columns.find(el => el.name === "Title" && el.target === "page_field")
            || {
                name: "Title",
                target: "page_field",
                readonly: false,
                checked: true
            };

        return {
            columns: [
                ...[...allowedColumns, titleColumn].sort((a, b) => a.name.localeCompare(b.name)),
                ...defaultReadonlyColumnNames.map(el => ({
                    name: el,
                    target: "page_field",
                    readonly: true,
                    checked: true
                }))
            ]
        }
    }

    updateConfig(config) {
        const readonlyNames = ['Match', 'File name', 'Page', 'Link'];
        const filtered = {
            ...config,
            columns: config.columns.filter(el => !readonlyNames.includes(el.name))
        };

        const curWsId = this._currentWorkspaceId();
        const configs = this._configs().filter(el => el.ws_id !== curWsId);

        localStorage.setItem(
            'search_customize_configs',
            JSON.stringify([...configs, {ws_id: curWsId, data: filtered}])
        );
    }

    updateAdvancedConfig(advancedConfig) {
        localStorage.setItem(
            'search_customize_advanced_config',
            JSON.stringify(advancedConfig)
        )
    }

    getAdvancedConfig() {
        // default
        let advancedConfig = {
            include_text_objects: false,
        }
        const advancedConfigJson = localStorage.getItem('search_customize_advanced_config')
        if (advancedConfigJson) {
            advancedConfig = JSON.parse(advancedConfigJson)
        }
        return advancedConfig;
    }

    _configs() {
        return JSON.parse(localStorage.getItem('search_customize_configs') || '[]');
    }

    _currentWorkspaceId() {
        return WorkspaceViewModeService.getCurrentWorkspaceId() || '';
    }

}

export default new SearchCustomizeService();
