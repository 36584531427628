import HierarchyNode from "../../../ImageViewerHandlers/Hierarchy/HierarchyNode";

export interface CutPasteState {
    node?: HierarchyNode;
}

export function cutPasteStateCut(node: HierarchyNode): CutPasteState {
    return {
        node,
    }
}

export function cutPasteStateReset(): CutPasteState {
    return {
        node: undefined
    }
}

export function cutPasteStateIsNotEmpty(state: CutPasteState): boolean {
    return state.node != null;
}