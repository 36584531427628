import React, {useEffect, useState} from "react";
import {Button, Card, Checkbox, Col, DatePicker, Divider, message, Row, Space} from "antd";
import moment from 'moment';
import axios from "axios";
import {API_URL} from "../../constants";
import {authHeader, download} from "../../Utilities";

const { RangePicker } = DatePicker;

function loadStatusReportProcessingRuns(workspaceId) {
    return axios.get(
        API_URL + `/workspaces/${workspaceId}/status_report/processing_runs`,
        {headers: authHeader()}
    );
}

export function ConfigureStatusReport({workspaceId}) {
    const [processingRuns, setProcessingRuns] = useState([]);
    const [dateRange, setDateRange] = useState(null);

    useEffect(() => {
        loadStatusReportProcessingRuns(workspaceId).then(response => {
            setProcessingRuns(response.data.map(el => {
                return {...el, checked: false}
            }));
        });
    }, [workspaceId]);

    const handleDownload = () => {
        if (!dateRange) {
            message.error("Date range must be configured!");
            return;
        }

        axios.post(API_URL + `/workspaces/${workspaceId}/status_report/export_to_xlsx`,
            {
                start_date: dateRange[0].format(),
                end_date: dateRange[1].format(),
                selected_runs: processingRuns.filter(run => run.checked).map(run => {
                    return {
                        id: run.id,
                    }
                })
            },
            {
                headers: authHeader(),
            }).then(response => {
            const taskId = response.data.task_id;
            message.info('Status report export started')
            const getProgress = () => {
                axios.get(`${API_URL}/workspaces/${workspaceId}/status_report/export_to_xlsx/progress/${taskId}`, { headers: authHeader() })
                    .then(response => {
                        const progress = response.data;

                        if (progress == null || (!progress.error && progress.completed !== true)) {
                            setTimeout(() => getProgress(), 2000);
                        }
                        else {
                            axios.get(`${API_URL}/workspaces/${workspaceId}/status_report/export_to_xlsx/download/${taskId}`, {
                                headers: authHeader(),
                                responseType: 'blob'
                            }).then((response) => {
                                const blob = new window.Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                                download(blob, "status_report.xlsx");
                            }).catch(() => {
                                message.error("Failed to download status report");
                            })
                        }
                    })
            }

            getProgress();
        })
    }

    const onDateRangeChange = (dates, datesString) => {
        setDateRange(dates);

        setProcessingRuns(processingRuns.map(run => {
            if (!dates) {
                run.checked = false;
                return run;
            }

            const createdAtMoment = moment(run.created_at);

            run.checked = createdAtMoment.isBetween(dates[0], dates[1]);

            return run;
        }));
    }

    return (
        <Card title="Status report">
            <Row>
                <Space>
                    <RangePicker
                        ranges={{
                            'Last Month': [moment().subtract(1, 'months').startOf('month'),
                                moment().endOf('day').subtract(1, 'months').endOf('month')],
                            'This Month': [moment().startOf('month'), moment().endOf('day').endOf('month')],
                        }}
                        onChange={onDateRangeChange}
                    />
                    <Button id="download-status-report-button" type="primary" onClick={handleDownload}>Download</Button>
                </Space>
            </Row>
            <Divider/>
            <Row>
                <Card title="Runs list" size="small">
                    {processingRuns.map((run, idx) => (
                        <Col span={24}>
                            <Checkbox id={`run-checkbox_${idx}`}
                                onChange={e => {
                                    run.checked = e.target.checked;

                                    //update ref to cause rerender
                                    setProcessingRuns([...processingRuns]);
                                }}
                                checked={run.checked}
                            >
                                {run.name}
                            </Checkbox>
                        </Col>
                    ))}
                </Card>
            </Row>
        </Card>
    )
}