import {Dropdown, Menu} from "antd";
import Highlighter from "react-highlight-words";
import React from "react";
import {postprocessed, PostprocessedHighlightWords} from "../../Utilities";


export function AutoCompleteWithSearchOptions(
    {
        id,
        autoCompleteOptions,
        maxAutoCompleteOptionsNumber,
        minSearchQueryLengthToActivate,
        searchOptions,
        searchSettings = [],
        searchQuery,
        children,
        onAutoCompleteOptionClick,
        visible,
        onVisibleChange
    }
) {
    let autoCompleteItems = [];
    const searchQueryLowerCase = postprocessed(searchQuery);
    const highlightWords = new PostprocessedHighlightWords(searchQuery);

    if (searchQuery.length >= minSearchQueryLengthToActivate) {
        const filteredAutoCompleteOptions = autoCompleteOptions
            .filter((o) => postprocessed(o).includes(searchQueryLowerCase))
            .slice(0, maxAutoCompleteOptionsNumber);

        autoCompleteItems = filteredAutoCompleteOptions.map((optionText, idx) => {
            console.log(highlightWords.values(optionText));

            return (
                <Menu.Item
                    id={`auto-complete-option_${idx}`}
                    onClick={() => onAutoCompleteOptionClick(optionText)}
                >
                    <Highlighter
                        highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                        searchWords={highlightWords.values(optionText)}
                        autoEscape
                        textToHighlight={optionText}
                    />
                </Menu.Item>
            )
        })
    }

    const overlay = (
        <Menu>
            {autoCompleteItems}
            {autoCompleteItems.length > 0 ? <Menu.Divider/> : null}
            {searchOptions}
            <Menu.Divider/>
            <Menu.ItemGroup title="Advanced search settings">
                {searchSettings}
            </Menu.ItemGroup>
        </Menu>
    )

    return (
        <Dropdown
            visible={visible}
            overlay={overlay}
            trigger={['click']}
            mouseLeaveDelay={0.3}
            onVisibleChange={onVisibleChange}
        >
            {children}
        </Dropdown>
    )
}