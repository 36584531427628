import React from "react";
import {
  Tag,
  Button,
  Form,
  Input,
  Row,
  Typography,
  Card,
  Menu,
  Dropdown,
  message,
  Space,
  Select,
  Col,
  Popconfirm, Tooltip
} from 'antd'
import {Link} from "react-router-dom";
import { PlusOutlined } from '@ant-design/icons';
import {API_URL, KEYCLOAK_ENABLED, roleColors} from "../../constants";
import axios from "axios";
import {authHeader} from "../../Utilities";
import {PermissionsList} from "../Misc/PermissionsList";
import OrganizationsService from "../../services/OrganizationsService";
import AuthService from "../../services/AuthService";
import { DeleteOutlined } from '@ant-design/icons';
import UsersService from "../../services/UsersService";


const { Title } = Typography;
const { Option } = Select;


export class EditUser extends React.Component {
  state = {
    userRoles: [],
    permissionsList: [],
    userData: null,
    organizationsList: [],
    currentUser: null,
    selectedOrganizationId: null,
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  loadCurrentUser = () => {
    axios.get(API_URL + `/get_current_user`, {headers: authHeader()}).then(res => {
      this.setState({currentUser: res.data}, this.getOrganizationsList);
    }).catch(err => {
    })
  }

  getOrganizationsList = () => {
    if (this.state.currentUser.roles.includes('admin')) {
      OrganizationsService.fetchOrganizations().then(orgsList => {
        this.setState({organizationsList: orgsList.data});
      }).catch(() => message.error("Failed to load organizations list"));
    } else {
      this.setState({organizationsList: [this.state.currentUser.organization]})
    }
  }

  handleResetPassword = () => {
    const userEmail = this.state.userData.email;
    AuthService.requestPasswordReset(userEmail, 'long').then(() => {
      message.success(`Password reset instructions have been sent to ${userEmail}`, 5)
    }).catch(() => {
      message.error('Failed to reset password');
    })
  }

  handleDeleteUser = () => {
    UsersService.deleteUser(this.props.match.params.userId).then(() => {
      message.success('User deleted');
      this.props.history.push('/users')
    }).catch(() => {
      message.error('Failed to delete user');
    });
  }

  componentDidMount() {
    this.loadCurrentUser();

    UsersService.fetchUserById(this.props.match.params.userId).then(result => {
      this.formRef.current.setFieldsValue({name: result.data.name, email: result.data.email, password: '', organization_id: result.data.organization.id});
      this.setState({
        userRoles: result.data.roles, permissionsList: result.data.permissions,
        userData: result.data, selectedOrganizationId: result.data.organization.id,
      });
    }).catch(err => {
      message.error('Failed to get user info');
    })
  }

  onSubmit = (values) => {
    const userId = this.props.match.params.userId;
    UsersService.updateUser({
      'id': userId,
      ...values,
      'roles': this.state.userRoles,
      'permissions': this.state.permissionsList
    }).then(result => {
      message.success('User updated');
      this.props.history.push(`${this.props.root}users`);
    }).catch(err => {
      message.error('Failed to update user');
    });
  }

  getAvailablePermissionsTypes = () => {
    const isAdmin = this.state.currentUser?.roles?.includes('admin');
    if (!isAdmin) return ['view_results', 'finalize_results', 'hierarchies', 'comments'];
    if (this.state.userRoles.includes('admin') || this.state.userRoles.includes('analyst'))
      return ['view_results', 'finalize_results', 'data_extraction', 'hierarchies', 'comments'];
    return ['view_results', 'finalize_results', 'hierarchies', 'comments'];
  }

  render() {
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };

    const rolesToAdd = ['user', 'analyst', 'admin', 'manager'].filter(r => !this.state.userRoles.includes(r));

    const menu = (
        <Menu>
          {
            rolesToAdd.map(role => (
                <Menu.Item onClick={() => this.setState({userRoles: [...this.state.userRoles, role]})}>
                  {role}
                </Menu.Item>
            ))
          }
        </Menu>
    )

    const organizationPermissions = this.state.organizationsList.filter(
        org => org.id === this.state.selectedOrganizationId
    )[0]?.permissions || [];

    const isAdmin = this.state.currentUser?.roles?.includes('admin');
    const rolesEditable = isAdmin;

    const availablePermissionsTypes = this.getAvailablePermissionsTypes();
    return (
      <>
        <Row style={{justifyContent: 'center'}}>
          <Card
              title="Update user"
              style={{width: '100%'}}
              extra={(isAdmin &&
                <Tooltip title="Delete user" placement="right">
                  <Popconfirm
                      title="Are you sure to permanently delete the user？"
                      onConfirm={this.handleDeleteUser}
                  >
                    <a>
                      <DeleteOutlined style={{fontSize: '24px'}}/>
                    </a>
                  </Popconfirm>
                </Tooltip>
              )}
          >

            <Form {...layout} ref={this.formRef} name="update-user-form" onFinish={this.onSubmit}>
              <Row gutter={[16, 16]}>
                <Col span={12}>

                <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true, message: 'Please input user name' }, { min: 3 }]}
                >
                  <Input disabled={KEYCLOAK_ENABLED}/>
                </Form.Item>

                <Form.Item name={'email'} label="Email" rules={[{ type: 'email', required: true }]}>
                  <Input disabled={true} />
                </Form.Item>

                <Form.Item name="organization_id" label="Organization" rules={[{ required: true }]} >
                  <Select
                      disabled={!isAdmin}
                      showSearch
                      filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(newValue) => this.setState({selectedOrganizationId: newValue})}
                  >
                    {
                      this.state.organizationsList.map(org => (<Option value={org.id}>{org.name}</Option>))
                    }
                  </Select>

                </Form.Item>

                <Form.Item
                    label="Roles"
                    // name="roles"
                >
                  {
                    this.state.userRoles.map((role) => {
                      return (
                          <Tag key={`tag-${role}`} color={roleColors[role]}
                               closable={rolesEditable}
                               onClose={(e) => {
                                 e.preventDefault();
                                 this.setState({
                                   userRoles: this.state.userRoles.filter(r => r !== role)
                                 })
                               }}
                          >
                            {role}
                          </Tag>
                      )
                    })
                  }
                  {
                    rolesEditable && rolesToAdd.length > 0 && (
                        <Dropdown overlay={menu} trigger={['click']}>
                          <Tag style={{borderStyle: 'dashed', background: '#fff'}}>
                            <PlusOutlined /> New role
                          </Tag>
                        </Dropdown>
                    )
                  }

                </Form.Item>
                </Col>

                <Col span={12}>
                  <PermissionsList
                      title="User permissions"
                      permissionsList={this.state.permissionsList}
                      availablePermissionsTypes={availablePermissionsTypes}
                      isEditable={true}
                      onChange={perms => {this.setState({permissionsList: perms})}}
                      style={{marginBottom: '16px'}}
                  />

                  <PermissionsList
                      title="Organization permissions"
                      permissionsList={organizationPermissions}
                      isEditable={false}
                      style={{marginBottom: '16px'}}

                  />
                </Col>
              </Row>
              <Row>
                {!KEYCLOAK_ENABLED && (
                    <Popconfirm
                        title="Are you sure to send email with password reset instructions to the user？"
                        onConfirm={this.handleResetPassword}
                    >
                      <Button>Reset password</Button>
                    </Popconfirm>
                )}
                <Space style={{marginLeft: 'auto'}}>
                  <Button><Link to={`${this.props.root}users`}>Cancel</Link></Button>
                  <Button
                      type="primary"
                      htmlType="submit"
                  >
                    Save
                  </Button>
                </Space>
              </Row>

            </Form>
          </Card>
        </Row>
      </>
    )
  }
}