export class SafeAnimatedCnDetails {
    constructor(origin) {
        this._origin = origin;
    }

    keys() {
        try {
            return this._origin.keys();
        } catch (e) {
            return [];
        }
    }

    values(key) {
        try {
            return this._origin.values(key);
        } catch (e) {
            return [];
        }
    }

    hasValueByKey(key, value) {
        try {
            return this._origin.hasValueByKey(key, value);
        } catch (e) {
            return false;
        }
    }
}