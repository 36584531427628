import {Form, Input, Select, Tag} from "antd";
import React from "react";
import {CnStatusEditedFilter} from "./Model/CnStatusEditedFilter";

export function CnStatusFilterEditor({possibleOptions, onEditedFilterChanged, editedFilterCtor}) {
    const allOptions = [
        {
            value: 'ADDED',
        },
        {
            value: 'REMOVED',
        },
        {
            value: 'MOVED',
        },
        {
            value: 'MODIFIED',
        },
        {
            value: 'SAME'
        }
    ];

    const options = possibleOptions || allOptions;

    const defaultEditedFilterCtor = (values) => new CnStatusEditedFilter(values);
    const currentEditedFilterCtor = editedFilterCtor || defaultEditedFilterCtor;

    function colorOf(value) {
        if (value === 'ADDED') {
            return 'green';
        } else if (value === 'REMOVED') {
            return 'red';
        } else if (value === 'MOVED') {
            return 'purple';
        } else if (value === 'MODIFIED') {
            return 'blue';
        }

        return '';
    }

    function tagRender(props) {
        const { label, value, closable, onClose } = props;

        const onPreventMouseDown = event => {
            event.preventDefault();
            event.stopPropagation();
        };

        return (
            <Tag
                color={colorOf(value)}
                size={"small"}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
                style={{ marginRight: 3 }}
            >
                {label}
            </Tag>
        );
    }

    return (
        <>
            <Form.Item label="Attribute" >
                <Input size="small" value="Status" disabled={true}/>
            </Form.Item>
            <Form.Item label="Value">
                <Select mode="multiple"
                        size={"small"}
                        showArrow
                        tagRender={tagRender}
                        defaultValue={[]}
                        style={{ width: '100%' }}
                        options={options}
                        onChange={values => onEditedFilterChanged(currentEditedFilterCtor(values))}
                />
            </Form.Item>
        </>
    );
}