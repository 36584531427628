import HierarchyNode from "../../../ImageViewerHandlers/Hierarchy/HierarchyNode";
import {ImageViewerCanvasObject} from "../../../ImageViewerHandlers/ImageViewerCanvasObject";

type ChildNode = {
    target: HierarchyNode;
    targetType: "node";
};

type ChildObject = {
    target: ImageViewerCanvasObject;
    targetType: "object";
}

export type Child = ChildNode | ChildObject;
export type Parent = HierarchyNode;

export interface ParentChildSelectorState {
    child: Child | null;
    parent: Parent | null;
}

export function childIsNode(child: Child): child is ChildNode {
    return child.targetType === "node";
}

export function textOf(child: Child): string {
    if (childIsNode(child)) {
        return child.target.text;
    } else {
        return child.target.objectMetadata.text;
    }
}

export function parentChildStateIsNotEmpty(state: ParentChildSelectorState): boolean {
    return state.parent != null || state.child != null;
}