import {ImageViewerCanvasObject} from "../../../ImageViewerHandlers/ImageViewerCanvasObject";

export type CustomObjectAttributeBase = {
    alias?: string,
    key: string;
    isReadonly: boolean;
    canBeDeleted: boolean;
}

export type AttributeTextValue = {
    type: "text",
    value: string;
}

export type AttributeSelectValue = {
    type: "select",
    value: string,
    options: string[];
}

export type AttributeAutocompleteValue = {
    type: "autocomplete",
    value: string,
    options: string[];
}

export type CustomObjectAttribute = CustomObjectAttributeBase & (
    AttributeSelectValue | AttributeAutocompleteValue | AttributeTextValue
);

export function isTextAttribute(attr: CustomObjectAttribute): attr is CustomObjectAttribute & AttributeTextValue {
    return attr.type === "text";
}

export function isSelectAttribute(attr: CustomObjectAttribute): attr is CustomObjectAttribute & AttributeSelectValue {
    return attr.type === "select";
}

export function isAutocompleteAttribute(attr: CustomObjectAttribute): attr is CustomObjectAttribute & AttributeAutocompleteValue {
    return attr.type === "autocomplete";
}

export interface CustomObjectAttributes {
    attributes: CustomObjectAttribute[],

    updateAttributeValue(key: string, value: string): Promise<CustomObjectAttributes>;

    createAttribute(key: string, value: string): Promise<CustomObjectAttributes>;

    deleteAttribute(key: string): Promise<CustomObjectAttributes>;
}

export interface CustomObjectAttributesSource {
    load(obj: ImageViewerCanvasObject): Promise<CustomObjectAttributes>;
}
