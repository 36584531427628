import axios from "axios"
import {API_URL} from "../constants";
import {authHeader} from "../Utilities";

class WorspacesService {
  fetchWorkspaces(fetchAll=false) {
    return axios.get(API_URL + `/workspaces?all=${fetchAll}`, {headers: authHeader()})
  }

  deleteWorkspace(workspaceId) {
    return axios.delete(API_URL + `/workspaces/${workspaceId}`, {headers: authHeader()})
  }

  fetchExcelReportsConfigs(workspaceId) {
    return axios.get(API_URL + `/workspaces/${workspaceId}/excel_reports_configs`, {headers: authHeader()})
  }

  addExcelReportConfig(workspaceId, reportConfig) {
    return axios.post(API_URL + `/workspaces/${workspaceId}/excel_reports_configs`, reportConfig, {headers: authHeader()})
  }

  updateExcelReportConfig(workspaceId, reportConfig) {
    return axios.put(API_URL + `/workspaces/${workspaceId}/excel_reports_configs/${reportConfig.id}`, reportConfig, {headers: authHeader()})
  }

  deleteExcelReportConfig(workspaceId, reportConfigId) {
    return axios.delete(API_URL + `/workspaces/${workspaceId}/excel_reports_configs/${reportConfigId}`, {headers: authHeader()})
  }


  fetchHierarchyReportsConfigs(workspaceId) {
    return axios.get(API_URL + `/workspaces/${workspaceId}/hierarchy_reports_configs`, {headers: authHeader()})
  }

  addHierarchyReportConfig(workspaceId, reportConfig) {
    return axios.post(API_URL + `/workspaces/${workspaceId}/hierarchy_reports_configs`, reportConfig, {headers: authHeader()})
  }

  updateHierarchyReportConfig(workspaceId, reportConfig) {
    return axios.put(API_URL + `/workspaces/${workspaceId}/hierarchy_reports_configs/${reportConfig.id}`, reportConfig, {headers: authHeader()})
  }

  deleteHierarchyReportConfig(workspaceId, reportConfigId) {
    return axios.delete(API_URL + `/workspaces/${workspaceId}/hierarchy_reports_configs/${reportConfigId}`, {headers: authHeader()})
  }

  fetchPostProcessingLambdaFunctions(workspaceId) {
    return axios.get(API_URL + `/workspaces/${workspaceId}/post_processing_lambda_functions`, {headers: authHeader()})
  }
}

export default new WorspacesService();
