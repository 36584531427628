import React, {useState} from "react";
import {Button, Dropdown, Menu} from "antd";
import {BulkUpdateHierarchyModal} from "./BulkUpdateHierarchy";
import {DownOutlined} from "@ant-design/icons";
import Hierarchy from "../../ImageViewerHandlers/Hierarchy/Hierarchy";
import {ViewHierarchy} from "./ViewHierarchy";

export function ViewHierarchyActions({hierarchy, viewHierarchy}: {
    hierarchy?: Hierarchy,
    viewHierarchy: ViewHierarchy,
}) {
    const [bulkUpdateModalVisible, setBulkUpdateModalVisible] = useState(false);

    const actionsMenu = (
        <Menu>
            <Menu.Item onClick={() => setBulkUpdateModalVisible(true)}>
                Bulk Update
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            <Dropdown overlay={actionsMenu} trigger={["click"]}>
                <Button>Actions <DownOutlined/></Button>
            </Dropdown>
            {hierarchy != null && (
              <>
                  <BulkUpdateHierarchyModal
                      onOk={() => viewHierarchy.reloadHierarchy()}
                      hierarchy={hierarchy}
                      visible={bulkUpdateModalVisible}
                      setVisible={setBulkUpdateModalVisible}
                  />
              </>
            )}
        </>
    );
}
