import React, {useEffect, useState} from "react";
import {CnSummary} from "./Summary/CnSummary";
import {Modal, message} from "antd";
import {SelectDrawingsToCompare} from "./SelectDrawingsToCompare";
import {RemoteCnIntent} from "../Model/Remote/RemoteCnIntent";
import {CnIntent} from "../Model/CnIntent";
import axios from "axios";
import {API_URL} from "../../../../constants";
import {authHeader} from "../../../../Utilities";
import {RemoteCnDrawing} from "../Model/Remote/RemoteCnDrawing";
import {useHistory, useLocation} from "react-router";

export function DrawingsComparison({modalVisible, onModalVisibilityChanged, anotherSelectedCanvasObject, onFiltersChanged,
                                       currentCnDrawing, selectedCanvasObject, selectCanvasObjectById, onCnResultsChanged,
                                       selectOtherObjectById, renderObjectData,
                                       canvas, onSummaryVisibilityChanged}) {
    const history = useHistory();
    const location = useLocation();

    const [cnIntent, setCnIntent] = useState(new CnIntent());
    const [summaryVisible, setSummaryVisible] = useState(false);

    useEffect(() => {
        setSummaryVisible(cnIntent.prepared());
    }, [cnIntent]);

    useEffect(() => {
        onSummaryVisibilityChanged(summaryVisible);
    }, [summaryVisible]);

    useEffect(() => {
        const cnSwitch = location.state?.cnSwitch;

        if (cnSwitch != null) {
            axios.post(API_URL + `/user_view/drawing_to_compare/info`,
                {result_reference: cnSwitch.selectedDrawing}, {headers: authHeader()})
                .then(res => {
                    const data = res.data;

                    if (data.length === 0) {
                        message.error('Failed to load drawing info');
                    }

                    setCnIntent(new RemoteCnIntent(
                        currentCnDrawing, new RemoteCnDrawing(res.data[0], history), selectCanvasObjectById, selectOtherObjectById, canvas
                    ));
                });
        }
    }, [history, location]);

    return (
      <>
          <SelectDrawingToCompareModal
              visible={modalVisible}
              currentCnDrawing={currentCnDrawing}
              onCancel={e => onModalVisibilityChanged(false)}
              onCompareClick={selectedCnDrawing => {
                  setCnIntent(new RemoteCnIntent(currentCnDrawing, selectedCnDrawing, selectCanvasObjectById, selectOtherObjectById, canvas));
                  onModalVisibilityChanged(false);
              }}
          />
          {
              summaryVisible &&
              <div style={{overflowY: 'auto'}}>
                  <CnSummary selectedCanvasObject={selectedCanvasObject}
                             renderObjectData={renderObjectData}
                             anotherSelectedCanvasObject={anotherSelectedCanvasObject}
                             cnIntent={cnIntent}
                             onCnResultsChanged={onCnResultsChanged}
                             onFiltersChanged={onFiltersChanged}
                             onCloseClick={e => {
                                 setSummaryVisible(false);
                             }}
                  />
              </div>
          }
      </>
    );
}

export function SelectDrawingToCompareModal({visible, currentCnDrawing, onCancel, onCompareClick}) {
    return (
        <Modal width={700} title="Select drawing" visible={visible} onCancel={onCancel} footer={false} closable={true}>
            <SelectDrawingsToCompare currentCnDrawing={currentCnDrawing} onCompareClick={onCompareClick}/>
        </Modal>
    );
}