class BaseChangesDetector {
    constructor() {
        this.subjects = {}
    }

    detect(validationData) {
        const validationKeys = Object.keys(validationData);

        for (let i = 0; i < validationKeys.length; i++) {
            const key = validationKeys[i];

            const subject = this.subjects[key];
            const validationTarget = validationData[key];

            if (!subject) {
                console.warn(`Attempt to validate unregistered data with key '${key}'`);
            }

            if (!this._compareObjects(validationTarget, subject)) return true;
        }

        return false;
    }

    register(entry) {
        Object.keys(entry).forEach(key => {
            this.subjects[key] = this._prepareRegisteredData(entry[key]);
        })
    }

    _prepareRegisteredData(data) {
        return data
    }

    _compareObjects(a, b) {
        return a === b;
    }
}

export class JSONChangesDetector extends BaseChangesDetector {
    _prepareRegisteredData(data) {
        return JSON.parse(JSON.stringify(data));
    }

    _compareObjects(a, b) {
        return JSON.stringify(a) === JSON.stringify(b);
    }
}