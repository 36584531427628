import ObjectReference from "./ObjectReference";
import HierarchiesService, {NodeLoadingInfoModel} from "../../../services/HierarchiesService";
import ImageViewerObject, {ImageViewerObjectAttribute} from "../ImageViewerObject";
import Hierarchy from "./Hierarchy";
import {TagReference} from "../../../services/ApiModels/TagReference";
import {AsyncDataSource} from "../../Misc/Table/AsyncDataSourceGeneric";
import {ReferencesV1} from "./ReferencesV1";

type HierarchyNodeAttribute = {
    key: string,
    value: string
}

type HierarchyNodeLoadingInfo = NodeLoadingInfoModel;

export type ObjectReferenceWithExtraData = {
    tag_reference: TagReference;
    reference: {isOnPageReference(): boolean, open(): void};
    file_name: string;
    page_number: number;
}

export interface ReferencesDelegate {
    isVirtual(): boolean;

    hasOnPageReference(): boolean;

    matchesObject(obj: ImageViewerObject): boolean;

    findReferencedObject(): ImageViewerObject | null;

    findPotentialObject(): ImageViewerObject | null;

    getObjectAttributes(): Promise<ImageViewerObjectAttribute[] | null>;

    getRefsWithExtraDataSource(
        skipOnSameResult: boolean
    ): AsyncDataSource<ObjectReferenceWithExtraData>;
}

export default class HierarchyNode {
    public id?: string;
    public text: string;
    public label: string;
    public references: ObjectReference[];
    public attributes: HierarchyNodeAttribute[];
    public loading_info?: HierarchyNodeLoadingInfo;
    public hierarchy: Hierarchy;
    public parent_node_id?: string;
    private readonly _referencesDelegate: ReferencesDelegate;

    constructor({id, text, label, parent_node_id, references, attributes, hierarchy, loading_info}: {
        id?: string,
        text: string,
        label: string,
        parent_node_id?: string,
        references: ObjectReference[] | null,
        attributes: HierarchyNodeAttribute[] | null,
        hierarchy: Hierarchy,
        loading_info?: HierarchyNodeLoadingInfo
    }) {
        this.id = id;
        this.text = text;
        this.label = label;
        this.parent_node_id = parent_node_id;
        this.references = references ?? [];
        this.attributes = attributes ?? [];
        this.hierarchy = hierarchy;
        this.loading_info = loading_info;
        this._referencesDelegate = new ReferencesV1(this);
    }

    // returns null for the root
    getParent() {
        return this.parent_node_id ? this.hierarchy.getNodeById(this.parent_node_id) : null;
    }

    isVirtual() {
        return this._referencesDelegate.isVirtual();
    }

    isRoot() {
        return this.parent_node_id === null;
    }

    isLeaf() {
        if (this.isRoot()) return false;
        if (!this.loading_info?.full) return this.loading_info?.subnodes_count === 0;
        return this.findChildren().length === 0;
    }

    /**
     * returns new object reference with the link to itself
     */
    getObjectReference(obj: ImageViewerObject) {
        const objectCenter = obj.getRect().getCenter();

        return new ObjectReference({
            id: null,
            page_id: this.hierarchy.imageViewer.state.pageId!,
            x_rel: objectCenter.x / this.hierarchy.imageViewer.imageWidth!,
            y_rel: objectCenter.y / this.hierarchy.imageViewer.imageHeight!,
            node: this,
        });
    }

    findAttributeByKey(attributeKey: string) {
        return this.attributes.find(attr => attr.key === attributeKey) ?? null;
    }

    hasAttribute(attributeKey: string) {
        const attribute = this.findAttributeByKey(attributeKey);
        return attribute !== null;
    }

    // returns null if attribute doesn't exist
    getAttributeValue(attributeKey: string) {
        const attribute = this.findAttributeByKey(attributeKey);
        if (!attribute) return null;
        return attribute.value;
    }

    addAttribute(newAttribute: HierarchyNodeAttribute, callback = null) {
        const clonedNode = this.clone();
        clonedNode.attributes = [...clonedNode.attributes, newAttribute];
        return HierarchiesService.updateHierarchyNode(this.hierarchy, clonedNode).then(() => {
            return this.hierarchy.hierarchyView.loadHierarchy([], callback);
        });
    }

    updateText(value: string, callback = null) {
        if (this.textIsReadonly()) return Promise.reject();

        const clonedNode = this.clone();

        clonedNode.text = value;

        return HierarchiesService.updateHierarchyNode(this.hierarchy, clonedNode).then(() => {
            return this.hierarchy.hierarchyView.loadHierarchy([], callback);
        });
    }

    updateLabel(value: string, callback = null) {
        if (this.labelIsReadonly()) return Promise.reject();

        const clonedNode = this.clone();

        clonedNode.label = value;

        return HierarchiesService.updateHierarchyNode(this.hierarchy, clonedNode).then(() => {
            return this.hierarchy.hierarchyView.loadHierarchy([], callback);
        });
    }

    updateAttribute(updatedAttribute: HierarchyNodeAttribute, callback = null) {
        const clonedNode = this.clone();
        clonedNode.attributes = clonedNode.attributes.map(attr => {
            if (attr.key === updatedAttribute.key) return updatedAttribute;
            return attr;
        });
        return HierarchiesService.updateHierarchyNode(this.hierarchy, clonedNode).then(() => {
            return this.hierarchy.hierarchyView.loadHierarchy([], callback);
        });
    }

    removeAttribute(attributeToRemove: {key: string}, callback = null) {
        const clonedNode = this.clone();
        clonedNode.attributes = clonedNode.attributes.filter(attr => attr.key !== attributeToRemove.key);
        return HierarchiesService.updateHierarchyNode(this.hierarchy, clonedNode).then(() => {
            return this.hierarchy.hierarchyView.loadHierarchy([], callback);
        });
    }

    clone() {
        const clonedNode = new HierarchyNode({
            id: this.id,
            text: this.text,
            label: this.label,
            parent_node_id: this.parent_node_id,
            references: [],
            attributes: this.attributes,
            hierarchy: this.hierarchy,
        });
        clonedNode.references = this.references.map(ref => {
            const clonedRef = ref.clone();
            clonedRef.node = clonedNode;
            return clonedRef;
        });
        return clonedNode;
    }

    getDict() {
        return {
            id: this.id,
            text: this.text,
            label: this.label,
            parent_node_id: this.parent_node_id,
            hierarchy_id: this.hierarchy.id,
            references: this.references.map(ref => ref.getDict()),
            attributes: this.attributes,
        };
    }

    hasOnPageReference() {
        return this._referencesDelegate.hasOnPageReference();
    }

    // returns list the nodes on the path, Root is the last element of the list
    // node itself is not included
    getPathToRoot() {
        const result = [];
        let curNode: HierarchyNode = this;
        while (true) {
            const curParent = curNode.getParent();
            if (curParent) {
                result.push(curParent);
                curNode = curParent;
            } else break;
        }
        return result;
    }

    findChildren() {
        return this.hierarchy.nodes.filter(node => node.parent_node_id === this.id);
    }

    // returns list of all nodes from subtree, including itself
    getSubtreeNodes(): HierarchyNode[] {
        const children = this.findChildren();
        const subtreeNodes = [this, ...children.flatMap(child => child.getSubtreeNodes())];
        return subtreeNodes;
    }

    // including self
    hasDescendant(node: HierarchyNode): boolean {
        return node.isDescendantOf(this);
    }

    // including self
    isDescendantOf(node?: HierarchyNode): boolean {
        if (node == null) return false;
        if (node === this) return true;
        return this.getParentNode()?.isDescendantOf(node) ?? false;
    }

    getParentNode(): HierarchyNode | null {
        if (this.parent_node_id == null) return null;
        return this.hierarchy.getNodeById(this.parent_node_id);
    }

    matchesObject(obj: ImageViewerObject) {
        return this._referencesDelegate.matchesObject(obj);
    }

    /**
     * returns null if the object was not found
     * returns first match in case of multiple on page references
     */
    findReferencedObject() {
        return this._referencesDelegate.findReferencedObject();
    }

    async getObjectAttributes(): Promise<ImageViewerObjectAttribute[] | null> {
        const obj = this.findReferencedObject();
        if (obj) {
            return Promise.resolve(obj.getAttributes());
        } else {
            return this._referencesDelegate.getObjectAttributes();
        }
    }

    getRefsWithExtraDataSource(
        skipOnSameResult: boolean = false
    ): AsyncDataSource<ObjectReferenceWithExtraData> {
        return this._referencesDelegate.getRefsWithExtraDataSource(skipOnSameResult);
    }

    /**
     * returns an object with matching text and label
     * returns null if such an object does not exist
     */
    findPotentialObject(): ImageViewerObject | null {
        return this._referencesDelegate.findPotentialObject();
    }

    isValid() {
        return this.label.trim().length > 0 && this.text.trim().length > 0;
    }

    textIsReadonly() {
        return this.isRoot() || !this.isVirtual();
    }

    labelIsReadonly() {
        return this.isRoot() || !this.isVirtual();
    }

    equals(another?: HierarchyNode) {
        if (another == null) return false;
        return this.id === another.id;
    }
}
