import {useEffect, useRef} from "react";

export class NoneScroll {
    do(idx) {

    }
}


export class Scroll extends NoneScroll {
    constructor(refs) {
        super();

        this._refs = refs;
    }

    do(idx) {
        this._refs.current[idx].scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    }
}


export function ScrollableToIndexList({children, onScrollInit, data}) {
    const scrollRefs = useRef([]);

    useEffect(() => {
        onScrollInit(new Scroll(scrollRefs));
    }, [scrollRefs, onScrollInit]);

    useEffect(() => {
        scrollRefs.current = scrollRefs.current.slice(0, data.length);
    }, [data]);

    return children({
        scrollRefs: scrollRefs,
        data: data
    });
}
