import React from 'react'
import {Button, Dropdown, Input, Menu, message, Popconfirm, Row, Space, Table, Typography} from "antd";
import {Link} from "react-router-dom";
import axios from "axios";
import {API_URL} from "../../constants";
import {addTableTextFilters, authHeader, download} from "../../Utilities";
import ExportTags from "./ExportTags";
import {DownloadOutlined, SearchOutlined} from "@ant-design/icons";
import {BulkUpdateAttributesModal} from "./BulkUpdate/BulkUpdateAttributesModal";

export class UserViewFinalizeResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      finalResultsList: [],
      page: 1,
      limit: 20,
      maxPage: 0,
      bulkUpdateModalVisible: false
    };

    addTableTextFilters(this);

    this.handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      this.setState({
        page: 1,
        maxPage: 0,
        total: 0,
        searchText: selectedKeys[0],
        searchedColumn: dataIndex,
      });
    }

    this.handleReset = clearFilters => {
      clearFilters();
      this.setState({ searchText: '', maxPage: 0 });
    };
  }

  loadFinalResults = (projectId) => {
    const params = {
      search: this.state.searchText,
      page: this.state.page,
      limit: this.state.limit
    }

    axios.get(
        API_URL + `/user_view/final_results`,
        {params, headers: authHeader()}
    ).then(result => {
      this.updateTotal(params.page, result.data.length, params.limit);
      this.setState({
        finalResultsList: result.data.slice(0, params.limit),
      });
    });
  }

  componentDidMount() {
    this.loadFinalResults(this.props.match.params.projectId);
  }

  updateTotal(page, resultsSize, limit) {
    if (page > this.state.maxPage) {
      this.setState({maxPage: page, total: (page - 1) * limit + resultsSize})
    }
  }

  handleFinalResultRemoval = (projectId, sampleId) => {
    axios.delete(API_URL + `/projects/${projectId}/final_results/${sampleId}`, {headers: authHeader()}).then(result => {
      this.loadFinalResults(projectId)
      message.success('Final result removed');
    }).catch(err => {
      message.error('Error removing final result');
    });
  }

  render() {
    const fetchOptionsUrl = API_URL + `/user_view/final_results/possible_search_option_values`;
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        ...this.getColumnSearchProps('name'),
        onFilter: (value, record) => value,
      },
      {
        title: 'Page number',
        dataIndex: 'page_number',
        render: pageNumber => pageNumber + 1,
      },
      {
        title: 'Title',
        dataIndex: ['drawing_title'],
      },
      {
        title: 'Actions',
        dataIndex: 'id',
        render: (sampleId, record) => {
          return (
              <>
                <Row style={{justifyContent: 'space-between'}}>
                  <Link to={`/project/${record.project.id}/final_results/view_result/${sampleId}`}>
                    Open
                  </Link>

                  <Popconfirm title="Are you sure to delete the result？" onConfirm={() => this.handleFinalResultRemoval(record.project.id, sampleId)}>
                    <a href="#">Delete</a>
                  </Popconfirm>
                </Row>

              </>
          )
        }
      }
    ];

      const overlay = (
          <Menu>
              <Menu.Item>
                  <ExportTags
                      renderer={(id, onClick) => (<span id={id} onClick={onClick}>Export tags</span>)}
                      fetchOptionsUrl={fetchOptionsUrl}
                      exportFinalResults={true}
                  />
              </Menu.Item>
          </Menu>
      )

    return (
        <>
          <Row style={{justifyContent: 'space-between'}}>
            {/*<Typography.Title level={4}>Final results</Typography.Title>*/}
              <h1>Final results</h1>
              <Space>
                <BulkUpdateAttributesModal
                    fetchOptionsUrl={fetchOptionsUrl}
                    visible={this.state.bulkUpdateModalVisible}
                    onVisibilityChange={value => this.setState({bulkUpdateModalVisible: value})}
                />
                <Button
                    id={"bulk-update-attributes"}
                    onClick={_ => this.setState({bulkUpdateModalVisible: true})}
                    type={"primary"}
                >
                  Bulk Update Attributes
                </Button>
                <Dropdown
                    placement={"bottomLeft"}
                    trigger={"click"}
                    overlay={overlay}
                    arrow
                >
                  <a id={"export-dropdown"}>
                    <DownloadOutlined style={{fontSize: '24px'}} />
                  </a>
                </Dropdown>
              </Space>
          </Row>
          <Table id="final-results-list" dataSource={this.state.finalResultsList} style={{paddingTop: '16px'}} columns={columns}
                 pagination={{ position: 'bottomCenter', current: this.state.page, defaultPageSize: this.state.limit, total: this.state.total,
                   showSizeChanger: false,
                   onChange: (page, pageSize)=> this.setState({page: page}, this.loadFinalResults)}}
          />
        </>
    );
  }
}