import {AsyncDataSource, TablePage} from "../Misc/Table/ManualPagedTable";
import {SearchFilter} from "./SearchFilters/SearchFilters";
import axios from "axios";
import {API_URL} from "../../constants";
import {authHeader} from "../../Utilities";
import {Col, message, Row} from "antd";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {HighlightedMatch} from "../Misc/HighlightedMatch";
import {Link} from "react-router-dom";
import * as queryString from "query-string";
import {QueryWithPage, QueryWithTab} from "../Misc/Query";
import {QueryFromFilters} from "./SearchFilters/QueryFromFilters";
import {SearchFiltersFromUrl} from "./SearchFilters/SearchFiltersFromUrl";
import {FiltersList} from "../Misc/DataSourceFilter";


class HierarchiesDataSource extends AsyncDataSource {
    constructor(filters) {
        super();

        this._filters = filters;
    }

    async values(page, limit) {
        const searchFilters = this._filters.filter(filter => filter instanceof SearchFilter);
        const searchFilter = searchFilters[0] || {
            value: '',
            useWildcards: false
        };

        const data = await axios.get(
            API_URL + `/hierarchies/filtered`,
            {
                params: {
                    page: encodeURIComponent(page),
                    limit: encodeURIComponent(limit),
                    search: encodeURIComponent(searchFilter.value),
                    use_wildcards: encodeURIComponent(searchFilter.useWildcards)
                },
                headers: authHeader()
            }
        ).then(res => res.data).catch(err => {
            message.error('Failed to load hierarchies');
            return [];
        });

        return data;
    }
}


export function HierarchiesSearch({context, location, history, onSearch}) {
    const [page, setPage] = useState(1);
    const [dataSource, setDataSource] = useState(new AsyncDataSource());
    const [filters, setFilters] = useState([]);

    const columns = useMemo(() => {
        const matchColumn = {
            title: 'Match',
            dataIndex: 'match',
            fixed: 'left',
            render: (match) => {
                return (
                    <HighlightedMatch matchText={match.text} occurrences={match.occurrences}/>
                );
            }
        };

        const columns = [
            ...(filters.length > 0 ? [matchColumn] : []),
            {
                title: 'Name',
                dataIndex: ['hierarchy', 'name'],
            },
            {
                title: 'Links',
                dataIndex: ['hierarchy'],
                fixed: 'right',
                width: '5%',
                render: (hierarchy) => {
                    const ref = hierarchy.reference;
                    const refIsValid = ref != null;
                    const hid = hierarchy.id;
                    const pid = ref?.project_id;
                    const rid = ref?.result_id;
                    const runid = ref?.run_id;
                    const getRedirectUrl = () => {
                        let to;
                        if (hierarchy.reference.is_final) {
                            to = `/project/${pid}/final_results/view_result/${rid}?hierarchy_id=${hid}`;
                        } else {
                            to = `/project/${pid}/explore_results/${runid}/view_document/${rid}?hierarchy_id=${hid}`;
                        }
                        return to;
                    };
                    return (
                        <Link to={refIsValid && getRedirectUrl()} disabled={!refIsValid}>Open</Link>
                    );
                }
            }
        ];

        return columns;
    }, [filters]);

    const onPageChange = useCallback((page) => {
        const url = location.search;
        const query = queryString.parse(decodeURI(url));
        query['page'] = page;

        history.push({
            pathname: location.pathname,
            search: queryString.stringify(query)
        });
    }, [history, location, onSearch]);

    const updateFilters = useCallback((updated) => {
        let search = '';

        if (updated.length > 0) {
            search = new QueryWithTab(
                new QueryWithPage(
                    new QueryFromFilters(updated),
                    1
                ),
                'hierarchies'
            ).toString();
        } else {
            search = new QueryWithTab('', 'hierarchies').toString();
        }

        history.push({
            pathname: location.pathname,
            search: search
        });
    }, [location, history, onSearch]);

    useEffect(() => {
        const url = location.search;
        const query = queryString.parse(decodeURI(url));
        const pageNumber = parseInt(query['page']?.toString()) || 1;

        setPage(pageNumber);
        onSearch(query['q'] || '', pageNumber);
    }, [location]);

    useEffect(() => {
        const filters = new SearchFiltersFromUrl(location.search).getArray();
        setFilters(filters);
    }, [location]);

    useEffect(() => {
        setDataSource(new HierarchiesDataSource(filters));
        setPage(1);
    }, [JSON.stringify(filters)]);

    return (
        <>
            <Row justify={"space-between"} style={{marginBottom: 8}}>
                <Col span={15}>
                    <FiltersList itemFilters={filters} onItemFiltersChanged={updateFilters}/>
                </Col>
            </Row>
            <TablePage
                page={page}
                limit={10}
                showSizeChanger={false}
                onPageChange={onPageChange}
                id="hierarchies-table"
                columns={columns}
                asyncDataSource={dataSource}
                scroll={{x: 1500}}
                size="small"
            />
        </>
    );
}
