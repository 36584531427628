import React from 'react'
import {Button, Card, Col, Form, Input, List, message, Modal, Popconfirm, Row, Table, Tabs, Upload} from "antd";
import ColorPicker from "./Misc/ColorPicker";
import tinycolor from 'tinycolor2'
import axios from "axios";
import {API_URL, COLOR_PICKER_PRESET_COLORS} from "../constants";
import {authHeader} from "../Utilities";
import { UploadOutlined } from '@ant-design/icons';
import {LambdaFunctionsSettings} from "./Pipelines/LambdaFunctionsSettings";

export class Settings extends React.Component {
  state = {
    settings: {
      labels: [
        // {
        //   name: 'valve',
        //   color: '#0000FF99',
        // },
        // {
        //   name: 'instrument',
        //   color: '#00FF0099',
        // },
        // {
        //   name: 'metadata',
        //   color: '#FF000099',
        // },
      ]
    },

    importedRunName: '',
  }

  componentDidMount() {
    this.loadSettings();
  }

  loadSettings = () => {
    axios.get(API_URL + `/projects/${this.props.match.params.projectId}/settings`, {headers: authHeader()}).then(res =>
      this.setState({settings: res.data.settings})
    ).catch(err => message.error('failed to load labels'));
  }

  handleSaveSettings = () => {
    axios.put(API_URL + `/projects/${this.props.match.params.projectId}/settings`, {
      settings: this.state.settings,
    }, {headers: authHeader()}).then(result => {
      message.success('labels saved')
    }).catch(err => {
      message.error('failed to save labels')
    });
  }

  getColor(labelName) {
    return this.state.settings.labels.find(label => label.name === labelName).color;
  }

  updateColor(labelName, newColor) {
    this.setState({
      settings: {
        ...this.state.settings,
        labels: this.state.settings.labels.map(label => label.name === labelName ? {...label, color: newColor} : label)
      }
    })
  }

  removeLabel(labelName) {
    this.setState({
      settings: {
        ...this.state.settings,
        labels: this.state.settings.labels.filter(label => label.name !== labelName)
      }
    })
  }

  onImportRunFromMinioSubmit = (values) => {
    axios.post(API_URL + `/projects/${this.props.match.params.projectId}/import_run_from_minio`, {...values},
        {headers: authHeader()}
    ).then(result => {
      message.success('Import scheduled');
    }).catch(err => {
      this.recaptchaRef.current.reset();
      message.error('Failed to schedule import');
    });
  }


  render() {
    const tableColumns = [
      {
        title: 'Label',
        dataIndex: 'name',
      },
      {
        title: 'Color',
        dataIndex: 'color',
        render: (color, record) => <ColorPicker
              color={this.getColor(record.name)}
              presetColors={COLOR_PICKER_PRESET_COLORS}
              onChange={(color) => this.updateColor(record.name, tinycolor(color.rgb).toHex8String(false))}
          />
      },
      {
        title: 'Actions',
        dataIndex: 'name',
        render: (name) => {
          return (<Popconfirm title="Are you sure to delete the label setting？" onConfirm={() => this.removeLabel(name)}>
              <a href="#">Delete</a>
            </Popconfirm>)
        }
      }
    ]

    const uploadProps = {
      name: 'file',
      action: API_URL + `/projects/${this.props.match.params.projectId}/import_run`,
      headers: authHeader(),
      data: {
        run_name: this.state.importedRunName,
      },
      onChange(info) {
        if (info.file.status !== 'uploading') {
          console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
          message.success(`Run imported successfully`);
        } else if (info.file.status === 'error') {
          message.error(`Error importing run`);
        }
      },
    };

    return (
      <Row>
        <Col span={12}>
          <Tabs defaultActiveKey="labels">
            <Tabs.TabPane tab="Labels" key="labels">
              <Row style={{justifyContent: 'space-between'}}>
                <Button onClick={() => this.setState({modalVisible: true, newLabelName: '', newLabelColor: '#0000FF99'})}>
                  Add new
                </Button>

                <Button onClick={this.handleSaveSettings}>
                  Save changes
                </Button>
              </Row>
              <Modal
                  title="Add new class"
                  visible={this.state.modalVisible}
                  onCancel={() => this.setState({modalVisible: false})}
                  onOk={() => this.setState({
                    settings: {
                      ...this.state.settings, labels: [...this.state.settings.labels, {
                        name: this.state.newLabelName,
                        color: this.state.newLabelColor,
                      }]
                    },
                    modalVisible: false,
                  })}
                  width={300}
              >

                <div>
                  <Form
                      labelCol={{span: 6}}
                      wrapperCol={{span: 18}}
                  >
                    <Form.Item label="Label" style={{marginBottom: '12px'}}>
                      <Input size="small"
                             value={this.state.newLabelName}
                             onChange={(e) => this.setState({newLabelName: e.target.value})}
                      ></Input>
                    </Form.Item>
                    <Form.Item label="Color" style={{marginBottom: '4px'}}>
                      <ColorPicker
                          color={this.state.newLabelColor}
                          presetColors={COLOR_PICKER_PRESET_COLORS}
                          onChange={color => this.setState({newLabelColor: tinycolor(color.rgb).toHex8String(false)})}
                      />
                    </Form.Item>
                    {/*<Form.Item label="value" style={{marginBottom: '12px'}}>*/}
                    {/*  <Input size="small" value={this.state.newAttribute.value}*/}
                    {/*         onChange={(e) => this.setState({newAttribute: {...this.state.newAttribute, value: e.target.value}})}*/}
                    {/*  ></Input>*/}
                    {/*</Form.Item>*/}
                  </Form>
                </div>
              </Modal>

              <Table style={{marginTop: '16px'}} dataSource={this.state.settings.labels} columns={tableColumns}
                     size="small"/>
            </Tabs.TabPane>

            <Tabs.TabPane tab="Import run" key="import-run">
              <Row>
                <Col span={12}>
                  <Form
                      labelCol={{span: 8}}
                      wrapperCol={{span: 16}}
                  >
                    <Form.Item label="Run name" style={{marginBottom: '12px'}}>
                      <Input size="small"
                             value={this.state.importedRunName}
                             onChange={(e) => this.setState({importedRunName: e.target.value})}
                      />
                    </Form.Item>
                  </Form>
                  <Upload {...uploadProps}>
                    <Button icon={<UploadOutlined />}>Upload zip</Button>
                  </Upload>
                </Col>
              </Row>

            </Tabs.TabPane>

            <Tabs.TabPane tab="Import run from minio" key="import-run-from-minio">
              <Row>
                <Col span={12}>
                  <Form
                      labelCol={{span: 8}}
                      wrapperCol={{span: 16}}
                      name="import-run-from-minio-form"
                      onFinish={this.onImportRunFromMinioSubmit}
                  >
                    <Form.Item name={'run-name'} label="Run name" rules={[{ required: true }]}>
                      <Input />
                    </Form.Item>
                    <Form.Item name={'annotations-dir'} label="Annotations dir" rules={[{ required: true }]}>
                      <Input />
                    </Form.Item>
                    <Form.Item name={'images-dir'} label="Images dir" rules={[{ required: true }]}>
                      <Input />
                    </Form.Item>
                    <Row>
                      <Button type="primary" htmlType="submit" style={{marginLeft: 'auto'}}>
                        Import
                      </Button>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Tabs.TabPane>

            <Tabs.TabPane tab="Lambda functions" key="lambda-functions">
              <LambdaFunctionsSettings {...this.props}/>
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
    )
  }
}

export default Settings;
