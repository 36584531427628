export default class PermissionsService {
  constructor(user) {
    this.user = user;
  }

  isAdmin() {
    if (!this.user) return null;
    return this.user.roles.includes('admin');
  }

  isManager() {
    if (!this.user) return null;
    return this.user.roles.includes('manager');
  }

  getLinkToUser = (userId) => {
    if (!this.user) return null;
    if (this.isAdmin())
        return `/users/${userId}/edit`;
      else if (this.isManager())
        return `/user_view/users/${userId}/edit`;
  }
}
