import React from 'react';
import { Row, Col } from 'antd';
import { Typography } from 'antd';
import { Table, Button } from 'antd';
import { showNewProjectForm, fetchProjects } from '../actions/actions'
import { Link } from "react-router-dom"
import {addTableTextFilters} from "../Utilities";

const { Title } = Typography;

export class ProjectsList extends React.Component {
  constructor(props) {
    super(props);

    addTableTextFilters(this);
  }

  componentDidMount() {
    this.props.dispatch(fetchProjects())
  }

  render() {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        ...this.getColumnSearchProps('name'),
      },
      {
        title: 'Description',
        dataIndex: 'description',
        ...this.getColumnSearchProps('description'),
      },
      {
        title: 'Workspace',
        dataIndex: 'workspace',
      },
      {
        title: 'Link',
        dataIndex: 'link'
      },
    ];

    return (
      <>
        <Row style={{justifyContent: 'space-between', padding: '1em'}}>
          <Title level={4}>Projects</Title>
          <Button type="primary"><Link to="/projects/new">New project</Link></Button>
        </Row>
        <Table id="projects-list" dataSource={this.props.dataSource} columns={columns} />
      </>
    );
  }
}