import axios from "axios"
import {API_URL} from "../constants";
import {authHeader} from "../Utilities";

class UsersService {
  fetchUsers() {
    return axios.get(API_URL + '/users', {headers: authHeader()});
  }

  fetchUserById(userId) {
    return axios.get(API_URL + `/users/${userId}`, {headers: authHeader()});
  }

  addUser(user) {
    return axios.post(API_URL + '/users', user, {headers: authHeader()});
  }

  updateUser(user) {
    return axios.put(API_URL + `/users/${user.id}`, user, {headers: authHeader()});
  }

  deleteUser(userId) {
    return axios.delete(API_URL + `/users/${userId}`, {headers: authHeader()});
  }
}

export default new UsersService();