import * as React from 'react';
import Table from 'antd/lib/table';
import { DndProvider, DropTarget, DragSource } from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';

let dragingIndex = -1;


const BodyRow = props => {
    const style = { ...props.style, cursor: 'move' };

    let { className } = props;
    if (props.isOver) {
        if (props.index > dragingIndex) {
            className += ' drop-over-downward';
        }
        if (props.index < dragingIndex) {
            className += ' drop-over-upward';
        }
    }

    return props.connectDragSource(
        props.connectDropTarget(
            <tr className={className} style={style}>
                {props.children}
            </tr>
        )
    );
};

const rowSource = {
    beginDrag(props) {
        dragingIndex = props.index;
        return {
            index: props.index
        };
    }
};

const rowTarget = {
    drop(props, monitor) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return;
        }

        // Time to actually perform the action
        props.moveRow(dragIndex, hoverIndex);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        monitor.getItem().index = hoverIndex;
    }
};

const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
}))(
    DragSource('row', rowSource, connect => ({
        connectDragSource: connect.dragSource()
    }))(BodyRow)
);

export const DragSortingTable = props => {
    const [dataSource, setDataSource] = React.useState(props.dataSource);

    React.useEffect(() => {
        setDataSource(props.dataSource);
    }, [props.dataSource]);

    const components = {
        body: {
            row: DragableBodyRow
        }
    };

    const moveRow = (dragIndex, hoverIndex) => {
        const dragRow = dataSource[dragIndex];
        const remaining = dataSource.filter(i => i !== dragRow);
        const sorted = [...remaining.slice(0, hoverIndex), dragRow, ...remaining.slice(hoverIndex)];

        setDataSource(sorted);
        props.onRowsMoved(sorted);

        if (props.onDragSort) {
            props.onDragSort(sorted);
        }
    };

    const tableProps = {
        ...props,
        className: props.className ? props.className + ' drag-sorting-table' : 'drag-sorting-table',
        components,
        dataSource,
        onRow: (_record, index) => ({ index, moveRow })
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <Table {...tableProps}>{props.children}</Table>
        </DndProvider>
    );
};
