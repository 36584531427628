import React from 'react';
import { Tabs } from 'antd';
import { UploadDocuments } from './UploadDocuments/UploadDocuments';
import { UploadS3WithCredentials } from './UploadS3/UploadS3WithCredentials';

const { TabPane } = Tabs

export class UploadPage extends React.Component {
  componentDidMount() {
  }

  render() {
    return (
      <>
        <Tabs defaultActiveKey="web_upload">
          <TabPane tab="Web upload" key="web_upload">
            <UploadDocuments {...this.props} />
          </TabPane>
          <TabPane tab="S3" key="s3">
            <UploadS3WithCredentials {...this.props} />
          </TabPane>
        </Tabs>
      </>
    );
  }
}
