import React from "react";
import {Button, Dropdown, Menu} from "antd";
import {LinkOutlined} from "@ant-design/icons";


export function EntityLinksDropdown({source, triggerStyle={}}) {
    const items = source.filter([
        {
            label: 'Inventory',
            icon: <LinkIcon title={'Inventory'} src={process.env.PUBLIC_URL + '/images/kbr/ih.png'}/>,
            key: 'ih'
        },
        {
            label: 'Data Mapper',
            icon: <LinkIcon title={'Data Mapper'} src={process.env.PUBLIC_URL + '/images/kbr/dm.png'}/>,
            key: 'dm'
        },
        {
            label: 'Knowledge',
            icon: <LinkIcon title={'Knowledge'} src={process.env.PUBLIC_URL + '/images/kbr/kh.png'}/>,
            key: 'kh'
        },
        {
            label: 'Equipment',
            icon: <LinkIcon title={'Equipment'} src={process.env.PUBLIC_URL + '/images/kbr/eh.svg'}/>,
            key: 'eh'
        },
    ]);

    const overlay = (
        <Menu
            onClick={({key}) => {
                setTimeout(() => {
                    window.open(source.urlByType(key), '_blank').focus();
                });
            }}
        >
            {items.map(x => (
                <Menu.Item key={x.key} onClick={console.log}>
                    {x.label}
                </Menu.Item>
            ))}
        </Menu>
    );

    return items.length > 0 && (
        <Dropdown overlay={overlay}>
            <Button icon={<LinkOutlined />} type={"link"} style={triggerStyle}/>
        </Dropdown>
    );
}


function LinkIcon({title, src}) {
    return (
        <img alt={title} width={24} height={24} src={src} style={{marginRight: '4px'}}/>
    );
}

