import {ColumnsType} from "antd/es/table";
import {TagReference} from "../../../services/ApiModels/TagReference";
import {TagPreview} from "../../ExploreResults";
import {Modal, Tooltip} from "antd";
import React from "react";
import {ManualPagedTable} from "../../Misc/Table/ManualPagedTable";
import {AsyncDataSource} from "../../Misc/Table/AsyncDataSourceGeneric";
import {ObjectReferenceWithExtraData} from "../../ImageViewerHandlers/Hierarchy/HierarchyNode";

export type NodeRefDescription = ObjectReferenceWithExtraData;

export function SelectReferenceToGoModal({dataSource, visible, onVisibleChange}: {
    dataSource: AsyncDataSource<NodeRefDescription>,
    visible: boolean,
    onVisibleChange: (visible: boolean) => void
}) {
    const columns: ColumnsType<NodeRefDescription> = [
        {
            title: "Tag",
            dataIndex: ["tag_reference"],
            render: (tagReference: TagReference, record) => {
                const tooltipContent = (
                    <TagPreview
                        resultId={tagReference.result_reference.result_id}
                        isFinalResult={tagReference.result_reference.is_final}
                        tagNumber={tagReference.tag_number}
                        tagId={tagReference.tag_id}
                    />
                );

                return (
                    <Tooltip placement="topRight" title={tooltipContent} color="white"
                             overlayStyle={{maxWidth: "1800px", maxHeight: "2000px"}}
                             destroyTooltipOnHide={true}
                    >
                        <a>
                            <span
                                style={{
                                    backgroundColor: record.reference.isOnPageReference() ? "#57ce46" : "#3968d0"
                                }}
                                className="medium-circle"
                            />
                            {tagReference.tag_number}
                        </a>
                    </Tooltip>
                );
            }
        },
        {
            title: "File name",
            dataIndex: ["file_name"]
        },
        {
            title: "Page number",
            dataIndex: ["page_number"],
            render: pageNumber => pageNumber + 1
        },
        {
            title: "Link",
            dataIndex: "id",
            width: "15%",
            render: (_, record) => (
                <a onClick={e => {
                    record.reference.open();
                    onVisibleChange(false);
                }}>Open</a>
            )
        }
    ];

    return (
        <Modal
            // @ts-ignore
            id="references-modal"
            footer={null}
            title="References"
            visible={visible}
            onCancel={e => onVisibleChange(false)}
            onOk={e => onVisibleChange(false)}
        >
            <ManualPagedTable size="small" columns={columns} asyncDataSource={dataSource} pagination={false}/>
        </Modal>
    );
}