import {Space, Tag, Typography} from "antd";
import React from "react";

export function ObjectTextLabel({text, label}) {
    return (
        <Typography>
            {/*<Typography.Text strong>(</Typography.Text>*/}
            {/*<Typography.Text strong>, </Typography.Text>*/}
            {/*{label}*/}
            <Space>
                {text}
                <Tag>{label}</Tag>
            </Space>
            {/*<Typography.Text strong>)</Typography.Text>*/}
        </Typography>
    );
}