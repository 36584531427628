import { UPLOAD_FILE_PROGRESS, PENDING_FILES } from '../actions/uploadFiles';


export function uploadedFile(
  state = {
    progress: {}
  },
  action
) {
  switch (action.type) {
    case UPLOAD_FILE_PROGRESS: {
      const progress = { ...state.progress }
      progress[action.filename] = action
      return {
        ...state,
        progress
      }
    }
    case PENDING_FILES: {
      const progress = { ...state.progress, ...action.files }
      return {
        ...state,
        progress
      }
    }
    default:
      return state
  }
}