import React from "react";
import {Button, Dropdown, Menu} from "antd";
import {SettingOutlined} from "@ant-design/icons";
import {ViewHierarchy} from "./ViewHierarchy";

export function ViewHierarchySettings({viewHierarchy}: {
    viewHierarchy: ViewHierarchy,
}) {
    const actionsMenu = (
        <Menu>
            <Menu.Item onClick={() => viewHierarchy.toggleArrows()}>
                Toggle Arrows
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={actionsMenu} trigger={["click"]}>
            <Button
                type={"link"}
                icon={<SettingOutlined />}
            />
        </Dropdown>
    );
}