import React from "react";
import {CnStatus} from "../CnStatus";
import {Tag} from "antd";

export class TagCnStatus extends CnStatus {
    constructor(name) {
        super();

        this._name = name;
    }

    name() {
        return this._name;
    }

    toTag() {
        let color = '';

        if (this._name === 'ADDED') {
            color = 'green';
        } else if (this._name === 'REMOVED') {
            color = 'red';
        } else if (this._name === 'MOVED') {
            color = 'purple';
        } else if (this._name === 'MODIFIED') {
            color = 'blue';
        }

        return <Tag color={color}>{this._name}</Tag>;
    }
}