import {CnCanvasObject} from "../CnCanvasObject";

export class CurrentAnnotationCnCanvasObject extends CnCanvasObject {
    constructor(annotationObj, canvas, fillColor) {
        super();

        this._data = annotationObj;
        this._canvas = canvas;
        this._fillColor = fillColor;
    }

    select() {
        const obj = this._data;
        const found = this._canvas.getObjects().find(el => el.objectMetadata?.id === obj.id);

        if (found) {
            found.stroke = 'rgba(255,0,0,0.8)';
        }
    }

    deselect() {
        const obj = this._data;
        const found = this._canvas.getObjects().find(el => el.objectMetadata?.id === obj.id);

        if (found) {
            found.stroke = 'rgba(0,0,255,0.8)';
        }
    }

    addToCanvas() {

    }

    removeFromCanvas() {
        this.removeHighlight();
    }

    addHighlight() {
        const obj = this._data;
        const found = this._canvas.getObjects().find(el => el.objectMetadata?.id === obj.id);

        if (found) {
            found.baseFillColor = this._fillColor();
            found.fill = this._fillColor();
        }
    }

    removeHighlight() {
        const obj = this._data;
        const found = this._canvas.getObjects().find(el => el.objectMetadata?.id === obj.id);

        if (found) {
            found.baseFillColor = 'rgba(0,0,0,0)';
            found.fill = 'rgba(0,0,0,0)';
        }
    }

    show() {
        const obj = this._data;
        const found = this._canvas.getObjects().filter(el => el.objectMetadata?.id != null).find(el => el.objectMetadata?.id === obj.id);

        if (found){
            found.visible = true;
            found.selectable = true;
        }
    }

    hide() {
        const obj = this._data;
        const found = this._canvas.getObjects().filter(el => el.objectMetadata?.id != null).find(el => el.objectMetadata?.id === obj.id);

        if (found){
            found.visible = false;
            found.selectable = false;
        }
    }
}