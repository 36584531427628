import {CnCanvasObject} from "../CnCanvasObject";
import {fabric} from "fabric";
import {viewerConsts} from "../../../../../constants";
import Rectangle from "../../../../ImageViewerHandlers/Geometry/Rectangle";
import {getFabricjsArrow} from "../../../../../Utilities";

export class AnotherAnnotationCnCanvasObject extends CnCanvasObject {
    constructor(curAnnotationObj, anotherAnnotationObj, canvas, fillColor, status) {
        super();

        this._currentAnnotationObject = curAnnotationObj;
        this._anotherAnnotationObj = anotherAnnotationObj;
        this._status = status;
        this._fillColor = fillColor;
        this._canvas = canvas;
    }

    select() {
        const obj = this._anotherAnnotationObj;
        this._canvas.getObjects()
            .filter(el => el.fromAnotherDrawing)
            .filter(el => el.objectMetadata?.id === obj.id)
            .forEach(el => {
                console.log('select')
                el.stroke = 'rgba(141,141,139,0.6)';
            });
    }

    deselect() {
        const obj = this._anotherAnnotationObj;
        console.log('deselect outer')
        this._canvas.getObjects()
            .filter(el => el.fromAnotherDrawing)
            .filter(el => el.objectMetadata?.id === obj.id)
            .forEach(el => {
                console.log('deselect inner')
                el.stroke = 'rgba(141,141,139,0.6)';
            });
    }

    addToCanvas() {
        const obj = this._anotherAnnotationObj;

        const rect = new fabric.Rect({
            ...viewerConsts.DEFAULT_OBJECT_ARGS,
            width: Math.round(obj.bbox.x2 - obj.bbox.x1),
            height: Math.round(obj.bbox.y2 - obj.bbox.y1),
            stroke: 'rgba(141,141,139,0.6)',
            top: Math.round(obj.bbox.y1),
            left: Math.round(obj.bbox.x1),
            strokeWidth: viewerConsts.STROKE_WIDTH,
            strokeDashArray: [15, 15],
            rx: 0.5,
            ry: 0.5
        });

        rect.hasControls = false;
        rect.lockMovementX = true;
        rect.lockMovementY = true;

        rect.isProperObject = false;
        rect.fromAnotherDrawing = true;

        rect.objectMetadata = {...obj};
        rect.origin = {...this._currentAnnotationObject}

        this._canvas.add(rect);

        if (this._status === 'MOVED') {
            const curRect = new Rectangle(obj.bbox.x1, obj.bbox.y1, obj.bbox.x2, obj.bbox.y2);
            const anotherRect = new Rectangle(rect.origin.bbox.x1, rect.origin.bbox.y1, rect.origin.bbox.x2, rect.origin.bbox.y2);

            getFabricjsArrow([
                curRect.getCenter().x,
                curRect.getCenter().y,
                anotherRect.getCenter().x,
                anotherRect.getCenter().y
            ]).forEach(el => {
                el.originId = obj.id;
                el.selectable = false;
                this._canvas.add(el);
            });
        }
    }

    removeFromCanvas() {
        const obj = this._anotherAnnotationObj;

        this._canvas.getObjects()
            .filter(el => el.fromAnotherDrawing).filter(el => el.objectMetadata?.id === obj.id).forEach(el => this._canvas.remove(el));

        if (this._status === 'MOVED') {
            this._canvas.getObjects().filter(el => el.originId != null).filter(el => el.originId === obj.id).forEach(el => this._canvas.remove(el));
        }
    }

    addHighlight() {
        const obj = this._anotherAnnotationObj;

        this._canvas.getObjects()
            .filter(el => el.fromAnotherDrawing)
            .filter(el => el.objectMetadata?.id === obj.id)
            .forEach(el => {
                el.fill = this._fillColor();
                el.baseFillColor = this._fillColor();
                el.visible = true;
            });

        if (this._status === 'MOVED') {
            this._canvas.getObjects()
                .filter(el => el.originId != null)
                .filter(el => el.originId === obj.id)
                .forEach(el => {
                    el.visible = true;
                });
        }
    }

    removeHighlight() {
        const obj = this._anotherAnnotationObj;

        this._canvas.getObjects()
            .filter(el => el.fromAnotherDrawing)
            .filter(el => el.objectMetadata?.id === obj.id)
            .forEach(el => {
                el.fill = 'rgba(0,0,0,0)';
                el.baseFillColor = 'rgba(0,0,0,0)'
                el.visible = false;
            });

        if (this._status === 'MOVED') {
            this._canvas.getObjects()
                .filter(el => el.originId != null)
                .filter(el => el.originId === obj.id)
                .forEach(el => {
                    el.visible = false;
                });
        }
    }

    show() {
        const obj = this._anotherAnnotationObj;

        this._canvas.getObjects()
            .filter(el => el.fromAnotherDrawing)
            .filter(el => el.objectMetadata?.id === obj.id)
            .forEach(el => {
                el.visible = true;
            });

        if (this._status === 'MOVED') {
            this._canvas.getObjects()
                .filter(el => el.originId != null)
                .filter(el => el.originId === obj.id)
                .forEach(el => {
                    el.visible = true;
                });
        }
    }

    hide() {
        const obj = this._anotherAnnotationObj;

        this._canvas.getObjects()
            .filter(el => el.fromAnotherDrawing)
            .filter(el => el.objectMetadata?.id === obj.id)
            .forEach(el => {
                el.visible = false;
            });

        if (this._status === 'MOVED') {
            this._canvas.getObjects()
                .filter(el => el.originId != null)
                .filter(el => el.originId === obj.id)
                .forEach(el => {
                    el.visible = false;
                });
        }
    }
}