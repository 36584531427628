import React from 'react'
import { Descriptions, Card, Row, Col, Table, Progress } from 'antd';
import { fetchStatistics } from '../actions/actions';
import filesize from 'filesize';

export class Statistics extends React.Component {
  componentDidMount() {
    this.props.dispatch(fetchStatistics(this.props.match.params.projectId))
  }

  render() {
    const totalFilesCount = this.props.summary.total_files_count
    // const dataSource = [
    //   {
    //     file_type: 'PDF',
    //     count: 1200,
    //   },
    //   {
    //     file_type: 'TIF',
    //     count: 200,
    //   },
    //   {
    //     file_type: 'DWG',
    //     count: 30,
    //   },
    //   {
    //     file_type: 'XLSX',
    //     count: 10,
    //   },
    // ];

    const columns = [
      {
        title: 'File type',
        dataIndex: 'file_type',
        render: text => (
          <React.Fragment>
            <span 
              className={"fiv-viv fiv-icon-" + text.toLowerCase()}
              style={{ fontSize: '2em', marginRight: '0.5em' }} 
            />
            {text}
          </React.Fragment>
          )
      },
      {
        title: 'Count',
        dataIndex: 'files_count',
        render: count => {
          const percentage = (100.0 * count / totalFilesCount).toFixed(1)
          return (
            <React.Fragment>
              <Row>
                <Col span={5} style={{textAlign: 'right'}}>
                  {count}
                </Col>
                <Col span={16} offset={1}>
                  <Progress percent={percentage} status="normal"/>
                </Col>
              </Row>
            </React.Fragment>
          )
        }
      },
    ];
    
    

    return (
      <>
        <Row gutter={16}>
          <Col span={12}>
            <Card title="Summary">
              <Descriptions column={1} bordered>
                <Descriptions.Item label="Total files">{totalFilesCount}</Descriptions.Item>
                <Descriptions.Item label="Total size">{filesize(this.props.summary.total_size || 0)}</Descriptions.Item>
                <Descriptions.Item label="Supported files">{this.props.summary.total_supported_files}</Descriptions.Item>
                <Descriptions.Item label="Total pages">{this.props.summary.total_pages}</Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
          <Col span={12}>
            <Card title="File types statistics">
              <Table dataSource={this.props.fileTypesStatistics} columns={columns} size="small" />
              {/* <Descriptions title="User Info" column={1}>
                <Descriptions.Item label="UserName">Zhou Maomao</Descriptions.Item>
                <Descriptions.Item label="Telephone">1810000000</Descriptions.Item>
                <Descriptions.Item label="Live">Hangzhou, Zhejiang</Descriptions.Item>
                <Descriptions.Item label="Remark">empty</Descriptions.Item>
                <Descriptions.Item label="Address">
                  No. 18, Wantang Road, Xihu District, Hangzhou, Zhejiang, China
                </Descriptions.Item>
              </Descriptions> */}
            </Card>
          </Col>
        </Row>
      </>
    )
  }
}