import {CnDrawing} from "./CnDrawing";

export class CurrentCnDrawing extends CnDrawing {
    constructor(imageViewer) {
        super();

        this._imageViewer = imageViewer;
    }

    id() {
        const props = this._imageViewer.props;
        const matchParams = props.parentProps.match.params;

        const isFinalResult = props.location.pathname.indexOf('/final_results/view_result/') !== -1;
        const resultId = isFinalResult ? matchParams.finalResultId : matchParams.documentId;

        return resultId;
    }

    openSwitched(cnDrawingToCompare, selectedCnResult) {
        throw new Error('Not supported');
    }

    open() {
        throw new Error('Not supported');
    }

    toColumnData() {
        throw new Error('Not supported');
    }

    toResultReference() {
        const props = this._imageViewer.props;
        const matchParams = props.parentProps.match.params;

        const isFinalResult = props.location.pathname.indexOf('/final_results/view_result/') !== -1;
        const resultId = isFinalResult ? matchParams.finalResultId : matchParams.documentId;

        return {
            result_id: resultId,
            is_final: isFinalResult
        };
    }
}