import {map, pairwise, switchMap, takeUntil, tap, startWith, filter, mergeMap, withLatestFrom} from "rxjs/operators";
import {of, EMPTY} from 'rxjs';
import {viewerModes} from "../../constants";

const PANNING_MODES = [
    viewerModes.NORMAL,
]

export default class PanningHandler {
  constructor(imageViewer) {
    this.imageViewer = imageViewer;
  }

  registerEvents = () => {
    this.imageViewer.subscriptions.push(this.imageViewer.mouseDown$.pipe(
        withLatestFrom(this.imageViewer.mode$),
        filter(([_, mode]) => PANNING_MODES.includes(mode)), map(([opt, _]) => opt),

        map(opt => ({x: opt.e.clientX, y: opt.e.clientY})),
        mergeMap(opt => {
          const curObject = this.imageViewer.handlers.objectSelectionHandler.findTargetObject(opt);
          if (curObject && curObject !== this.imageViewer.canvas.getActiveObject()) {
            return of({...opt, curObject: curObject});
          }
          if (curObject === undefined) return of(opt);
          return EMPTY;
        }),
        tap(opt => {
          this.imageViewer.canvas.selection = false;
          if (opt.curObject) {
            opt.curObject.lockMovementX = true;
            opt.curObject.lockMovementY = true;
            opt.curObject.lockScalingX = true;
            opt.curObject.lockScalingY = true;
          }
        }),
        switchMap(opt => this.imageViewer.mouseMove$.pipe(
            map(opt2 => ({x: opt2.e.clientX, y: opt2.e.clientY})),
            startWith(opt),
            pairwise(),
            tap(pair => {
              const curPoint = pair[1];
              const prevPoint = pair[0];
              var vpt = this.imageViewer.canvas.viewportTransform;
              vpt[4] += curPoint.x - prevPoint.x;
              vpt[5] += curPoint.y - prevPoint.y;
              this.imageViewer.zoomHandler.clipViewPort();
              this.imageViewer.renderAll$.next(1);
              this.imageViewer.viewPortChanged$.next(1);
            }),
            takeUntil(this.imageViewer.mouseUp$.pipe(tap(() => {
              this.imageViewer.canvas.selection = false;
              if (opt.curObject && this.imageViewer.props.editable) {
                opt.curObject.lockMovementX = false;
                opt.curObject.lockMovementY = false;
                opt.curObject.lockScalingX = false;
                opt.curObject.lockScalingY = false;
              }
              this.imageViewer.isDragging$.next(false);
            })))
        )),
    ).subscribe());
  }
}
