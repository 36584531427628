export default class Point {
  constructor(x, y) {
    this.x = x;
    this.y = y;
  }

  distanceSquared(to) {
    const dx = to.x - this.x;
    const dy = to.y - this.y;

    return dx * dx + dy * dy;
  }
}