import Point from "../../../ImageViewerHandlers/Geometry/Point";
import {fabric} from "fabric";
import {bboxOf, RectangleFromBbox} from "../../../../Utilities";

export class MarkerTargetAsProperObj {
    constructor(tagObj) {
        this._imgViewerObject = tagObj;
    }

    containsMarker(marker) {
        if (this._imgViewerObject.isGraphObject()) {
            const wrappingHelper = this._imgViewerObject.canvasObject.wrappingData.helper;
            const wrappedObjects = Array.from(wrappingHelper.getWrappedObjects().values()).flatMap(el => el);
            const possibleTargets = wrappedObjects
                .map(el => new MarkerTargetAsAnyCanvasObj(el))
                .filter(el => el.containsMarker(marker));

            return possibleTargets.length > 0;
        } else {
            return this._imgViewerObject.getRect().containsPoint({x: marker.position.x, y: marker.position.y});
        }
    }

    attachedMarker(base) {
        let text = '';
        let position = {x: 0, y: 0};

        if (this._imgViewerObject.isGraphObject()) {
            text = this._imgViewerObject.text ?? '';

            const byDistanceSquared = (a, b) => {
                const markerPoint = new Point(base.position.x, base.position.y);
                const distA = new Point(a.left, a.top).distanceSquared(markerPoint);
                const distB = new Point(b.left, b.top).distanceSquared(markerPoint);

                return distA - distB;
            }

            const wrappingHelper = this._imgViewerObject.canvasObject.wrappingData.helper;
            const wrappedObjects = Array.from(wrappingHelper.getWrappedObjects().values()).flatMap(el => el);
            const nearestVert = wrappedObjects.sort(byDistanceSquared);

            const targetPosition = nearestVert[0] ?? {
                left: this._imgViewerObject.getRect().getCenter().x,
                top: this._imgViewerObject.getRect().getCenter().y,
            }

            position = {
                x: targetPosition.left,
                y: targetPosition.top,
            }
        } else {
            text = this._imgViewerObject.text ?? '';
            position = {
                x: this._imgViewerObject.getRect().getCenter().x,
                y: this._imgViewerObject.getRect().getCenter().y,
            };
        }

        return {...base, text: text, position: position};
    }
}


export class MarkerTargetAsAnyCanvasObj {
    constructor(canvasObj) {
        this._canvasObj = canvasObj;
    }

    containsMarker(marker) {
        const markerPosition = marker.position;
        return this._canvasObj.containsPoint(new fabric.Point(markerPosition.x, markerPosition.y), null, true);
    }

    attachedMarker(base) {
        const rect = new RectangleFromBbox(bboxOf(this._canvasObj));
        return {
            ...base,
            text: this._canvasObj.objectMetadata?.text ?? '',
            position: {
                x: rect.getCenter().x,
                y: rect.getCenter().y
            }
        };
    }
}