import React from 'react'
import { Button, Form, Input } from 'antd'
import { UploadS3 } from './UploadS3';


export class UploadS3WithCredentials extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      awsAccessKeyId: null,
      awsSecretAccessKey: null,
    };

  }

  submitForm() {
    const layout = {
      labelCol: { offset: 0, span: 3 },
      wrapperCol: { offset: 0, span: 6 },
    };
    const tailLayout = {
      wrapperCol: { offset: 5 },
    };

    const onFinish = values => {
      this.setState({
        ...values,
      })
    };

    return (
      <Form
        {...layout}
        name="basic"
        onFinish={onFinish}
      >
        <Form.Item
          label="Aws access key id"
          name="awsAccessKeyId"
          rules={[{ required: true, message: 'Please input Aws access key id!' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="Aws secret access key"
          name="awsSecretAccessKey"
          rules={[{ required: true, message: 'Please input Aws secret access key!' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    );
  }

  render() {
    const credentialsValid = this.state.awsAccessKeyId && this.state.awsSecretAccessKey;

    return (
      <>
        {/* <Button type="primary" onClick={() => {
          this.setState({
            awsAccessKeyId: null,
            awsSecretAccessKey: null,
          })
        }} >Primary Button</Button> */}

        {!credentialsValid && this.submitForm()}
        {credentialsValid
          && <UploadS3 {...this.props} {...this.state} />
        }
      </>
    )
  }
}