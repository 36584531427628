import {Attributes} from "../Attributes";

export class SafeAttributes extends Attributes {
    constructor(origin) {
        super();

        this._origin = origin;
    }

    keys() {
        try {
            return this._origin.keys();
        } catch (e) {
            return [];
        }
    }

    value(key) {
        try {
            return this._origin.value(key);
        } catch (e) {
            return '';
        }
    }
}