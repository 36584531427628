import axios from "axios";
import {API_URL} from "../../../constants";
import {authHeader, cache} from "../../../Utilities";


class AssignTarget {
    constructor(response) {
        this._response = response;
    }

    includesText(text) {
        return this._response.name.toLowerCase().includes(text);
    }

    relatedToSelectOption(opt) {
        return this._response.user_id === opt.value;
    }

    asCommentSelectOption() {
        const userId = this._response.user_id;

        return {
            key: userId,
            value: userId,
            label: this._response.name
        }
    }

    asDataToAssign() {
        return {
            userId: this._response.user_id,
            name: this._response.name
        }
    }
}

export class AssignTargets {
    constructor(imageViewer) {
        this._imageViewer = imageViewer;

        this._cache = cache(() => {
            const params = {...this._imageViewer.toResultReference()}

            return axios.get(
                API_URL + `/comments/assign_targets`,
                {params: params, headers: authHeader()}
            );
        });
    }

    async values(text) {
        return new Promise((resolve, reject) => {
            this._cache()
                .then(response => resolve(
                    response.data
                        .map(el => new AssignTarget(el))
                        .filter(target => target.includesText(text.toLowerCase()))
                ))
                .catch(e => reject())
        })
    }

    async valueByCommentSelectOption(opt) {
        return this.values('').then(
            data => data.find(target => target.relatedToSelectOption(opt))
        )
    }

    defaultCommentOptionValue(comment) {
        const assignTarget = comment.assignTargets[0];

        return {
            key: assignTarget?.userId ?? null,
            value: assignTarget?.userId ?? null,
            label: assignTarget?.name ?? null
        }
    }
}