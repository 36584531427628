import {Avatar, Button, Card, Col, Divider, Dropdown, Menu, Row} from "antd";
import React, {useCallback} from "react";
import {MoreOutlined, UserOutlined} from "@ant-design/icons";
import moment from "moment";
import {empty} from "../../../../../Utilities";
import {TrimmedTag} from "../../../../Misc/TrimmedTag";
import Paragraph from "antd/es/typography/Paragraph";

function CommentMessage({value}) {
    return (
        <Paragraph
            id={'comment-message'}
            style={{maxWidth: '100%'}}
            ellipsis={{
                rows: 3,
                expandable: true,
            }}
            title={value}
        >
            {value}
        </Paragraph>
    );
}


export function CommentMarker({marker, selected, onClick}) {
    return (
        <TrimmedTag
            color={selected ? 'var(--color-yellow)' : ''}
            onClick={onClick ?? empty}
            text={marker.text || "(point)"}
        >
            {text => text}
        </TrimmedTag>
    );
}


function CommentMarkers({id, markers, markerSelected, onClick}) {
    const markersList = useCallback(() => {
            return markers.map(marker => (
                <CommentMarker
                    key={marker.id}
                    marker={marker}
                    selected={markerSelected(marker)}
                    onClick={e => {
                        e.stopPropagation();
                        onClick(marker);
                    }}
                />
            ));
        },
        [onClick, markerSelected, markers]
    );

    return (
        markers.length > 0 && (
            <div id={id}>
                <Divider style={{marginBottom: '8px', marginTop: '8px'}}/>
                <div>
                    Reference: {markersList()}
                </div>
            </div>
        )
    );
}


function CommentSignature({username, date}) {
    return (
        <Row>
            <Col style={{marginRight: "16px"}}>
                <Avatar style={{backgroundColor: 'var(--color-gray)'}} size="default" icon={<UserOutlined/>}/>
            </Col>
            <Col>
                <div>
                    {username}
                </div>
                {moment.utc(date).local().format('MM/DD/YYYY, h:mm a')}
            </Col>
        </Row>
    );
}


export function CommentHeader({username, date, actions, outsideActions}) {
    const actionsMenu = (
        <Menu id={'comment-actions-menu'}>
            {actions}
        </Menu>
    );

    return (
        <Row justify={"space-between"}>
            <Col>
                <CommentSignature username={username} date={date}/>
            </Col>
            <Col>
                {outsideActions?.length > 0 && (
                    outsideActions
                )}
                {actions?.length > 0 && (
                    <span onClick={e => e.stopPropagation()}>
                        <Dropdown overlay={actionsMenu} trigger={["click"]}>
                            <Button id={'comment-actions-button'} type="text" icon={<MoreOutlined />} size={"small"}/>
                        </Dropdown>
                    </span>
                )}
            </Col>
        </Row>
    );
}


function AssignedTo({id, comment}) {
    const isAssigned = comment.assignTargets.length > 0;
    return isAssigned && (
        <div id={id}>
            <Divider style={{marginBottom: '8px', marginTop: '8px'}}/>
            <div>
                Assigned to: <a>{comment.assignTargets[0]?.name}</a>
            </div>
        </div>
    );
}


export function Comment({id, comment, actions, outsideActions, onClick, onMarkerClick, commentsSelection}) {
    const borderStyle = commentsSelection.commentSelected(comment) ? {borderColor: 'var(--color-turquoise)'} : {};

    return (
        <Card
            id={id}
            hoverable
            style={{borderRadius: "10px", ...borderStyle}}
            onClick={onClick ?? empty}
        >
            <CommentHeader
                date={comment.date}
                username={comment.author.name}
                actions={actions}
                outsideActions={outsideActions}
            />
            <CommentMessage
                value={comment.message}
            />
            <CommentMarkers
                id={'comment-markers'}
                markers={comment.markers}
                markerSelected={marker => commentsSelection.markerSelected(comment, marker)}
                onClick={onMarkerClick ?? empty}
            />
            <AssignedTo id={'assigned-to'} comment={comment}/>
        </Card>
    );
}
