import {defer, Subject, timer, of, queueScheduler} from "rxjs";
import {debounceTime, switchMap, take, takeWhile, tap, concat, map, repeat, observeOn} from "rxjs/operators";
import { animationFrameScheduler } from 'rxjs';

export class ZoomHandler {
  constructor(imageViewer, minZoomLevel, maxZoomLevel) {
    this.imageViewer = imageViewer;
    this.canvas = imageViewer.canvas;
    this.zoomLevel = 1.0;
    this.minZoomLevel = minZoomLevel;
    this.maxZoomLevel = maxZoomLevel;
    this.zoomStep = 0.1 * 0.5 * 0.8 * 1.5;

    this.desiredZoomLevel = this.zoomLevel;

    this.setViewPortTransform$ = new Subject();
    this.setZoomSmooth$ = new Subject();
  }

  registerEvents = () => {
    this.imageViewer.subscriptions.push(this.imageViewer.onMouseWheel$.pipe(observeOn(queueScheduler)).subscribe(e => {
      this.handleWheel(e);
    }));

    this.imageViewer.subscriptions.push(
        this.setViewPortTransform$.pipe(debounceTime(200)).subscribe(vpt => this.canvas.setViewportTransform(vpt))
    );

    const requestAnimationFrame$ = defer(() => of(animationFrameScheduler.now(), animationFrameScheduler).pipe(
      repeat(),
      map(start => animationFrameScheduler.now() - start),
    ));

    const duration$ = duration => requestAnimationFrame$.pipe(
        map(time => time / duration),
        takeWhile(progress => progress < 1),
        concat([1]),
   )


    this.imageViewer.subscriptions.push(this.setZoomSmooth$.pipe(
      switchMap(opt => {
        const oldZoomLevel = this.zoomLevel;
        return duration$(500).pipe(
          tap(step => {
            const curZoomLevel = this.easeOutQuart(step, oldZoomLevel, opt.newZoomLevel, 1);
            this._setZoom(curZoomLevel, opt.pX, opt.pY);
          })
        );
      })
    ).subscribe());
  }

  clipViewPort = () => {
    const vpt = [...this.canvas.viewportTransform];
    vpt[4] = Math.min(vpt[4], 0.5 * this.canvas.width);
    vpt[5] = Math.min(vpt[5], 0.5 * this.canvas.height);

    vpt[4] = Math.max(vpt[4], 0.5 * this.canvas.width - this.imageViewer.imageWidth * this.zoomLevel);
    vpt[5] = Math.max(vpt[5], 0.5 * this.canvas.height - this.imageViewer.imageHeight * this.zoomLevel);
    this.canvas.setViewportTransform(vpt);
  }

  // Do not use this method directly. Use either setZoomSmooth or setZoomForce as they properly handle this.desiredZoomLevel
  _setZoom = (newZoomLevel, pX, pY) => {
    newZoomLevel = Math.max(newZoomLevel, this.minZoomLevel);
    newZoomLevel = Math.min(newZoomLevel, this.maxZoomLevel);
    this.zoomLevel = newZoomLevel;
    this.canvas.zoomToPoint({x: pX, y: pY}, this.zoomLevel);
    this.clipViewPort();

    this.imageViewer.zoomChange$.next({
      zoomLevel: newZoomLevel,
    })

    this.imageViewer.viewPortChanged$.next(1);
  }

  getZoomRatio = () => {
    return (this.zoomFuncInv(this.zoomLevel) - this.zoomFuncInv(this.minZoomLevel)) / (this.zoomFuncInv(this.maxZoomLevel) - this.zoomFuncInv(this.minZoomLevel));
  }

  // ease out method
  /*
      t : current time,
      b : intial value,
      c : changed value,
      d : duration
  */
  easeOutQuart = (t, b, c, d) => {
    t /= d;
    t -= 1;
    return -(c - b) * (t * t * t * t - 1) + b;
  }

  _setDesiredZoomLevel = (desiredZoomLevel) => {
    desiredZoomLevel = Math.max(desiredZoomLevel, this.minZoomLevel);
    desiredZoomLevel = Math.min(desiredZoomLevel, this.maxZoomLevel);
    this.desiredZoomLevel = desiredZoomLevel;
  }

  setZoomSmooth = (newZoomLevel, pX, pY) => {
    this._setDesiredZoomLevel(newZoomLevel);
    this.setZoomSmooth$.next({newZoomLevel, pX, pY});
  }

  setZoomForce = (newZoomLevel, pX, pY) => {
    this._setDesiredZoomLevel(newZoomLevel);
    this._setZoom(newZoomLevel, pX, pY);
  }

  zoomToFit = (rect) => {
    const width = rect.x2 - rect.x1;
    const height = rect.y2 - rect.y1;
    let curZoom = Math.min(this.canvas.width / width, this.canvas.height / height) * 0.95;
    this.setZoomForce(curZoom, 0, 0);
    curZoom = this.zoomLevel;
    const vpt = [...this.canvas.viewportTransform];
    vpt[4] = -((rect.x1 + 0.5 * width) * curZoom - this.canvas.width * 0.5)
    vpt[5] = -((rect.y1 + 0.5 * height) * curZoom - this.canvas.height * 0.5)
    this.setViewPortTransform$.next(vpt);

    this.imageViewer.viewPortChanged$.next(1);
    this.imageViewer.visualChanged$.next(1);
    this.imageViewer.renderAll$.next(1);
  }

  zoomToObject = (obj, select=true, hideOthers=true, source='zoom') => {
    this.setZoomForce(1.0, 0, 0);
    const vpt = [...this.canvas.viewportTransform];
    vpt[4] = -(obj.left + 0.5 * obj.width * obj.scaleX - this.canvas.width * 0.5);
    vpt[5] = -(obj.top + 0.5 * obj.height * obj.scaleY - this.canvas.height * 0.5);
    this.imageViewer.viewPortChanged$.next(1);
    // this.setViewPortTransform$.next(vpt);
    this.canvas.setViewportTransform(vpt);


    if (select) {
      this.canvas.setActiveObject(obj);
      this.imageViewer.objectSelected$.next({target: obj, source: source});
    } else {
      this.imageViewer.renderAll$.next(1);
      this.imageViewer.visualChanged$.next(1);

    }

    if (hideOthers) {
      this.imageViewer.setState({selectionParams: [], extraVisibleObjectsIds: new Set()}, this.imageViewer.updateObjectsVisibility);
    }

    this.imageViewer.canvas.requestRenderAll();
  }

  resetZoom = () => {
    this.setZoomForce(this.minZoomLevel, 0, 0);
    const vpt = [...this.canvas.viewportTransform];
    vpt[4] = -(this.imageViewer.imageWidth * 0.5 * this.minZoomLevel - this.canvas.width * 0.5);
    vpt[5] = -(this.imageViewer.imageHeight * 0.5 * this.minZoomLevel - this.canvas.height * 0.5);
    this.imageViewer.viewPortChanged$.next(1);
    this.setViewPortTransform$.next(vpt);
  }

  handleWheel = (e) => {
    const pX = e.nativeEvent.offsetX;
    const pY = e.nativeEvent.offsetY;
    if (e.deltaY > 0) {
      const newZoomLevel = this.zoomFunc(this.zoomFuncInv(this.desiredZoomLevel) - this.zoomStep);
      this.setZoomSmooth(newZoomLevel, pX, pY);
    } else {
      const newZoomLevel = this.zoomFunc(this.zoomFuncInv(this.desiredZoomLevel) + this.zoomStep);
      this.setZoomSmooth(newZoomLevel, pX, pY);
    }
  }

  zoomFunc = (x) => {
    return Math.pow(x, 4);
  }

  zoomFuncInv = (y) => {
    return Math.pow(y, 0.25);
  }
}
