import React from "react";
import {Table} from "antd";
import {ColoredCnDetail} from "../../Model/AnimatedCnDetails/ColoredCnDetail";
import {ColoredColumns} from "../../../../../Utilities";


export function CnResultDetails({detailsTarget, colorMap}) {
    const columns = new ColoredColumns(
        [
            {
                title: 'Attribute',
                dataIndex: ['key'],
            },
            {
                title: 'Current',
                dataIndex: ['current', 'value'],
            },
            {
                title: 'Other',
                dataIndex: ['another', 'value'],
            },
        ],
        (text, record, idx) => new ColoredCnDetail(record, colorMap).color()
    );

    return (
        <Table size="small"
               id="cn-details-table"
               dataSource={detailsTarget.details()}
               pagination={false}
               columns={columns.values()}
        />
    );
}