import React from "react";
import {Button, Card, Descriptions, Form, message, Row, Space, Tooltip} from "antd";
import OrganizationsService from "../../services/OrganizationsService";
import { EditOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import {Link} from "react-router-dom";
import {PermissionsList} from "../Misc/PermissionsList";


export class ViewOrganization extends React.Component {
  state = {
    organization: null,
  }

  componentDidMount() {
    this.loadOrganization();
  }

  loadOrganization = () => {
    OrganizationsService.fetchOrganizationById(this.props.match.params.organizationId).then(org => {
      this.setState({organization: org.data});
    }).catch(() => message.error("Failed to load organization"));
  }

  render() {
    const title = (
        <Row>
          <Button shape="circle" size="medium" icon={<ArrowLeftOutlined />} style={{marginRight: '32px'}}
                  onClick={() => this.props.history.push(`${this.props.root}organizations`)}
          />
          <span>Organization Info</span>
          <Space style={{marginLeft: 'auto'}}>
            <Tooltip title="Edit">
              <Link to={`${this.props.root}organizations/${this.state.organization?.id}/edit`}>
                <EditOutlined style={{fontSize: '24px'}} />
              </Link>
            </Tooltip>
          </Space>
        </Row>
    )
    return (
        <>
          <Row style={{justifyContent: 'center'}}>
            <Card style={{width: '50%'}}>
                <Descriptions title={title} bordered style={{marginBottom: '16px'}}>
                  <Descriptions.Item label="Name">{this.state.organization?.name}</Descriptions.Item>
                </Descriptions>
                <PermissionsList
                    permissionsList={this.state.organization?.permissions}
                    isEditable={false}
                />
            </Card>
          </Row>
        </>
    )
  }
}