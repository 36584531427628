import React from 'react'
import {Tabs} from "antd";
import ConfigureExcelReports from "../Workspaces/ConfigureExcelReports";
import ConfigureHierarchyReports from "../Workspaces/ConfigureHierarchyReports";
import WorkspaceViewModeService from "../../services/WorkspaceViewModeService";

export function UserViewConfigureReports() {
    return (
        <Tabs>
            <Tabs.TabPane tab="Tags export" key="tags_export">
                <ConfigureExcelReports workspaceId={WorkspaceViewModeService.getCurrentWorkspaceId()}/>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Hierarchy export" key="hierarchy_export">
                <ConfigureHierarchyReports workspaceId={WorkspaceViewModeService.getCurrentWorkspaceId()}/>
            </Tabs.TabPane>
        </Tabs>
    );
}
